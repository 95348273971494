import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import Button from '@sportnet/ui/Button';
import Link from '@sportnet/ui/Link';
import React from 'react';
import __ from '../../utilities/getText';

interface OwnProps {
  selectionCount: number;
  onClickAddNew: () => void;
  onClickRename: () => void;
  onClickMove: () => void;
  onClickDelete: () => void;
  onRemoveAllFromSelection: () => void;
}

const CustomContextBar: React.FC<OwnProps> = ({
  selectionCount,
  onClickAddNew,
  onClickDelete,
  onClickMove,
  onClickRename,
  onRemoveAllFromSelection,
}) => {
  const isSomethingSelected = selectionCount > 0;
  return (
    <ContextBar>
      <ContextBarItem>
        {__('Označené: ')} {selectionCount}
      </ContextBarItem>
      {isSomethingSelected && (
        <ContextBarItem>
          <Link danger onClick={onRemoveAllFromSelection}>
            {__('Zrušiť výber')}
          </Link>
        </ContextBarItem>
      )}
      <ContextBarSpacer />

      {isSomethingSelected && (
        <ContextBarItem>
          <Button
            block
            basic
            primary
            onClick={onClickMove}
            disabled={!isSomethingSelected}
          >
            {__('Presunúť do ...')}
          </Button>
        </ContextBarItem>
      )}

      {isSomethingSelected && (
        <ContextBarItem>
          <Button
            block
            basic
            primary
            onClick={onClickRename}
            disabled={!isSomethingSelected}
          >
            {selectionCount > 1 ? __('Zlúčiť do ...') : __('Premenovať')}
          </Button>
        </ContextBarItem>
      )}

      {isSomethingSelected && (
        <ContextBarItem>
          <Button
            block
            basic
            onClick={onClickDelete}
            danger
            disabled={!isSomethingSelected}
          >
            {__('Odstrániť')}
          </Button>
        </ContextBarItem>
      )}

      <ContextBarItem>
        <Button onClick={onClickAddNew} icon="plus" primary />
      </ContextBarItem>
    </ContextBar>
  );
};

export default CustomContextBar;
