import * as React from 'react';
import { AuthUser } from '@sportnet/auth/validateIdToken';
import { getApiError } from '../../library/api';
import { styled } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import AuthProvider from '@sportnet/auth-react/AuthProvider';
import CoreApi, { AppPublic, AppSpaceUser } from '../../api/CoreApi';
import Layout from '../../containers/Layout';
import Loader from '@sportnet/ui/Loader';
import TagmanagerApi from '../../api/TagmanagerApi';
import config from '../../config';
import withPopups, { WithPopupsProps } from '../../components/WithPopups';

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

interface OwnProps {
  topFixed?: JSX.Element;
  bottomFixed?: JSX.Element;
  isLoading?: boolean;
}

const Administration: React.FC<OwnProps & WithPopupsProps> = ({ alert }) => {
  const navigate = useNavigate();
  const { appspace: ppo } = useParams<{ appspace: string }>();
  const [authorized, setAuthorized] = React.useState(false);
  const [appSpace, setAppSpace] = React.useState<AppSpaceUser>();
  const [appSpaces, setAppSpaces] = React.useState<AppSpaceUser[]>();
  const [app, setApp] = React.useState<AppPublic>();
  const [apps, setApps] = React.useState<AppPublic[]>();

  const handleOnAuthorize = React.useCallback(
    async ({ accessToken }: { accessToken?: string; authUser?: AuthUser }) => {
      if (accessToken) {
        // nastavime token API triedam
        TagmanagerApi.setToken(accessToken);
        CoreApi.setToken(accessToken);
        try {
          // organizacie a aplikacie uzivatela
          const response = await CoreApi.meAppSpaces({ expandApp: true });
          const newApps = (response?.apps || []).map((a) => {
            const appSpaceIds = (a.appspaces || []).map((as) => as.app_space);
            return { ...a.app, appSpaceIds };
          });
          setApps(newApps);

          const appObj = (response.apps || []).find(
            (a) => a.app_id === config.APP_ID
          );

          if (appObj) {
            const newAppSpaces = appObj.appspaces || [];
            setAppSpaces(newAppSpaces);

            // aktivny appSpace
            const activeAppSpace = newAppSpaces.find(
              (newAppSpace) => newAppSpace.app_space === ppo
            );
            setAppSpace(activeAppSpace);
          }

          const appInfo = await CoreApi.getPublicApp(config.APP_ID);
          setApp(appInfo);

          setAuthorized(true);
        } catch (e) {
          const err = getApiError(e);
          await alert(
            `Chyba autorizácie: ${err.details.statusCode} ${err.details.name} ${err.message}`
          );
          navigate('/');
          setAuthorized(false);
        }
      }
    },
    [ppo, alert, navigate]
  );

  return (
    <AuthProvider app={config.APP_ID} ppo={ppo} onAuthorize={handleOnAuthorize}>
      {!authorized ? (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      ) : (
        <Layout
          appSpace={appSpace}
          appSpaces={appSpaces}
          app={app}
          apps={apps}
        />
      )}
    </AuthProvider>
  );
};

export default withPopups(Administration);
