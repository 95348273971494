import { RESTError } from './RESTError';

export type CmsApiError = {
  code: number;
  name: string;
  description: string | null;
  payload?: object | null;
  userinfo?: string;
};

export interface GenericApiError {
  message: string;
  details: {
    name: string;
    description: string | null;
    payload?: unknown;
    userinfo?: string;
  } & ({ statusCode: number } | { code: number });
}

export interface ApiError {
  message: string;
  details: {
    statusCode: number;
    name: string;
    description: string | null;
    payload?: unknown;
    userinfo?: string;
  };
}

export const isGenericApiError = (error: unknown): error is GenericApiError => {
  return typeof (error as GenericApiError).details !== 'undefined';
};

export const isRESTError = (
  errorDetails: unknown
): errorDetails is RESTError => {
  return typeof (errorDetails as RESTError).statusCode !== 'undefined';
};

export const isCmsApiError = (
  errorDetails: unknown
): errorDetails is CmsApiError => {
  return typeof (errorDetails as CmsApiError).code !== 'undefined';
};

export const getApiError = (error: unknown): ApiError => {
  if (isGenericApiError(error)) {
    if (isCmsApiError(error.details)) {
      const { code: statusCode, ...restDetails } = error.details;
      return {
        ...error,
        details: {
          ...restDetails,
          statusCode,
        },
      };
    } else {
      return error as ApiError;
    }
  }
  return {
    message: (error as Error).message,
    details: {
      statusCode: 500,
      name: 'OTHER_EXCEPTION',
      description: (error as Error).message,
    },
  };
};
