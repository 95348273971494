import { IContent } from '@sportnet/content/utilities/library';
import { useAuth } from '@sportnet/auth-react';
import React from 'react';
import Widgets from '@sportnet/content/editor';
import config from '../../config';
import defaultWidgets from '@sportnet/content/editor/defaultWidgets';

const AVAILABLE_WIDGETS = [
  'imagead',
  'document',
  'externalArticle',
  'photogallery',
  'photo',
  'multiblock',
  'table',
  'social',
  'video',
  'faq',
  'hr',
  'iframe',
  'banners',
  'textBlock',
  'resultsTable',
  'raceMatchOverview',
  'matchDetail',
  'matchOverview',
  'competitionStanding',
  'raceMatchesList',
  'matchesList',
  'playerStats',
  'competitionDraw',
  'onlajny.eu',
  'squad',
  'ctk-widget',
  'enetpulseWidget',
  'advert',
];

interface OwnProps {
  initialValue: IContent;
  onChange: (newWidgets: IContent) => void;
}

const SmartTagEditorPromptWidgets: React.FC<OwnProps> = ({
  initialValue,
  onChange,
}) => {
  const { ppo: appSpace, accessToken } = useAuth();

  const availableWidgets = React.useMemo(() => {
    return defaultWidgets.filter((i) => AVAILABLE_WIDGETS.includes(i.type));
  }, []);

  return !!appSpace && !!accessToken ? (
    <Widgets
      appId={config.APP_ID}
      appSpace={appSpace}
      contentDivider="default"
      token={accessToken}
      initialValue={initialValue}
      availableWidgets={availableWidgets}
      onChange={onChange}
    />
  ) : null;
};

export default React.memo(SmartTagEditorPromptWidgets);
