import { Route, Routes, useNavigate } from 'react-router-dom';
import Administration from '../Administration';
import AuthProvider from '@sportnet/auth-react/AuthProvider';
import PpoSelector from '@sportnet/auth-react/PpoSelector';
import React from 'react';
import config from '../../config';

const App = () => {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthProvider app={config.APP_ID}>
            <PpoSelector
              onSelectAppspace={(appSpace) => {
                navigate(`/admin/${appSpace.ppo}/`);
              }}
            />
          </AuthProvider>
        }
      />
      <Route path={'/_auth'} element={<AuthProvider app={config.APP_ID} />} />
      <Route path="/admin/:appspace/*" element={<Administration />} />
    </Routes>
  );
};

export default App;
