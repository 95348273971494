import * as React from 'react';
import styled from 'styled-components';

const Whitespace = styled.span`
  background: #fff3b6;
  padding: 0 0.2em;
`;

interface Props {
  children: string;
}

class WithWhitespace extends React.Component<Props> {
  render() {
    const { children, ...restProps } = this.props;
    return (
      <span {...restProps}>
        {children
          .split(/\s/)
          .reduce((acc: React.ReactNode[], word, idx, originalArray) => {
            acc.push(word);
            if (idx <= originalArray.length - 2) {
              acc.push(<Whitespace key={idx}>&#183;</Whitespace>);
            }
            return acc;
          }, [])}
      </span>
    );
  }
}

export default WithWhitespace;
