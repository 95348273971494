import { SmartTagKeyValuePair, SmartTagSelection } from '../../library/Common';
import { SmartTagStore } from './hooks/useAllSmarttags';
import { removeAccents } from '@sportnet/utilities';
import Column, { Item } from './Column';
import React from 'react';
import styled from 'styled-components';

const COLUMN_WIDTH = 300;

const Row = styled('div')`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-x: auto;
  width: 100%;
  flex: 1;
`;

const StyledColumn = styled(Column)`
  width: ${COLUMN_WIDTH}px;
  min-width: ${COLUMN_WIDTH}px;
  max-width: ${COLUMN_WIDTH}px;
`;

interface OwnProps {
  query: string;
  smarttags: SmartTagStore['smarttags'];
  onClickAdd: (val: { key: string }) => void;
  onClickEdit: (val: { key: string; value: string }) => void;
  onClickSetSystemTag: (key: string) => void;
  onClickUnsetSystemTag: (key: string) => void;
  onSelect: (smarttags: SmartTagKeyValuePair[]) => void;
  onDeselect: (smarttags: SmartTagKeyValuePair[]) => void;
  onDeselectAllFromColumn: (key: string) => void;
  smarttagSelection: SmartTagSelection;
}

const DataGrid: React.FC<OwnProps> = ({
  smarttags,
  query,
  onClickAdd,
  onClickEdit,
  onSelect,
  onDeselect,
  onDeselectAllFromColumn,
  smarttagSelection,
  onClickSetSystemTag,
  onClickUnsetSystemTag,
}) => {
  const re = new RegExp(query.trim(), 'i');
  const filteredSmarttags = query.trim()
    ? smarttags
        .map((smarttag) => {
          return {
            ...smarttag,
            values: (smarttag.values || []).filter((value) => {
              return (
                re.exec(value.key) !== null ||
                re.exec(removeAccents(value.key)) !== null
              );
            }),
          };
        })
        .filter((smarttag) => smarttag.values.length > 0)
    : smarttags;

  return (
    <Row>
      {filteredSmarttags.map((smarttag) => {
        return (
          <StyledColumn
            key={smarttag.key}
            smarttagKey={smarttag.key}
            items={(smarttag.values || []) as Item[]}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onDeselectAllFromColumn={onDeselectAllFromColumn}
            selectedValues={smarttagSelection[smarttag.key] ?? {}}
            onClickSetSystemTag={onClickSetSystemTag}
            onClickUnsetSystemTag={onClickUnsetSystemTag}
            isSystemTag={smarttag.system ?? false}
            onClickAdd={onClickAdd}
            onClickEdit={onClickEdit}
          />
        );
      })}
    </Row>
  );
};

export default DataGrid;
