import { IApp, IAppSpace } from '@sportnet/ui/TheLayout/types';
import { INavigationItem } from '@sportnet/ui/TheLayout/Navigation';
import { NavLink, Navigate, Route, Routes } from 'react-router-dom';
import { useAuth } from '@sportnet/auth-react/AuthProvider';
import CoreApi, { AppPublic, AppSpaceUser } from '../../api/CoreApi';
import Overview from '../../pages/Overview';
import React from 'react';
import TheLayout from '@sportnet/ui/TheLayout';
import __ from '../../utilities/getText';
import config from '../../config';

interface OwnProps {
  appSpace?: AppSpaceUser;
  appSpaces?: AppSpaceUser[];
  app?: AppPublic;
  apps?: AppPublic[];
}

const Layout: React.FC<OwnProps> = ({
  appSpace,
  appSpaces = [],
  app,
  apps = [],
}) => {
  const { authUser, logout, accessToken } = useAuth();
  const [notificationCount, setNotificationCount] = React.useState(-1);
  const getNavigationItemUrl = (itemUrl: string) => {
    if (appSpace) {
      return `/admin/${appSpace.app_space}${itemUrl}`;
    }
    return itemUrl;
  };
  const navigationItems: INavigationItem[] = [
    {
      icon: 'monitor',
      label: __('Prehľad'),
      url: getNavigationItemUrl('/overview'),
    },
  ];

  const timerRef = React.useRef<number | null>(null);

  React.useEffect(() => {
    if (!accessToken) {
      return;
    }

    const fetchNotificationCount = async () => {
      try {
        const { count } = await CoreApi.meUnreadMessagesCount();
        setNotificationCount(count ?? 0);
        if (timerRef.current) {
          window.clearInterval(timerRef.current);
        }
        timerRef.current = window.setTimeout(fetchNotificationCount, 300000);
      } catch (e) {
        setNotificationCount(-1);
      }
    };

    fetchNotificationCount();

    return () => {
      if (timerRef.current) {
        window.clearInterval(timerRef.current);
      }
    };
  }, [accessToken]);

  return (
    <TheLayout
      grant={authUser?.appSpace?.grant}
      accessToken={accessToken}
      navigation={navigationItems}
      userName={appSpace?.display_name ?? ''}
      notificationCount={notificationCount}
      userPictureSrc={authUser?.photoUrl}
      onLogout={() => {
        logout();
      }}
      app={app as IApp}
      apps={apps as IApp[]}
      appSpace={{
        _id: appSpace?.app_space || '',
        name: appSpace?.org_profile?.name || '',
        organization_name: appSpace?.org_profile?.organization_name || '',
        logo_public_url: appSpace?.org_profile?.logo_public_url,
        organization_id: appSpace?.org_profile?._id || '',
      }}
      appSpaces={
        appSpaces.map((as) => ({
          _id: as.app_space,
          name: as.org_profile?.name || '',
          organization_name: as.org_profile?.organization_name || '',
          logo_public_url: as.org_profile?.logo_public_url,
          organization_id: as.org_profile?._id || '',
        })) as IAppSpace[]
      }
      renderNavigationLink={({ item, linkProps }) => (
        <NavLink to={item.url || ''} {...linkProps}>
          {linkProps.children}
        </NavLink>
      )}
      userSubName={authUser?.appSpace?.roles?.[config.APP_ID]}
    >
      <Routes>
        <Route path="" element={<Navigate replace to="overview" />} />
        <Route path="overview" element={<Overview />} />
      </Routes>
    </TheLayout>
  );
};

export default Layout;
