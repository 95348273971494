import { ApiError, getApiError } from '../../../library/api';
import {
  SmartTagKeyValuePair,
  SmartTagSelection,
} from '../../../library/Common';
import { SmartTagMutateResponse } from './useAllSmarttags';
import React from 'react';
import TagmanagerApi from '../../../api/TagmanagerApi';
import mapSmarttagSelection from '../../../utilities/mapSmarttagSelection';

const initialState: {
  isLoading: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  error?: ApiError;
} = {
  isLoading: false,
};

type State = typeof initialState;

type Action = {
  type: string;
  payload?: {
    isLoading?: boolean;
    error?: ApiError;
    isSuccess?: boolean;
  };
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'start': {
      return {
        ...initialState,
        isLoading: true,
      };
    }
    case 'done': {
      return {
        ...state,
        isSuccess: true,
        isLoading: false,
        isError: false,
      };
    }
    case 'error': {
      return {
        ...state,
        error: action.payload?.error,
        isError: true,
        isLoading: false,
        isSuccess: false,
      };
    }
    default: {
      return state;
    }
  }
};

const useSmarttagsMutations = ({ appSpace }: { appSpace: string }) => {
  const [state, dispatch] = React.useReducer(reducer, { ...initialState });

  /**
   * Vytvoreie noveho smart:tagu
   */
  const createNewSmarttag = React.useCallback(
    async ({
      newSmarttag,
      onSettled,
      onSuccess,
      onError,
    }: {
      newSmarttag: SmartTagKeyValuePair;
      onSettled?: () => void;
      onSuccess?: (response: SmartTagMutateResponse) => void;
      onError?: (error: ApiError) => void;
    }) => {
      try {
        dispatch({
          type: 'start',
        });
        if (onSettled) {
          onSettled();
        }
        const response = (await TagmanagerApi.adminCreateTag(
          appSpace,
          {},
          newSmarttag
        )) as SmartTagMutateResponse;
        dispatch({
          type: 'done',
        });
        if (onSuccess) {
          onSuccess(response);
        }
      } catch (e) {
        const error = getApiError(e);
        dispatch({
          type: 'error',
          payload: {
            error,
          },
        });
        if (onError) {
          onError(error);
        }
      }
    },
    [appSpace]
  );

  /**
   * Presunutie smarttagov
   */
  const moveSmarttags = React.useCallback(
    async ({
      newKey,
      smarttagsToMove,
      onSettled,
      onSuccess,
      onError,
    }: {
      newKey: string;
      smarttagsToMove: SmartTagSelection;
      onSettled?: () => void;
      onSuccess?: (response: SmartTagMutateResponse) => void;
      onError?: (error: ApiError) => void;
    }) => {
      try {
        dispatch({
          type: 'start',
        });
        if (onSettled) {
          onSettled();
        }
        const response = (await TagmanagerApi.adminApplyCommands(
          appSpace,
          {},
          {
            commands: mapSmarttagSelection(
              ({ key, value }) => ({
                moveTag: {
                  from: `${key}:${value}`,
                  to: `${newKey}:${value}`,
                },
              }),
              smarttagsToMove
            ),
          }
        )) as SmartTagMutateResponse;
        dispatch({
          type: 'done',
        });
        if (onSuccess) {
          onSuccess(response);
        }
      } catch (e) {
        const error = getApiError(e);
        dispatch({
          type: 'error',
          payload: {
            error,
          },
        });
        if (onError) {
          onError(error);
        }
      }
    },
    [appSpace]
  );

  /**
   * Premenovanie/zlucovanie smarttagov
   */
  const renameSmarttags = React.useCallback(
    async ({
      newSmarttag,
      smarttagsToRename,
      onSettled,
      onSuccess,
      onError,
    }: {
      newSmarttag: SmartTagKeyValuePair;
      smarttagsToRename: SmartTagSelection;
      onSettled?: () => void;
      onSuccess?: (response: SmartTagMutateResponse) => void;
      onError?: (error: ApiError) => void;
    }) => {
      try {
        dispatch({
          type: 'start',
        });
        if (onSettled) {
          onSettled();
        }
        const response = (await TagmanagerApi.adminApplyCommands(
          appSpace,
          {},
          {
            commands: mapSmarttagSelection(
              ({ key, value }) => ({
                moveTag: {
                  from: `${key}:${value}`,
                  to: `${newSmarttag.key}:${newSmarttag.value}`,
                },
              }),
              smarttagsToRename
            ),
          }
        )) as SmartTagMutateResponse;
        dispatch({
          type: 'done',
        });
        if (onSuccess) {
          onSuccess(response);
        }
      } catch (e) {
        const error = getApiError(e);
        dispatch({
          type: 'error',
          payload: {
            error,
          },
        });
        if (onError) {
          onError(error);
        }
      }
    },
    [appSpace]
  );

  /**
   * Odstranenie smarttagov
   */
  const deleteSmarttags = React.useCallback(
    async ({
      smarttagsToDelete,
      onSettled,
      onSuccess,
      onError,
    }: {
      smarttagsToDelete: SmartTagSelection;
      onSettled?: () => void;
      onSuccess?: (response: SmartTagMutateResponse) => void;
      onError?: (error: ApiError) => void;
    }) => {
      try {
        dispatch({
          type: 'start',
        });
        if (onSettled) {
          onSettled();
        }
        const response = (await TagmanagerApi.adminApplyCommands(
          appSpace,
          {},
          {
            commands: mapSmarttagSelection(
              ({ key, value }) => ({
                delete: {
                  tag: `${key}:${value}`,
                },
              }),
              smarttagsToDelete
            ),
          }
        )) as SmartTagMutateResponse;
        dispatch({
          type: 'done',
        });
        if (onSuccess) {
          onSuccess(response);
        }
      } catch (e) {
        const error = getApiError(e);
        dispatch({
          type: 'error',
          payload: {
            error,
          },
        });
        if (onError) {
          onError(error);
        }
      }
    },
    [appSpace]
  );

  /**
   * Nastavit smarttag ako systemovy
   */
  const setSystemKey = React.useCallback(
    async ({
      smarttagKey,
      onSettled,
      onSuccess,
      onError,
    }: {
      smarttagKey: string;
      onSettled?: () => void;
      onSuccess?: () => void;
      onError?: (error: ApiError) => void;
    }) => {
      try {
        dispatch({
          type: 'start',
        });
        if (onSettled) {
          onSettled();
        }
        await TagmanagerApi.adminSetSystemKey(appSpace, smarttagKey);
        dispatch({
          type: 'done',
        });
        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        const error = getApiError(e);
        dispatch({
          type: 'error',
          payload: {
            error,
          },
        });
        if (onError) {
          onError(error);
        }
      }
    },
    [appSpace]
  );

  /**
   * Zrusenie nastavenia systemoveho smarttagu
   */
  const unsetSystemKey = React.useCallback(
    async ({
      smarttagKey,
      onSettled,
      onSuccess,
      onError,
    }: {
      smarttagKey: string;
      onSettled?: () => void;
      onSuccess?: () => void;
      onError?: (error: ApiError) => void;
    }) => {
      try {
        dispatch({
          type: 'start',
        });
        if (onSettled) {
          onSettled();
        }
        await TagmanagerApi.adminUnsetSystemKey(appSpace, smarttagKey);
        dispatch({
          type: 'done',
        });
        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        const error = getApiError(e);
        dispatch({
          type: 'error',
          payload: {
            error,
          },
        });
        if (onError) {
          onError(error);
        }
      }
    },
    [appSpace]
  );

  return {
    ...state,
    moveSmarttags,
    createNewSmarttag,
    renameSmarttags,
    deleteSmarttags,
    setSystemKey,
    unsetSystemKey,
  };
};

export default useSmarttagsMutations;
