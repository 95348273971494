import { ApiError, getApiError } from '../../../library/api';
import { Smarttag } from '../../../library/Common';
import React from 'react';
import TagmanagerApi from '../../../api/TagmanagerApi';
import sortSmarttags from '../../../utilities/sortSmarttags';

export interface SmartTagMutateResponse {
  keys?: Array<string>;
  tags?: Array<{
    appSpace?: string;
    key: string;
    system?: boolean;
    values?: Array<{
      _id?: string;
      key: string;
      appId?: Array<string>;
    }>;
  }>;
}

export interface SmartTagStore {
  smarttags: Smarttag[];
}

// redukcia dat potrebnych pre ulozenie v state (todo: values ukladam ako su ... ?)
const reduceSmarttags = (
  smarttags: Required<SmartTagMutateResponse>['tags']
) => {
  return smarttags.map((tag) => {
    return {
      key: tag.key,
      system: tag.system ?? false,
      values: tag.values ?? [],
    };
  });
};

const initialState: {
  isLoading: boolean;
  isError: boolean;
  error?: string;
  smarttags?: Smarttag[];
  refetchTimestamp?: number;
} = {
  isLoading: false,
  isError: false,
};

type State = typeof initialState;

type Action = {
  type: string;
  payload?: {
    smarttags?: SmartTagStore['smarttags'];
    isLoading?: boolean;
    error?: ApiError;
    refetchTimestamp?: number;
    smarttagsToUpdate?: Required<SmartTagMutateResponse>['tags'];
    systemFlagProps?: {
      smarttagKey: string;
      newValue: boolean;
    };
  };
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'init': {
      return {
        ...initialState,
        isLoading: action.payload?.isLoading ?? false,
        refetchTimestamp: action.payload?.refetchTimestamp,
      };
    }
    case 'done': {
      return {
        ...state,
        smarttags: action.payload?.smarttags,
        isLoading: false,
        isError: false,
      };
    }
    case 'error': {
      return {
        ...state,
        error: action.payload?.error,
        isError: true,
        isLoading: false,
        smarttags: state.smarttags ?? [],
      };
    }
    // tato akcia nahradi smart:tagy daneho kluca alebo klucov v store
    // neexistujuce tagy doplni
    // TODO: vymazane tagy ostavaju, pretoze samotny kluc ostava v DB v kolekcii klucov, len
    // nema values
    case 'udpateSmarttags': {
      const reducedSmarttagsToUpdate = reduceSmarttags(
        action.payload?.smarttagsToUpdate ?? []
      );

      // update existujucich
      const updatedSmarttags = (state.smarttags ?? []).map((smarttag) => {
        const keyToUpdate = reducedSmarttagsToUpdate.find(
          (i) => i.key === smarttag.key
        );
        if (keyToUpdate) {
          return keyToUpdate;
        }
        return smarttag;
      });

      // skusime doplnit nove tagy (eixistuju v payloade pre update, ale nie v store)
      const newSmarttags = reducedSmarttagsToUpdate.filter(
        (smarttagToUpdate) =>
          (state.smarttags ?? []).findIndex(
            (smarttag) => smarttag.key === smarttagToUpdate.key
          ) === -1
      );

      // zoradime
      const smarttags = sortSmarttags([...updatedSmarttags, ...newSmarttags]);
      return {
        ...state,
        smarttags,
      };
    }
    case 'setSystemFlag': {
      const systemFlagProps = action.payload?.systemFlagProps;
      if (systemFlagProps !== undefined) {
        const { smarttagKey, newValue } = systemFlagProps;
        const newSmarttags = (state.smarttags ?? []).map((smarttag) => {
          if (smarttag.key === smarttagKey) {
            return {
              ...smarttag,
              system: newValue,
            };
          }
          return smarttag;
        });
        const smarttags = sortSmarttags(newSmarttags);

        return {
          ...state,
          smarttags,
        };
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

const getRefetchTimestamp = () => {
  return Date.now();
};

const useAllSmarttags = ({ appSpace }: { appSpace: string }) => {
  const [state, dispatch] = React.useReducer(reducer, { ...initialState });
  const [refetchTimestamp, setRefetchTimestamp] =
    React.useState(getRefetchTimestamp);

  const refetch = React.useCallback(() => {
    setRefetchTimestamp(getRefetchTimestamp());
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        dispatch({
          type: 'init',
          payload: {
            refetchTimestamp,
          },
        });
        const response = (await TagmanagerApi.adminGetAllTags(appSpace)) as {
          keys?: string[];
          tags?: Smarttag[];
        };
        const unsortedSmarttags = reduceSmarttags(response.tags ?? []);
        const smarttags = sortSmarttags(unsortedSmarttags) as Smarttag[]; // TODO: skurvene readonly
        dispatch({
          type: 'done',
          payload: {
            smarttags,
          },
        });
      } catch (e) {
        const error = getApiError(e);
        dispatch({
          type: 'error',
          payload: {
            error,
          },
        });
      }
    })();
  }, [appSpace, refetchTimestamp]);

  const updateSmarttagsInStore = React.useCallback(
    (smarttagsToUpdate: Required<SmartTagMutateResponse>['tags']) => {
      dispatch({
        type: 'udpateSmarttags',
        payload: {
          smarttagsToUpdate,
        },
      });
    },
    []
  );

  const setSystemFlagInStore = React.useCallback(
    (smarttagKey: string, newValue: boolean) => {
      dispatch({
        type: 'setSystemFlag',
        payload: {
          systemFlagProps: {
            smarttagKey,
            newValue,
          },
        },
      });
    },
    []
  );

  return { ...state, refetch, updateSmarttagsInStore, setSystemFlagInStore };
};

export default useAllSmarttags;
