export type ThenArg<T> = T extends Promise<infer U>
    ? U
    : T extends (...args: any[]) => Promise<infer U>
    ? U
    : T;


type QueryParameters = { [key: string]: string | number | string[] | number[] | boolean | boolean[] | undefined };

export type Sector = {
  _id: string
;
  category: "sport"
;
  itemId: string
;
  sectorId: string
;
  matricityPPO?: string
;
}
;

export type PushToken = {
  tokenId: string
;
  token: string
;
  platform: "ANDROID" | "IOS" | "WEB"
;
  type: "DEBUG" | "RELEASE"
;
  name?: string
;
  badge?: number
;
}
;

export type AppSpace = {
  _id: string
;
  user_id: string
;
  app_id: string
;
  app_space: string
;
  display_name?: string|null
;
  role: string
;
  child_apps_role?: Array<{
  app_id: string
;
  role: string|null
;
}
>
;
  grant: boolean
;
  validTo?: string|null
;
  org_profile?: {
  _id?: string
;
  name?: string
;
  type?: string
;
  sport?: string
;
  status?: string
;
  relations?: {
  related_ppo?: string
;
  date_from?: string
;
  date_to?: string|null
;
  member_until?: string|null
;
  status?: elementary_itemStatus
;
}
;
  parent_ppo?: string|null
;
  organization_id?: string
;
  organization_name?: string
;
  logo_public_url?: string|null
;
  favicon_public_url?: string|null
;
}
;
}
;

export type AppSpaceUser = {
  user_id: string
;
  app_id: string
;
  app_space: string
;
  display_name?: string|null
;
  role: string
;
  child_apps_role?: Array<{
  app_id: string
;
  role: string|null
;
}
>
;
  grant: boolean
;
  validTo?: string|null
;
  org_profile?: {
  _id?: string
;
  name?: string
;
  type?: string
;
  sport?: string
;
  status?: string
;
  relations?: {
  related_ppo?: string
;
  date_from?: string
;
  date_to?: string|null
;
  member_until?: string|null
;
  status?: elementary_itemStatus
;
}
;
  parent_ppo?: string|null
;
  organization_id?: string
;
  organization_name?: string
;
  logo_public_url?: string|null
;
  favicon_public_url?: string|null
;
}
;
}
;

export type UserProfileUpdate_Admin = {
  username?: string
;
  idnr?: string|null
;
  name?: string
;
  surname?: string
;
  family_name?: string
;
  titles?: {
  before: string
;
  after: string
;
}
;
  phone?: string
;
  email?: elementary_email
;
  sex?: elementary_sex
;
  birthdate?: string|null
;
  deathdate?: string|null
;
  birthplace?: string
;
  birthcountry?: string
;
  shoesize?: string
;
  dresssize?: string
;
  nationality?: string
;
  citizenship?: string
;
  membership_status?: string
;
  import_id?: string
;
  external_id?: string
;
}
;

export type UserCreate_CRM = {
  sport_sector: string
;
  user_profile: {
  username: string
;
  birthdate: string
;
  idnr?: string|null
;
  name: string
;
  surname: string
;
  email: elementary_email
;
  birthcountry: string
;
  citizenship: string
;
  nationality?: string
;
}
;
}
;

export type UserProfileUpdate = {
  name?: string
;
  surname?: string
;
  family_name?: string
;
  titles?: {
  before: string
;
  after: string
;
}
;
  phone?: string
;
  email?: elementary_email
;
  sex?: elementary_sex
;
  birthdate?: string|null
;
  birthplace?: string
;
  birthcountry?: string
;
  shoesize?: string
;
  dresssize?: string
;
  nationality?: string
;
  citizenship?: string
;
  photo?: {
  public?: boolean
;
}
;
}
;

export type UserProfileUpdate_Registrar = {
  name?: string
;
  surname?: string
;
  family_name?: string
;
  titles?: {
  before: string
;
  after: string
;
}
;
  phone?: string
;
  email?: elementary_email
;
  sex?: elementary_sex
;
  birthdate?: string|null
;
  deathdate?: string|null
;
  idnr?: string
;
  birthcountry?: string
;
  nationality?: string
;
  citizenship?: string
;
  formalPhoto?: {
  public: boolean
;
}
;
}
;

export type UserExternalProfile = {
  external_id?: string
;
  external_service?: string
;
  user_info_update?: string
;
  user_info?: {
}
;
}
;

export type UserProfile = {
  _id?: string
;
  name?: string
;
  surname?: string
;
  family_name?: string
;
  titles?: {
  before?: string
;
  after?: string
;
}
;
  deleteAfter?: string
;
  email?: string
;
  phone?: string
;
  sex?: elementary_sex
;
  birthdate?: string|null
;
  birthplace?: string
;
  birthcountry?: string
;
  deathdate?: string|null
;
  shoesize?: string
;
  dresssize?: string
;
  nationality?: string
;
  citizenship?: string
;
  membership_status?: string
;
  import_id?: string
;
  external_id?: string
;
  created_datetime?: string
;
  email_verified?: string|null
;
  username?: string
;
  photo?: object|null
;
  idnr?: string|null
;
  ppoRelation?: UserPPORelation
;
  formalPhoto?: UserFormalPhoto
;
}
;

export type UserPPORelation = {
  ppo?: string
;
  flags?: Array<string
>
;
}
;

export type UserBasicProfile = {
  _id?: string
;
  name?: string
;
  surname?: string
;
  family_name?: string
;
  titles?: {
  before?: string
;
  after?: string
;
}
;
  deleteAfter?: string
;
}
;

export type UserBasicProfileWithGroups = {
  _id?: string
;
  name?: string
;
  surname?: string
;
  family_name?: string
;
  titles?: {
  before?: string
;
  after?: string
;
}
;
  deleteAfter?: string
;
  groups?: Array<{
  _id?: string
;
  name?: string
;
}
>
;
}
;

export type UserSportOrg = {
  org_id?: string
;
  org_name?: string
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  source_ppo?: string
;
  source_ppo_name?: string
;
  sector?: Sector
;
  competence_type?: string
;
  date_from?: string
;
  date_to?: string|null
;
  transfer_type?: string
;
  valid_to?: string|null
;
}
;

export type UserBasicProfileWithAffiliations = {
  _id?: string
;
  name?: string
;
  surname?: string
;
  family_name?: string
;
  titles?: {
  before?: string
;
  after?: string
;
}
;
  deleteAfter?: string
;
  photo?: {
  token?: string
;
  public?: boolean
;
  public_url?: string
;
}
;
  membership?: {
  regnr?: string
;
}
;
  sport_orgs?: Array<UserSportOrg
>
;
  sport_expert_orgs?: Array<{
  _id?: string
;
  org_id?: string
;
  org_profile_id?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  sector?: Sector
;
  competence_type?: string
;
  date_from?: string
;
  date_to?: string|null
;
}
>
;
  regnrs?: Array<{
}
>
;
  bio?: Array<{
  _id?: string
;
  org_id?: string
;
  org_name?: string
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  photo?: MediaManagerPhoto
;
  perex?: string
;
  facebook?: string
;
  twitter?: string
;
  instagram?: string
;
  youtube?: string
;
  widgets?: Array<Widget
>
;
}
>
;
  awards?: Array<{
  _id?: elementary__id
;
  org_profile_id?: string
;
  sport?: string
;
  sport_sector?: string
;
  award_type?: string
;
  issue_date?: string
;
  issued_by?: string
;
  created_datetime?: string
;
  org_id?: string
;
}
>
;
  custom?: Array<{
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
>
;
}
;

export type UserPublicProfile = {
  _id?: string
;
  name?: string
;
  surname?: string
;
  family_name?: string
;
  titles?: {
  before?: string
;
  after?: string
;
}
;
  deleteAfter?: string
;
  email?: string
;
  phone?: string
;
  sex?: elementary_sex
;
  birthdate?: string|null
;
  birthplace?: string
;
  birthcountry?: string
;
  deathdate?: string|null
;
  shoesize?: string
;
  dresssize?: string
;
  nationality?: string
;
  citizenship?: string
;
  membership_status?: string
;
  import_id?: string
;
  external_id?: string
;
  created_datetime?: string
;
  email_verified?: string|null
;
  username?: string
;
  photo?: object|null
;
}
;

export type TokenInfo = {
  client_id?: string
;
  token_type?: "Bearer"
;
  expires_at?: number
;
  expires_in?: number
;
  expires_at_date?: string
;
  user_id?: string
;
  scopes?: Array<string
>
;
  userinfo?: UserPublicProfile
;
  usergroups?: Array<{
  ppo?: string
;
  group?: Array<string
>
;
}
>
;
  appspace?: AppSpaceUser
;
  org_info?: Organization
;
  ppo_info?: OrganizationProfile
;
}
;

export type elementary_sex = "M" | "F" | null
;

export type elementary_email = string
;

export type elementary_itemStatus = "active" | "inactive"
;

export type elementary__id = string
;

export type Error = {
  statusCode?: number
;
  name?: string
;
  description?: string|null
;
  payload?: object|null
;
  userinfo?: string
;
}
;

export type AddressCreateOrUpdate = {
  street: string
;
  number: string|number
;
  conscription_number?: string|number
;
  city: string
;
  zip: string
;
  country: string
;
  name?: string
;
  surname?: string
;
  company?: string
;
  type: string
;
  status: elementary_itemStatus
;
  location?: GeoLocation
;
}
;

export type Address = {
  street?: string
;
  number?: string|number
;
  conscription_number?: string|number
;
  city?: string
;
  zip?: string
;
  country?: string
;
  name?: string
;
  surname?: string
;
  company?: string
;
  type?: string
;
  location?: GeoLocation
;
}
;

export type GeoLocation = object|null
;

export type BankAccount = {
  IBAN?: string
;
  type?: string
;
}
;

export type BankAccountCreateOrUpdate = {
  IBAN: string
;
  type: string
;
  status: elementary_itemStatus
;
}
;

export type CustomField = {
  org_profile_id: string
;
  label: string
;
  value: string
;
}
;

export type Widget = {
  type: string
;
}
;

export type CustomFieldCreateOrUpdate = {
  org_profile_id: string
;
  label: string
;
  value: string
;
}
;

export type ItemStatus = {
  status?: elementary_itemStatus
;
}
;

export type ItemId = {
  _id?: elementary__id
;
}
;

export type UserSportExpertOrg = {
  org_id?: string
;
  org_profile_id?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  sector?: Sector
;
  competence_type?: string
;
  legal_form?: string
;
  license_id?: string
;
  license?: UserLicense
;
  date_from?: string
;
  date_to?: string|null
;
  valid_to?: string|null
;
}
;

export type FileObject = {
  _id?: elementary__id
;
  name?: string
;
  mimetype?: string
;
  size?: number
;
  filepath?: string
;
}
;

export type UserLicense = {
  org_profile_id?: string
;
  type?: string
;
  name?: string
;
  documentnr?: string
;
  valid_from?: string
;
  valid_to?: string|null
;
  issued_by_po?: string
;
  verified_by_person?: string
;
  verified_by_position?: string
;
  registration_date?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  note?: string
;
  files?: Array<FileObject
>
;
}
;

export type IssuerLicense = {
  _id?: string
;
  name?: string
;
  type?: string
;
  subTypeId?: number
;
}
;

export type UserLicenseCreateOrUpdate = {
  org_profile_id: string
;
  type: string
;
  name: string
;
  documentnr: string
;
  valid_from: string
;
  valid_to?: string|null
;
  issued_by_po: string
;
  verified_by_person: string
;
  verified_by_position: string
;
  registration_date: string
;
  note?: string
;
}
;

export type UserDocument = {
  org_profile_id?: string
;
  name?: string
;
  mimetype?: string
;
  size?: number
;
  filepath?: string
;
  type?: string
;
  created_date?: string
;
  note?: string
;
}
;

export type Pager = {
  total?: number
;
  limit?: number
;
  offset?: number
;
  next_offset?: number|null
;
}
;

export type UserSportOrgCreate = {
  sector: Sector
;
  competence_type: string
;
  org_id?: string
;
  org_profile_id: string
;
  transfer_type: string
;
  date_from: string
;
  date_to?: string|null
;
}
;

export type UserSportOrgUpdate = {
  date_from: string|null
;
  date_to?: string|null
;
  transfer_type: string
;
  competence_type: string
;
  status?: elementary_itemStatus
;
}
;

export type UserSportExpertOrgCreate = {
  sector: Sector
;
  org_profile_id: string
;
  competence_type: string
;
  legal_form: string
;
  license_id?: string|null
;
  date_from: string
;
  date_to?: string|null
;
}
;

export type UserSportExpertOrgUpdate = {
  date_from: string|null
;
  date_to?: string|null
;
  legal_form: string
;
  competence_type: string
;
  license_id?: string|null
;
}
;

export type App = {
  _id: string
;
  secret: string
;
  name: string
;
  primary?: boolean
;
  allowed_hosts: Array<string
>
;
  response_types?: Array<string
>
;
  description?: string
;
  url?: string
;
  marketingUrl?: string|null
;
  appSpaceUrlPattern?: string
;
  logo?: string
;
  roles?: Array<AppRole
>
;
  parent_apps?: Array<string
>
;
}
;

export type AppPublic = {
  _id?: string
;
  name?: string
;
  description?: string|null
;
  url?: string|null
;
  marketingUrl?: string|null
;
  appSpaceUrlPattern?: string|null
;
  action_buttons?: Array<{
  label: string
;
  urlPattern: string
;
  roles?: Array<string
>
;
}
>
;
  logo?: string|null
;
  roles?: Array<AppRole
>
;
  flags?: Array<string
>
;
  child_apps?: Array<AppPublic_
>
;
}
;

export type AppPublic_ = AppPublic
;

export type AppRole = {
  role: string
;
  name?: string
;
}
;

export type AppCreate = {
  _id: string
;
  secret: string
;
  name: string
;
  allowed_hosts: Array<string
>
;
  response_types?: Array<string
>
;
  description?: string|null
;
  url?: string|null
;
  logo?: string|null
;
  roles?: Array<AppRole
>
;
  parent_apps?: Array<string
>
;
}
;

export type AppUpdate = {
  secret?: string
;
  name?: string
;
  allowed_hosts?: Array<string
>
;
  response_types?: Array<string
>
;
  description?: string|null
;
  url?: string|null
;
  logo?: string|null
;
  roles?: Array<AppRole
>
;
  parent_apps?: Array<string
>
;
}
;

export type Organization = {
  _id?: string
;
  full_name?: string
;
  name?: string
;
  legal_form?: string
;
  business_id?: string
;
  vat_nr?: string|null
;
  vat_foreign_trade_info?: string
;
  vat_alternatives?: Array<string
>
;
  email?: string
;
  www?: string
;
  phone?: string
;
  fax?: string
;
  contact_person?: string
;
  statutory?: string
;
  logo_public_url?: string|null
;
  favicon_public_url?: string|null
;
}
;

export type OrganizationWithProfiles = {
  _id?: string
;
  full_name?: string
;
  name?: string
;
  legal_form?: string
;
  business_id?: string
;
  vat_nr?: string|null
;
  vat_foreign_trade_info?: string
;
  vat_alternatives?: Array<string
>
;
  email?: string
;
  www?: string
;
  phone?: string
;
  fax?: string
;
  contact_person?: string
;
  statutory?: string
;
  logo_public_url?: string|null
;
  favicon_public_url?: string|null
;
  profiles?: Array<OrganizationProfile
>
;
}
;

export type OrganizationChangeRequest = {
  full_name?: string
;
  name?: string
;
  legal_form?: string
;
  business_id?: string
;
  vat_nr?: string|null
;
  email?: string
;
  www?: string
;
  phone?: string
;
  fax?: string
;
  contact_person?: string
;
  statutory?: string
;
  bank_accounts?: Array<{
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
>
;
  addresses?: Array<{
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  street?: string
;
  number?: string|number
;
  conscription_number?: string|number
;
  city?: string
;
  zip?: string
;
  country?: string
;
  name?: string
;
  surname?: string
;
  company?: string
;
  type?: string
;
  location?: GeoLocation
;
}
>
;
  custom?: Array<{
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
>
;
}
;

export type OrganizationUpdate = {
  full_name?: string
;
  name?: string
;
  legal_form?: string
;
  business_id?: string
;
  vat_nr?: string|null
;
  email?: string
;
  www?: string
;
  phone?: string
;
  fax?: string
;
  contact_person?: string
;
  statutory?: string
;
}
;

export type OrganizationCreate = {
  full_name: string
;
  name?: string
;
  legal_form: string
;
  business_id: string
;
  vat_nr?: string|null
;
  email?: string
;
  www?: string
;
  phone?: string
;
  fax?: string
;
  contact_person?: string
;
  statutory?: string
;
}
;

export type OrganizationProfileStructureItem = {
  _id: string
;
  name: string
;
  logo_public_url?: string
;
  subs?: Array<OrganizationProfileStructureItem
>
;
}
;

export type OrganizationShortName = {
  _id: string
;
  shortname: string
;
  org_profile_id: string
;
}
;

export type OrganizationProfile = {
  _id?: string
;
  name?: string
;
  country?: string
;
  type?: string
;
  sectors?: Array<{
  category?: "sport"
;
  itemId?: string
;
  sectorId?: string
;
  matricityPPO?: string
;
  _id?: string
;
}
>
;
  sport?: string
;
  status?: string
;
  parent_relations?: Array<OrganizationProfile2
>
;
  matricity_relations?: Array<OrganizationProfile2
>
;
  organization_id?: string
;
  organization_name?: string
;
  logo_public_url?: string|null
;
  favicon_public_url?: string|null
;
  shortNames?: Array<OrganizationShortName
>
;
}
;

export type OrganizationProfile2 = OrganizationProfile
;

export type OrganizationProfileRelation = {
  related_ppo?: string
;
  date_from?: string
;
  date_to?: string|null
;
  member_until?: string|null
;
  status?: elementary_itemStatus
;
  related_ppo_name?: string
;
  related_ppo_organization_name?: string
;
}
;

export type OrganizationProfileRelationCreate = {
  related_ppo: string
;
  date_from: string
;
  date_to?: string|null
;
  member_until?: string|null
;
  status: elementary_itemStatus
;
}
;

export type OrganizationProfileRelationUpdate = {
  date_from?: string|null
;
  date_to?: string|null
;
  member_until?: string|null
;
  status?: string
;
}
;

export type OrganizationProfileCreate = {
  _id: string
;
  name: string
;
  status?: string
;
  type: string
;
  sport: string|null
;
  parent_ppo?: string|null
;
}
;

export type OrganizationProfileUpdate = {
  name?: string
;
  type?: string
;
  status?: string
;
  sport?: string|null
;
  parent_ppo?: string|null
;
}
;

export type Codelist = {
  codelist?: Array<{
  value: string
;
  label?: string
;
  default?: boolean
;
}
>
;
}
;

export type UserAward = {
  org_profile_id?: string
;
  sport?: string
;
  sport_sector?: string
;
  award_type?: string
;
  issue_date?: string
;
  issued_by?: string
;
  created_datetime?: string
;
  org_id?: string
;
}
;

export type UserAwardCreate = {
  org_profile_id: string
;
  sport: string
;
  sport_sector: string
;
  award_type: string
;
  issue_date: string
;
  issued_by?: string
;
}
;

export type UserAwardUpdate = {
  org_profile_id?: string
;
  sport?: string
;
  sport_sector?: string
;
  award_type?: string
;
  issue_date?: string
;
  issued_by?: string
;
}
;

export type UserMembership = {
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  valid_from?: string
;
  valid_to?: string
;
  status?: UserMembershipStatus
;
}
;

export type UserMembershipStatus = "active" | "inactive" | "cancelled" | "excluded"
;

export type UserMembershipCreate = {
  org_profile_id: string
;
  status: UserMembershipStatus
;
  valid_from: string
;
  valid_to: string
;
}
;

export type UserMembershipUpdate = {
  status?: UserMembershipStatus
;
  valid_from?: string
;
  valid_to?: string
;
}
;

export type UserRegnr = {
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  regnr?: string
;
}
;

export type UserRegnrCreate = {
  org_profile_id: string
;
  regnr?: string
;
  useSequence?: boolean
;
}
;

export type UserRegnrUpdate = {
  regnr?: string
;
}
;

export type UserFormalPhoto = {
  token?: string
;
  public?: boolean
;
  public_url?: string
;
}
;

export type UserMedicalExamination = {
  org_profile_id: string
;
  doctor?: string
;
  valid_from?: string
;
  valid_to?: string|null
;
}
;

export type UserMedicalExaminationCreateOrUpdate = {
  org_profile_id: string
;
  doctor: string
;
  valid_from: string
;
  valid_to?: string|null
;
}
;

export type PpoRelation = {
  ppo?: string
;
  org_profile_name?: string
;
  org_id?: string
;
  org_name?: string
;
  flags?: Array<string
>
;
}
;

export type RequestCreate = {
  user?: {
  email: elementary_email
;
  name?: string
;
  surname?: string
;
  phone?: string
;
}
;
  note?: string
;
}
;

export type RequestCallbacks = Array<{
  url: string
;
  payload?: {
}
;
}
>
;

export type PoCreate = {
  organization?: OrganizationCreate
;
  profile?: {
  name?: string
;
  type?: string
;
}
;
  address?: Address
;
  addresses?: Array<Address
>
;
  bank_account?: BankAccount
;
  targetGroups?: Array<{
  targetGroupIdx?: number
;
  months?: Array<string
>
;
}
>
;
  users?: Array<{
  sportnetId: string
;
  name: string
;
  license_type: string
;
  license_id: string
;
}
>
;
}
;

export type RequestCreate_PoRegistry = {
  user?: {
  email: elementary_email
;
  name?: string
;
  surname?: string
;
  phone?: string
;
}
;
  note?: string
;
  callbacks?: RequestCallbacks
;
  data?: {
  po?: PoCreate
;
  source?: string
;
}
;
}
;

export type Request = {
  _id: string
;
  appSpace: string
;
  request_type: string
;
  confirmedBy?: Array<{
}
>
;
  workflow?: {
  transitions?: {
}
;
}
;
  status: "REJECTED" | "CANCELLED" | "APPROVED" | "NEW" | "PARTIALLY_CONFIRMED" | "CONFIRMED" | "CLOSED"
;
  ownerUser?: {
  display_name: string
;
  email: elementary_email
;
  sportnet_id: string
;
}
;
  last_update?: string
;
  created_date: string
;
  status_log?: Array<{
  change_date?: string
;
  old_status?: string
;
  new_status?: string
;
  admin_user?: {
  display_name?: string
;
  sportnet_id?: string
;
}
;
  ip?: string|null
;
  ips?: Array<string|null>|null
;
}
>
;
  paymentItem?: Array<PPOPricelistItem
>
;
  paygate?: Array<{
  ordernumber?: number
;
  createdDate?: string
;
}
>
;
  payment?: {
  ordernumber?: number
;
  paid_date?: string
;
}
;
  user?: {
  email: elementary_email
;
  name?: string
;
  surname?: string
;
  phone?: string
;
  sportnet_id?: string
;
}
;
  note?: string
;
  user_note?: string
;
  data?: {
}
;
}
;

export type RequestListItem = {
  _id: string
;
  request_type: string
;
  status: "REJECTED" | "CANCELLED" | "APPROVED" | "NEW" | "PARTIALLY_CONFIRMED" | "CONFIRMED" | "CLOSED"
;
  ownerUser?: {
  display_name: string
;
  email: elementary_email
;
  sportnet_id: string
;
}
;
  created_date: string
;
  data?: {
}
;
}
;

export type InvoiceItem = {
  ppo?: string
;
  invoicenr?: string
;
  storno?: boolean
;
  stornoDate?: string
;
  stornoBy?: {
  sportnetId?: string
;
  displayName?: string
;
}
;
  deliveryDate?: string
;
  payDueDate?: string
;
  issuedBy?: string
;
  seller?: Organization
;
  buyer?: {
  name?: string
;
  surname?: string
;
  invoice_address?: {
  address?: string
;
  city?: string
;
  zip?: string
;
}
;
}
;
  items?: Array<{
}
>
;
}
;

export type PPOPricelistItem = {
  _id?: string
;
  type: "membership" | "application" | "applicationPcn" | "ppomembership"
;
  name: string
;
  applicationId?: string
;
  price?: Price
;
  pcn?: number
;
  description?: string
;
  ageFrom?: number
;
  ageTo?: number|null
;
  sex?: elementary_sex
;
  validity?: {
  days?: number
;
  dateTo?: string
;
}
;
}
;

export type PPOPricelistItem_CreateOrUpdate = {
  type: "membership" | "application" | "applicationPcn" | "ppomembership"
;
  name: string
;
  applicationId?: string
;
  price?: Price
;
  pcn?: number
;
  description?: string
;
  ageFrom?: number
;
  ageTo?: number|null
;
  sex?: elementary_sex
;
  validity?: {
  days?: number
;
  dateTo?: string
;
}
;
}
;

export type Sequence = {
  _id?: string
;
  value?: number
;
  pattern?: string
;
}
;

export type Price = {
  amount: number
;
  amount_vat_excl: number
;
  currency: "EUR"
;
  vat_rate: number
;
}
;

export type PaygateInfo = {
  redirect?: string
;
  request?: Request
;
  gpwebpay?: {
  url: string
;
  params: Array<{
  name?: string
;
  value?: string|number|number
;
}
>
;
}
;
}
;

export type Courier_Message_Parameters = {
  subject: string
;
  perex: string
;
  widgets?: Array<{
}
>
;
}
;

export type Courier_Recipient_Parameters = {
  targetPPO?: string
;
  users?: Array<string
>
;
  userGroups?: Array<string
>
;
  apps?: Array<{
  appId: string
;
  roles?: Array<string>|null
;
}
>
;
  sportAthlets?: Array<{
  activeOnly?: boolean
;
}
>
;
  sportExperts?: Array<{
  activeOnly?: boolean
;
  types?: Array<string
>
;
}
>
;
  relatedPPOs?: Array<{
  status?: "active" | "inactive"
;
  crmroles?: Array<string
>
;
}
>
;
}
;

export type Courier_Template = {
  _id?: string
;
  appSpace?: string
;
  sender?: string
;
  senderAppId?: string|null
;
  date_sent?: string
;
  parameters?: {
  message_parameters?: Courier_Message_Parameters
;
  recipient_parameters?: Courier_Recipient_Parameters
;
}
;
}
;

export type Courier_Template_Create = {
  message_parameters: Courier_Message_Parameters
;
  recipient_parameters: Courier_Recipient_Parameters
;
}
;

export type Courier_Message = {
  _id?: string
;
  template_id?: string
;
  user_id?: string
;
  app_id?: string
;
  appSpace?: string|null
;
  sender?: string
;
  subject?: string
;
  perex?: string
;
  widgets?: Array<{
}
>
;
  date_sent?: string
;
  date_first_read?: string|null
;
  date_last_read?: string|null
;
  read?: boolean
;
  important?: boolean
;
  organization?: {
  _id?: string
;
  name?: string
;
  organization_id?: string
;
  organization_name?: string
;
}
;
}
;

export type User_Groups_List = Array<User_Group
>
;

export type User_Group = {
  _id?: string
;
  name?: string
;
}
;

export type User_Group_Create = {
  _id: string
;
  name: string
;
}
;

export type User_Group_Modify = {
  name?: string
;
}
;

export type PPOContactInfo = {
  email?: string
;
  www?: string
;
  phone?: string
;
  fax?: string
;
  name?: string
;
  country?: string
;
  contactPerson?: string
;
  address?: Address
;
}
;

export type PPOTheme = {
  color?: {
  primary?: string
;
}
;
}
;

export type Invitation = {
  _id: elementary__id
;
  appId: string
;
  createdDT: string
;
  ppo: string
;
  title: string
;
  description: string
;
  email: elementary_email
;
  callback: string
;
  expiration: string|null
;
  payload?: {
}
;
  status?: string
;
  sportnetId?: string
;
  resolved?: {
  dt?: string
;
  ip?: string|null
;
  ips?: Array<string|null>|null
;
  userAgent?: string|null
;
}
;
  pending?: {
  status?: string
;
  sportnetId?: string
;
}
;
  exitUrl?: {
  accepted?: string
;
  rejected?: string
;
}
;
  senderUser?: {
  sportnetId?: string
;
  displayName?: string
;
}
;
}
;

export type InvitationPublic = {
  _id: elementary__id
;
  createdDT: string
;
  ppo: string
;
  title: string
;
  description: string
;
  expiration: string|null
;
  status?: string
;
  exitUrl?: {
  accepted?: string
;
  rejected?: string
;
}
;
}
;

export type InvitationUser = {
  _id: elementary__id
;
  appId: string
;
  createdDT: string
;
  ppo: string
;
  title: string
;
  description: string
;
  email: elementary_email
;
  expiration: string|null
;
  status?: string
;
  sportnetId?: string
;
  resolved?: {
  dt?: string
;
  ip?: string|null
;
  ips?: Array<string|null>|null
;
  userAgent?: string|null
;
}
;
  exitUrl?: {
  accepted?: string
;
  rejected?: string
;
}
;
  senderUser?: {
  sportnetId?: string
;
  displayName?: string
;
}
;
}
;

export type PPOShortname = {
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  shortname?: string
;
}
;

export type PPOShortnameCreate = {
  shortname: string
;
}
;

export type PPOShortnameUpdate = {
  shortname?: string
;
}
;

export type PPOMembership = {
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  valid_from?: string
;
  valid_to?: string
;
  status?: PPOMembershipStatus
;
}
;

export type PPOMembershipStatus = "active" | "inactive" | "cancelled" | "excluded"
;

export type PPOMembershipCreate = {
  status: PPOMembershipStatus
;
  valid_from: string
;
  valid_to: string
;
}
;

export type PPOMembershipUpdate = {
  status?: PPOMembershipStatus
;
  valid_from?: string
;
  valid_to?: string
;
}
;

export type PPOContactPerson = {
  sportnetId?: string
;
  name?: string
;
  surname?: string
;
  titles?: {
  before: string
;
  after: string
;
}
;
  email?: Array<string
>
;
  phone?: Array<string
>
;
  position?: string
;
}
;

export type PPOContactPersonCreate = {
  sportnetId: string|null
;
  name: string
;
  surname: string
;
  titles?: {
  before: string
;
  after: string
;
}
;
  email?: Array<string
>
;
  phone?: Array<string
>
;
  position: string
;
}
;

export type PPOContactPersonUpdate = {
  name?: string
;
  surname?: string
;
  titles?: {
  before: string
;
  after: string
;
}
;
  email?: Array<string
>
;
  phone?: Array<string
>
;
  position?: string
;
}
;

export type InvoiceItem_Create = {
  _id?: string
;
  name: string
;
  subtitle?: string
;
  quantity: number
;
  totalPrice: Price
;
  unitPrice?: Price
;
  appId: string
;
  createdDT?: string
;
  status?: "paid" | "unpaid" | "storno"
;
  payload?: {
}
;
  payments?: Array<{
  method: "SPORTNET_PAYGATE" | "OTHER"
;
  amount: number
;
  paymentDT: string
;
  payload?: {
}
;
}
>
;
}
;

export type FavoriteItem = {
  _id: string
;
  appId: string
;
  appSpace: string
;
  appFavType: string
;
  appItemId: string
;
  title: string
;
  subtitle?: string
;
  image?: string
;
  data?: {
}
;
  createdDT?: string
;
}
;

export type FavoriteItemCreate = {
  appSpace: string
;
  appFavType: string
;
  appItemId: string
;
  title: string
;
  subtitle?: string
;
  image?: string
;
  data?: {
}
;
}
;

export type MediaManagerPhoto = {
  media_url: string
;
  public_url: string
;
  dimensions?: {
  X: number
;
  Y: number
;
  H: number
;
  W: number
;
}
;
}
;

export type UserBio = {
  photo?: MediaManagerPhoto
;
  perex?: string
;
  facebook?: string
;
  twitter?: string
;
  instagram?: string
;
  youtube?: string
;
  widgets?: Array<Widget
>
;
}
;

export type UserBio_CreateOrUpdate = {
  photo?: MediaManagerPhoto
;
  org_profile_id: string
;
  perex?: string
;
  facebook?: string
;
  twitter?: string
;
  instagram?: string
;
  youtube?: string
;
  widgets?: Array<Widget
>
;
}
;


class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}


/**
 * 
 * @class CoreApi
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class CoreApi {

    protected baseUrl: string = "https://api.sportnet.online/v1";
    protected headers: {name: string, value: string}[] = [];
    protected token: string = '';
            protected apiKey: string = '';

    serializeQueryParams(parameters: QueryParameters) {
        return Object.keys(parameters)
            .reduce((acc: string[], p) => {
                const param = parameters[p];
                if(typeof param === 'undefined' || param === '') {
                    return acc;
                }
                return [...acc, `${encodeURIComponent(p)}=${encodeURIComponent(
                String(parameters[p]),
                )}`];
            }, [])
            .join('&');
    }

    protected transformParameter(value: any, transformOperation?: string) {
        switch(transformOperation) {
            case 'joinUsingPipes':
                return Array.isArray(value) ? value.join('|') : value;
            default:
                return value;
        }
    }

    public setBaseUrl(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public setHeaders(headers: {name: string, value: string}[]) {
        this.headers = headers;
    }

    public setToken(token: string) {
        this.token = token;
    }

            public setApiKey(apiKey: string) {
            this.apiKey = apiKey;
            }

    protected appendAuthHeaders(headerParams: Headers) {
      const headers = new Headers(headerParams);
      if (this.token) {
        headers.append('Authorization', `Bearer ${this.token}`);
      }
        if (this.apiKey) {
        headers.append('', this.apiKey);
        }
      return headers;
    }

    private async request(method: string, url: string, body: any, headers: Headers = new Headers(), queryParameters: QueryParameters = {}) {
        const queryParams = queryParameters && Object.keys(queryParameters).length ? this.serializeQueryParams(queryParameters) : null ;
        const urlWithParams = url + (queryParams ? '?' + queryParams : '');

        if(headers.get('Content-Type') === 'multipart/form-data') {
            const form = new FormData();
            for (let k in body) {
                form.append(k, body[k]);
            }
            body = form;
        } else if(headers.get('Content-Type') === 'application/json' && body && Object.keys(body).length > 0) {
            body = JSON.stringify(body);
        } else {
            body = undefined;
        }

        if(headers.get('Content-Type') === 'multipart/form-data') {
            headers.delete('Content-Type');
        }

        if (this.headers.length > 0) {
            this.headers.forEach(h => {
                headers.append(h.name, h.value);
            });
        }

        const response = await fetch(urlWithParams, { method, headers, body,  });

        if (response.status === 204) return response;

        if(response.ok) {
            const responseContentType =
                (response.headers && response.headers.get('Content-Type')) || '';
            if (responseContentType.includes('application/json')) {
                return response.json();
            }
            return response;
        } else {
            const err = new ApiError(response.statusText);
            err.details = await response.json();
            throw err;
        }
    }

    /**
    * Delete push token by UUID. Only push token, created by authorized app can be deleted.
    * @method
    * @name CoreApi#deleteUserPushToken
    */
    deleteUserPushToken(
            
            tokenId: string
,
        parameters: {
        } ,
                        body:
                                                        {
                            }
,
    ): Promise< {
}
  >
    {
        let path = '/me/push-tokens/{tokenId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{tokenId}', tokenId.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Update or Create Push token. Push token is assigned to sportnet user, app_id and appSpace (from authorization).
    * @method
    * @name CoreApi#manageUserPushToken
    */
    manageUserPushToken(
            
        parameters: {
        } ,
                        body:
                                                        PushToken
,
    ): Promise< {
  _id?: elementary__id
;
  tokenId: string
;
  token: string
;
  platform: "ANDROID" | "IOS" | "WEB"
;
  type: "DEBUG" | "RELEASE"
;
  name?: string
;
  badge?: number
;
}
  >
    {
        let path = '/me/push-tokens';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#createInvitation
    */
    createInvitation(
            
        parameters: {
        } ,
                        body:
                                                        {
                              ppo: string
;
                              title: string
;
                              description: string
;
                              email: elementary_email
;
                              callback: string
;
                              expiration: string|null
;
                              payload?: {
}
;
                              exitUrl?: {
  accepted?: string
;
  rejected?: string
;
}
;
                              senderUser?: {
  sportnetId: string
;
  displayName: string
;
}
;
                            }
,
    ): Promise< Invitation
  >
    {
        let path = '/invitations';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#getInvitation
    */
    getInvitation(
            invitationId: string
,
        parameters: {
        }  = {},
    ): Promise< Invitation
  >
    {
        let path = '/invitations/{invitationId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{invitationId}', invitationId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#getAppspaceInvitations
    */
    getAppspaceInvitations(
            appSpace: string
,
            
            
        parameters: {
                        'appId'?: string
,
                        'pending'?: boolean
,
        }  = {},
    ): Promise< {
  items?: Array<Invitation
>
;
}
  >
    {
        let path = '/ppoinvitations/{appSpace}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            if(typeof parameters['appId'] !== 'undefined'){
                queryParameters['appId'] = parameters['appId'];
            }

            queryParameters['appId'] = this.transformParameter(queryParameters['appId']);




              queryParameters['pending'] = false;

            if(typeof parameters['pending'] !== 'undefined'){
                queryParameters['pending'] = parameters['pending'];
            }

            queryParameters['pending'] = this.transformParameter(queryParameters['pending']);





        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#getInvitationPublic
    */
    getInvitationPublic(
            invitationId: string
,
        parameters: {
        }  = {},
    ): Promise< InvitationPublic
  >
    {
        let path = '/invitations/{invitationId}/user';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{invitationId}', invitationId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#resolveInvitation
    */
    resolveInvitation(
            
            invitationId: string
,
        parameters: {
        } ,
                        body:
                                                        {
                              status: "accepted" | "rejected"
;
                            }
,
    ): Promise< Invitation
  >
    {
        let path = '/invitations/{invitationId}/user';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{invitationId}', invitationId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Registracia noveho usera z externého systému na pozadí.

Pouzívateľ sa zaregistruje pokiaľ neexistuje, ak existuje, tak registrácia neprebehne,
len sa doplní informácia o registrácii z externej aplikácie.

**Duplicita sa vyhodnocuje podľa `idnr` teda rodného čísla**, ktoré je povinné pri tomto type registrácie.

Ak používateľ neexistuje, vytvorí sa s prihlasovacím menom vo forme `meno.priezvisko`.

Ak idnr nie je zadane, tak sa pouzivatel neregistruje.

    * @method
    * @name CoreApi#externalRegistration
    */
    externalRegistration(
            
        parameters: {
        } ,
                        body:
                                                        {
                              subjectType: "PO" | "FO" | "SZCO"
;
                              subjectName?: string
;
                              name?: string
;
                              surname?: string
;
                              idnr?: string
;
                              ico?: string
;
                              titles?: {
  before: string
;
  after: string
;
}
;
                              phone?: string
;
                              email?: elementary_email
;
                              sex?: elementary_sex
;
                              birthdate?: string|null
;
                              birthplace?: string
;
                              birthcountry?: string
;
                              shoesize?: string
;
                              dresssize?: string
;
                              nationality?: string
;
                              citizenship?: string
;
                              post_address?: Address
;
                              payload?: {
  _createdBySportnetId?: string
;
  sport_org?: {
  sector: Sector
;
  org_profile_id: string
;
  competenceType?: string
;
  dateFrom?: string
;
}
;
  uniqa?: {
  insurance_nr: string
;
  insurance_amount: Price
;
  insurance_start: string
;
  insurance_end?: string|null
;
  insurance_name: string
;
  commision: Price
;
  donation: Price
;
}
;
}
;
                            }
,
    ): Promise< UserPublicProfile
  >
    {
        let path = '/external/registration';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#organizationPPOProfiles
    */
    organizationPPOProfiles(
            
            
            
            
        parameters: {
                        'ids'?: Array<string
>
,
                        'sectors'?: Array<string
>
,
                        'type'?: Array<string
>
,
                        'q'?: string
,
        }  = {},
    ): Promise< {
  items: Array<OrganizationProfile
>
;
}
  >
    {
        let path = '/ppo';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            if(typeof parameters['ids'] !== 'undefined'){
                queryParameters['ids'] = parameters['ids'];
            }

            queryParameters['ids'] = this.transformParameter(queryParameters['ids']);





            if(typeof parameters['sectors'] !== 'undefined'){
                queryParameters['sectors'] = parameters['sectors'];
            }

            queryParameters['sectors'] = this.transformParameter(queryParameters['sectors']);





            if(typeof parameters['type'] !== 'undefined'){
                queryParameters['type'] = parameters['type'];
            }

            queryParameters['type'] = this.transformParameter(queryParameters['type']);





            if(typeof parameters['q'] !== 'undefined'){
                queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);





        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#organizationPPOProfile
    */
    organizationPPOProfile(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: string
;
  name?: string
;
  country?: string
;
  type?: string
;
  sectors?: Array<{
  category?: "sport"
;
  itemId?: string
;
  sectorId?: string
;
  matricityPPO?: string
;
  _id?: string
;
}
>
;
  sport?: string
;
  status?: string
;
  parent_relations?: Array<OrganizationProfile2
>
;
  matricity_relations?: Array<OrganizationProfile2
>
;
  organization_id?: string
;
  organization_name?: string
;
  logo_public_url?: string|null
;
  favicon_public_url?: string|null
;
  shortNames?: Array<OrganizationShortName
>
;
  createdBy?: {
  type?: string
;
  payload?: {
}
;
}
;
  professional?: boolean
;
  seasonCategories?: Array<{
}
>
;
}
  >
    {
        let path = '/ppo/{appSpace}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Zoznam transferov konkretnej PPO z pohladu zvazu. Zatial su podporovane len futbalsfz.sk a len sektor sport:futbal:futbal
    * @method
    * @name CoreApi#listTransfers
    */
    listTransfers(
            
            
            
            
            
            
            
            
            
            appSpace: "futbalsfz.sk"
,
            sectorId: "sport:futbal:futbal"
,
        parameters: {
                        'limit'?: number
,
                        'offset'?: number
,
                        'transferType'?: "hosting" | "transfer"
,
                        'gender'?: "M" | "F"
,
                        'age'?: "18+" | "18-"
,
                        'dateFrom'?: string
,
                        'dateTo'?: string
,
                        'ppo'?: string
,
                        'ppoDir'?: "in" | "out" | "both"
,
        }  = {},
    ): Promise< {
  total?: number
;
  limit?: number
;
  offset?: number
;
  next_offset?: number|null
;
  transfers?: Array<{
  _id?: string
;
  name?: string
;
  surname?: string
;
  family_name?: string
;
  titles?: {
  before?: string
;
  after?: string
;
}
;
  deleteAfter?: string
;
  photo?: {
  token?: string
;
  public?: boolean
;
  public_url?: string
;
}
;
  birthyear?: number
;
  age?: number
;
  sex?: elementary_sex
;
  citizenship?: string
;
  transfer?: UserSportOrg
;
}
>
;
}
  >
    {
        let path = '/ppo/{appSpace}/transfers/{sectorId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
                queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);




              queryParameters['offset'] = 0;

            if(typeof parameters['offset'] !== 'undefined'){
                queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);





            if(typeof parameters['transferType'] !== 'undefined'){
                queryParameters['transferType'] = parameters['transferType'];
            }

            queryParameters['transferType'] = this.transformParameter(queryParameters['transferType']);





            if(typeof parameters['gender'] !== 'undefined'){
                queryParameters['gender'] = parameters['gender'];
            }

            queryParameters['gender'] = this.transformParameter(queryParameters['gender']);





            if(typeof parameters['age'] !== 'undefined'){
                queryParameters['age'] = parameters['age'];
            }

            queryParameters['age'] = this.transformParameter(queryParameters['age']);





            if(typeof parameters['dateFrom'] !== 'undefined'){
                queryParameters['dateFrom'] = parameters['dateFrom'];
            }

            queryParameters['dateFrom'] = this.transformParameter(queryParameters['dateFrom']);





            if(typeof parameters['dateTo'] !== 'undefined'){
                queryParameters['dateTo'] = parameters['dateTo'];
            }

            queryParameters['dateTo'] = this.transformParameter(queryParameters['dateTo']);





            if(typeof parameters['ppo'] !== 'undefined'){
                queryParameters['ppo'] = parameters['ppo'];
            }

            queryParameters['ppo'] = this.transformParameter(queryParameters['ppo']);




              queryParameters['ppoDir'] = "both";

            if(typeof parameters['ppoDir'] !== 'undefined'){
                queryParameters['ppoDir'] = parameters['ppoDir'];
            }

            queryParameters['ppoDir'] = this.transformParameter(queryParameters['ppoDir']);





            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{sectorId}', sectorId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#organizationPPOListRelatedPPOs
    */
    organizationPPOListRelatedPPOs(
            
            
            
            
            
            appSpace: string
,
        parameters: {
                        'q'?: string
,
                        'ppoType'?: Array<string
>
,
                        'relationStatus'?: string
,
                        'sports'?: Array<string
>
,
                        'with'?: Array<"shortNames"
>
,
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: string
;
  name?: string
;
  country?: string
;
  type?: string
;
  sectors?: Array<{
  category?: "sport"
;
  itemId?: string
;
  sectorId?: string
;
  matricityPPO?: string
;
  _id?: string
;
}
>
;
  sport?: string
;
  status?: string
;
  parent_relations?: Array<OrganizationProfile2
>
;
  matricity_relations?: Array<OrganizationProfile2
>
;
  organization_id?: string
;
  organization_name?: string
;
  logo_public_url?: string|null
;
  favicon_public_url?: string|null
;
  shortNames?: Array<OrganizationShortName
>
;
  relation?: OrganizationProfileRelation
;
}
>
;
}
  >
    {
        let path = '/ppo/{appSpace}/related-ppos';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            if(typeof parameters['q'] !== 'undefined'){
                queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);





            if(typeof parameters['ppoType'] !== 'undefined'){
                queryParameters['ppoType'] = parameters['ppoType'];
            }

            queryParameters['ppoType'] = this.transformParameter(queryParameters['ppoType']);





            if(typeof parameters['relationStatus'] !== 'undefined'){
                queryParameters['relationStatus'] = parameters['relationStatus'];
            }

            queryParameters['relationStatus'] = this.transformParameter(queryParameters['relationStatus']);





            if(typeof parameters['sports'] !== 'undefined'){
                queryParameters['sports'] = parameters['sports'];
            }

            queryParameters['sports'] = this.transformParameter(queryParameters['sports']);





            if(typeof parameters['with'] !== 'undefined'){
                queryParameters['with'] = parameters['with'];
            }

            queryParameters['with'] = this.transformParameter(queryParameters['with']);





            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#organizationRelatedPPO
    */
    organizationRelatedPPO(
            appSpace: string
,
            ppo: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: string
;
  name?: string
;
  country?: string
;
  type?: string
;
  sectors?: Array<{
  category?: "sport"
;
  itemId?: string
;
  sectorId?: string
;
  matricityPPO?: string
;
  _id?: string
;
}
>
;
  sport?: string
;
  status?: string
;
  parent_relations?: Array<OrganizationProfile2
>
;
  matricity_relations?: Array<OrganizationProfile2
>
;
  organization_id?: string
;
  organization_name?: string
;
  logo_public_url?: string|null
;
  favicon_public_url?: string|null
;
  shortNames?: Array<OrganizationShortName
>
;
  relation?: OrganizationProfileRelation
;
}
  >
    {
        let path = '/ppo/{appSpace}/related-ppos/{ppo}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{ppo}', ppo.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#getPPORelationsStructure
    */
    getPPORelationsStructure(
            
            appSpace: string
,
        parameters: {
                        'ppoType'?: string
,
        }  = {},
    ): Promise< {
  items: Array<OrganizationProfileStructureItem
>
;
}
  >
    {
        let path = '/ppo/{appSpace}/relations-structure';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            if(typeof parameters['ppoType'] !== 'undefined'){
                queryParameters['ppoType'] = parameters['ppoType'];
            }

            queryParameters['ppoType'] = this.transformParameter(queryParameters['ppoType']);





            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#getPPOLicensesIssuer
    */
    getPPOLicensesIssuer(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items: Array<IssuerLicense
>
;
}
  >
    {
        let path = '/ppo/{appSpace}/licenses-issuer';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#organizationPPOGDPRBody
    */
    organizationPPOGDPRBody(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  gdpr?: string
;
}
  >
    {
        let path = '/ppo/{appSpace}/gdpr';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#organizationPPOContactInfo
    */
    organizationPPOContactInfo(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< PPOContactInfo
  >
    {
        let path = '/ppo/{appSpace}/contact-info';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#organizationPPOContactPersons
    */
    organizationPPOContactPersons(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  contactPersons?: Array<{
  _id?: elementary__id
;
  sportnetId?: string
;
  name?: string
;
  surname?: string
;
  titles?: {
  before: string
;
  after: string
;
}
;
  email?: Array<string
>
;
  phone?: Array<string
>
;
  position?: string
;
}
>
;
}
  >
    {
        let path = '/ppo/{appSpace}/contact-persons';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#organizationPPOTheme
    */
    organizationPPOTheme(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< PPOTheme
  >
    {
        let path = '/ppo/{appSpace}/theme';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#organizationPPOUsers
    */
    organizationPPOUsers(
            appSpace: string
,
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
        parameters: {
                        'limit'?: number
,
                        'offset'?: number
,
                        'relatedTo'?: string
,
                        'q'?: string
,
                        'affiliationDateFrom'?: string
,
                        'affiliationDateTo'?: string
,
                        'birthdateFrom'?: string
,
                        'birthdateTo'?: string
,
                        'affiliationCategory'?: "sport_orgs" | "sport_expert_orgs"
,
                        'competenceType'?: string
,
                        'affiliationActive'?: boolean
,
                        'membershipActive'?: boolean
,
                        'bioActive'?: boolean
,
                        'sportSector'?: string
,
                        'ids'?: Array<string
>
,
                        'sorter'?: "_id" | "-_id" | "person" | "-person"
,
        }  = {},
    ): Promise< {
  total?: number
;
  limit?: number
;
  offset?: number
;
  next_offset?: number|null
;
  users?: Array<{
  _id?: string
;
  name?: string
;
  surname?: string
;
  family_name?: string
;
  titles?: {
  before?: string
;
  after?: string
;
}
;
  deleteAfter?: string
;
  photo?: {
  token?: string
;
  public?: boolean
;
  public_url?: string
;
}
;
  membership?: {
  regnr?: string
;
}
;
  sport_orgs?: Array<UserSportOrg
>
;
  sport_expert_orgs?: Array<{
  _id?: string
;
  org_id?: string
;
  org_profile_id?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  sector?: Sector
;
  competence_type?: string
;
  date_from?: string
;
  date_to?: string|null
;
}
>
;
  regnrs?: Array<{
}
>
;
  bio?: Array<{
  _id?: string
;
  org_id?: string
;
  org_name?: string
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  photo?: MediaManagerPhoto
;
  perex?: string
;
  facebook?: string
;
  twitter?: string
;
  instagram?: string
;
  youtube?: string
;
  widgets?: Array<Widget
>
;
}
>
;
  awards?: Array<{
  _id?: elementary__id
;
  org_profile_id?: string
;
  sport?: string
;
  sport_sector?: string
;
  award_type?: string
;
  issue_date?: string
;
  issued_by?: string
;
  created_datetime?: string
;
  org_id?: string
;
}
>
;
  custom?: Array<{
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
>
;
  birthyear?: number
;
  age?: number
;
  sex?: elementary_sex
;
  citizenship?: string
;
}
>
;
}
  >
    {
        let path = '/ppo/{appSpace}/users';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());



              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
                queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);




              queryParameters['offset'] = 0;

            if(typeof parameters['offset'] !== 'undefined'){
                queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);





            if(typeof parameters['relatedTo'] !== 'undefined'){
                queryParameters['relatedTo'] = parameters['relatedTo'];
            }

            queryParameters['relatedTo'] = this.transformParameter(queryParameters['relatedTo']);





            if(typeof parameters['q'] !== 'undefined'){
                queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);





            if(typeof parameters['affiliationDateFrom'] !== 'undefined'){
                queryParameters['affiliationDateFrom'] = parameters['affiliationDateFrom'];
            }

            queryParameters['affiliationDateFrom'] = this.transformParameter(queryParameters['affiliationDateFrom']);





            if(typeof parameters['affiliationDateTo'] !== 'undefined'){
                queryParameters['affiliationDateTo'] = parameters['affiliationDateTo'];
            }

            queryParameters['affiliationDateTo'] = this.transformParameter(queryParameters['affiliationDateTo']);





            if(typeof parameters['birthdateFrom'] !== 'undefined'){
                queryParameters['birthdateFrom'] = parameters['birthdateFrom'];
            }

            queryParameters['birthdateFrom'] = this.transformParameter(queryParameters['birthdateFrom']);





            if(typeof parameters['birthdateTo'] !== 'undefined'){
                queryParameters['birthdateTo'] = parameters['birthdateTo'];
            }

            queryParameters['birthdateTo'] = this.transformParameter(queryParameters['birthdateTo']);





            if(typeof parameters['affiliationCategory'] !== 'undefined'){
                queryParameters['affiliationCategory'] = parameters['affiliationCategory'];
            }

            queryParameters['affiliationCategory'] = this.transformParameter(queryParameters['affiliationCategory']);





            if(typeof parameters['competenceType'] !== 'undefined'){
                queryParameters['competenceType'] = parameters['competenceType'];
            }

            queryParameters['competenceType'] = this.transformParameter(queryParameters['competenceType']);




              queryParameters['affiliationActive'] = true;

            if(typeof parameters['affiliationActive'] !== 'undefined'){
                queryParameters['affiliationActive'] = parameters['affiliationActive'];
            }

            queryParameters['affiliationActive'] = this.transformParameter(queryParameters['affiliationActive']);




              queryParameters['membershipActive'] = false;

            if(typeof parameters['membershipActive'] !== 'undefined'){
                queryParameters['membershipActive'] = parameters['membershipActive'];
            }

            queryParameters['membershipActive'] = this.transformParameter(queryParameters['membershipActive']);




              queryParameters['bioActive'] = false;

            if(typeof parameters['bioActive'] !== 'undefined'){
                queryParameters['bioActive'] = parameters['bioActive'];
            }

            queryParameters['bioActive'] = this.transformParameter(queryParameters['bioActive']);





            if(typeof parameters['sportSector'] !== 'undefined'){
                queryParameters['sportSector'] = parameters['sportSector'];
            }

            queryParameters['sportSector'] = this.transformParameter(queryParameters['sportSector']);





            if(typeof parameters['ids'] !== 'undefined'){
                queryParameters['ids'] = parameters['ids'];
            }

            queryParameters['ids'] = this.transformParameter(queryParameters['ids']);




              queryParameters['sorter'] = "person";

            if(typeof parameters['sorter'] !== 'undefined'){
                queryParameters['sorter'] = parameters['sorter'];
            }

            queryParameters['sorter'] = this.transformParameter(queryParameters['sorter']);





        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#organizationPPOUser
    */
    organizationPPOUser(
            
            appSpace: string
,
            userId: string
,
        parameters: {
                        'withRelatedAffiliations'?: boolean
,
        }  = {},
    ): Promise< {
  _id?: string
;
  name?: string
;
  surname?: string
;
  family_name?: string
;
  titles?: {
  before?: string
;
  after?: string
;
}
;
  deleteAfter?: string
;
  photo?: {
  token?: string
;
  public?: boolean
;
  public_url?: string
;
}
;
  membership?: {
  regnr?: string
;
}
;
  sport_orgs?: Array<UserSportOrg
>
;
  sport_expert_orgs?: Array<{
  _id?: string
;
  org_id?: string
;
  org_profile_id?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  sector?: Sector
;
  competence_type?: string
;
  date_from?: string
;
  date_to?: string|null
;
}
>
;
  regnrs?: Array<{
}
>
;
  bio?: Array<{
  _id?: string
;
  org_id?: string
;
  org_name?: string
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  photo?: MediaManagerPhoto
;
  perex?: string
;
  facebook?: string
;
  twitter?: string
;
  instagram?: string
;
  youtube?: string
;
  widgets?: Array<Widget
>
;
}
>
;
  awards?: Array<{
  _id?: elementary__id
;
  org_profile_id?: string
;
  sport?: string
;
  sport_sector?: string
;
  award_type?: string
;
  issue_date?: string
;
  issued_by?: string
;
  created_datetime?: string
;
  org_id?: string
;
}
>
;
  custom?: Array<{
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
>
;
  birthyear?: number
;
  age?: number
;
  deathdate?: string
;
  sex?: elementary_sex
;
  citizenship?: string
;
  sport_expert_licenses?: Array<{
}
>
;
}
  >
    {
        let path = '/ppo/{appSpace}/users/{userId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

              queryParameters['withRelatedAffiliations'] = false;

            if(typeof parameters['withRelatedAffiliations'] !== 'undefined'){
                queryParameters['withRelatedAffiliations'] = parameters['withRelatedAffiliations'];
            }

            queryParameters['withRelatedAffiliations'] = this.transformParameter(queryParameters['withRelatedAffiliations']);





            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{userId}', userId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Can be used as image src
    * @method
    * @name CoreApi#getFormalImage
    */
    getFormalImage(
            appSpace: string
,
            userId: string
,
            token: string
,
        parameters: {
        }  = {},
    ): Promise< File
  >
    {
        let path = '/ppo/{appSpace}/users/{userId}/formal-photo/{token}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Accept', 'image/png');
        headers.append('Accept', 'image/jpeg');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{userId}', userId.toString());




            path = path.replace('{token}', token.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#ppoOrganizationBankAccounts
    */
    ppoOrganizationBankAccounts(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
>
;
}
  >
    {
        let path = '/ppo/{appSpace}/organization/bank-accounts';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#ppoOrganizationAddresses
    */
    ppoOrganizationAddresses(
            
            appSpace: string
,
        parameters: {
                        'type'?: Array<string
>
,
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  street?: string
;
  number?: string|number
;
  conscription_number?: string|number
;
  city?: string
;
  zip?: string
;
  country?: string
;
  name?: string
;
  surname?: string
;
  company?: string
;
  type?: string
;
  location?: GeoLocation
;
}
>
;
}
  >
    {
        let path = '/ppo/{appSpace}/organization/addresses';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            if(typeof parameters['type'] !== 'undefined'){
                queryParameters['type'] = parameters['type'];
            }

            queryParameters['type'] = this.transformParameter(queryParameters['type']);





            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#organizationInvoiceProfile
    */
    organizationInvoiceProfile(
            orgId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: string
;
  full_name?: string
;
  name?: string
;
  legal_form?: string
;
  business_id?: string
;
  vat_nr?: string|null
;
  vat_foreign_trade_info?: string
;
  vat_alternatives?: Array<string
>
;
  email?: string
;
  www?: string
;
  phone?: string
;
  fax?: string
;
  contact_person?: string
;
  statutory?: string
;
  logo_public_url?: string|null
;
  favicon_public_url?: string|null
;
  invoice_address?: Address
;
  bank_account?: BankAccount
;
}
  >
    {
        let path = '/organizations/{orgId}/invoice-profile';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{orgId}', orgId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#ppoInvoiceProfile
    */
    ppoInvoiceProfile(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: string
;
  full_name?: string
;
  name?: string
;
  legal_form?: string
;
  business_id?: string
;
  vat_nr?: string|null
;
  vat_foreign_trade_info?: string
;
  vat_alternatives?: Array<string
>
;
  email?: string
;
  www?: string
;
  phone?: string
;
  fax?: string
;
  contact_person?: string
;
  statutory?: string
;
  logo_public_url?: string|null
;
  favicon_public_url?: string|null
;
  invoice_address?: Address
;
  bank_account?: BankAccount
;
}
  >
    {
        let path = '/ppo/{appSpace}/invoice-profile';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * You can mix ppo / user ids and get user profiles in one request
    * @method
    * @name CoreApi#organizationMixedPPOUsers
    */
    organizationMixedPPOUsers(
            
        parameters: {
        } ,
                        body:
                                                        {
                              query: Array<{
  sportnetId: string
;
  appSpace: string
;
}
>
;
                            }
,
    ): Promise< {
  total?: number
;
  limit?: number
;
  offset?: number
;
  next_offset?: number|null
;
  users?: Array<{
  _id?: string
;
  name?: string
;
  surname?: string
;
  family_name?: string
;
  titles?: {
  before?: string
;
  after?: string
;
}
;
  deleteAfter?: string
;
  photo?: {
  token?: string
;
  public?: boolean
;
  public_url?: string
;
}
;
  membership?: {
  regnr?: string
;
}
;
  sport_orgs?: Array<UserSportOrg
>
;
  sport_expert_orgs?: Array<{
  _id?: string
;
  org_id?: string
;
  org_profile_id?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  sector?: Sector
;
  competence_type?: string
;
  date_from?: string
;
  date_to?: string|null
;
}
>
;
  regnrs?: Array<{
}
>
;
  bio?: Array<{
  _id?: string
;
  org_id?: string
;
  org_name?: string
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  photo?: MediaManagerPhoto
;
  perex?: string
;
  facebook?: string
;
  twitter?: string
;
  instagram?: string
;
  youtube?: string
;
  widgets?: Array<Widget
>
;
}
>
;
  awards?: Array<{
  _id?: elementary__id
;
  org_profile_id?: string
;
  sport?: string
;
  sport_sector?: string
;
  award_type?: string
;
  issue_date?: string
;
  issued_by?: string
;
  created_datetime?: string
;
  org_id?: string
;
}
>
;
  custom?: Array<{
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
>
;
  birthyear?: number
;
  age?: number
;
  sex?: elementary_sex
;
}
>
;
}
  >
    {
        let path = '/registry/users';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#DSGOrganizationsList
    */
    DSGOrganizationsList(
            
            
            
        parameters: {
                        'dateFrom'?: string
,
                        'dateTo'?: string
,
                        'ids'?: Array<string
>
,
        }  = {},
    ): Promise< {
  organizations?: Array<OrganizationProfile
>
;
}
  >
    {
        let path = '/registry/dajmespolugol/organizations';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            if(typeof parameters['dateFrom'] !== 'undefined'){
                queryParameters['date_from'] = parameters['dateFrom'];
            }

            queryParameters['date_from'] = this.transformParameter(queryParameters['date_from']);





            if(typeof parameters['dateTo'] !== 'undefined'){
                queryParameters['date_to'] = parameters['dateTo'];
            }

            queryParameters['date_to'] = this.transformParameter(queryParameters['date_to']);





            if(typeof parameters['ids'] !== 'undefined'){
                queryParameters['ids'] = parameters['ids'];
            }

            queryParameters['ids'] = this.transformParameter(queryParameters['ids']);





        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#DSGOrganizationDetail
    */
    DSGOrganizationDetail(
            profileId: string
,
        parameters: {
        }  = {},
    ): Promise< OrganizationProfile
  >
    {
        let path = '/registry/dajmespolugol/organizations/{profileId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{profileId}', profileId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#DSGUsersList
    */
    DSGUsersList(
            
            
            
            
            
            
            
        parameters: {
                        'limit'?: number
,
                        'offset'?: number
,
                        'externalProfile'?: string
,
                        'email'?: string
,
                        'ids'?: Array<string
>
,
                        'orgProfileId'?: string
,
                        'sorter'?: "_id" | "-_id" | "created_datetime" | "-created_datetime" | "person" | "-person" | "birthdate" | "-birthdate"
,
        }  = {},
    ): Promise< {
  total?: number
;
  limit?: number
;
  offset?: number
;
  next_offset?: number|null
;
  users?: Array<UserPublicProfile
>
;
}
  >
    {
        let path = '/registry/dajmespolugol/users';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
                queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);




              queryParameters['offset'] = 0;

            if(typeof parameters['offset'] !== 'undefined'){
                queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);





            if(typeof parameters['externalProfile'] !== 'undefined'){
                queryParameters['externalProfile'] = parameters['externalProfile'];
            }

            queryParameters['externalProfile'] = this.transformParameter(queryParameters['externalProfile']);





            if(typeof parameters['email'] !== 'undefined'){
                queryParameters['email'] = parameters['email'];
            }

            queryParameters['email'] = this.transformParameter(queryParameters['email']);





            if(typeof parameters['ids'] !== 'undefined'){
                queryParameters['ids'] = parameters['ids'];
            }

            queryParameters['ids'] = this.transformParameter(queryParameters['ids']);





            if(typeof parameters['orgProfileId'] !== 'undefined'){
                queryParameters['org_profile_id'] = parameters['orgProfileId'];
            }

            queryParameters['org_profile_id'] = this.transformParameter(queryParameters['org_profile_id']);




              queryParameters['sorter'] = "-created_datetime";

            if(typeof parameters['sorter'] !== 'undefined'){
                queryParameters['sorter'] = parameters['sorter'];
            }

            queryParameters['sorter'] = this.transformParameter(queryParameters['sorter']);





        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#sendCourierMessages
    */
    sendCourierMessages(
            
        parameters: {
        }  = {},
                        body:
                                                        {
                              senderPPO: string
;
                              senderSportnetId?: string|null
;
                              recipients: Courier_Recipient_Parameters
;
                              subject: string
;
                              perex: string
;
                              widgets?: Array<{
}
>
;
                            }
,
    ): Promise< {
}
  >
    {
        let path = '/courier';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Endpoint for sending push notifications.
    * @method
    * @name CoreApi#sendPushNotification
    */
    sendPushNotification(
            
        parameters: {
        }  = {},
                        body:
                                                        {
                              appSpace: string
;
                              appId: string
;
                              pushNotificationsAppIds: Array<string
>
;
                              date: string
;
                              payload: {
}
;
                              silent?: boolean
;
                            }
,
    ): Promise< {
  ok: boolean
;
  requestId?: string
;
  notificationDate?: string
;
  created?: string
;
  error?: string
;
}
  >
    {
        let path = '/push-notification';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Vytvorenie faktury pre PPO z externej sluzby
    * @method
    * @name CoreApi#createPPOInvoice
    */
    createPPOInvoice(
            
            ppo: string
,
        parameters: {
        }  = {},
                        body:
                                                        {
                              issuedBy: string
;
                              createdDate: string
;
                              deliveryDate: string
;
                              payDueDate: string
;
                              paymentDate: string
;
                              paymentReference: string
;
                              buyerSportnetId?: string
;
                              buyerPPO?: string
;
                              invoiceNote?: string
;
                              buyer: {
  name: string
;
  contact_person?: string
;
  business_id?: string
;
  vat_nr?: string
;
  invoice_address?: Address
;
}
;
                              items: Array<InvoiceItem_Create
>
;
                            }
,
    ): Promise< {
  invoicenr: string
;
  invoiceId: string
;
}
  >
    {
        let path = '/invoice/{ppo}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{ppo}', ppo.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Storno faktury pre PPO z externej sluzby
    * @method
    * @name CoreApi#stornoPPOInvoice
    */
    stornoPPOInvoice(
            
            ppo: string
,
            invoicenr: string
,
        parameters: {
        }  = {},
                        body:
                                                        {
                              stornoBy: {
  sportnetId: string
;
  displayName: string
;
}
;
                            }
,
    ): Promise< InvoiceItem
  >
    {
        let path = '/invoice/{ppo}/{invoicenr}/storno';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{ppo}', ppo.toString());




            path = path.replace('{invoicenr}', invoicenr.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Vytvorenie polozky faktury pre PPO z externej sluzby
    * @method
    * @name CoreApi#createPPOInvoiceItems
    */
    createPPOInvoiceItems(
            
            ppo: string
,
            buyerPpo: string
,
        parameters: {
        }  = {},
                        body:
                                                        {
                              items?: Array<InvoiceItem_Create
>
;
                            }
,
    ): Promise< {
  invoiceId?: string
;
  invoiceNr?: string
;
}
  >
    {
        let path = '/invoice-items/{ppo}/ppo/{buyerPPO}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{ppo}', ppo.toString());




            path = path.replace('{buyerPPO}', buyerPpo.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Ziskanie PDF faktury pre PPO z externej sluzby
    * @method
    * @name CoreApi#getPPOInvoiceByNr
    */
    getPPOInvoiceByNr(
            ppo: string
,
            invoicenr: string
,
        parameters: {
        }  = {},
    ): Promise< {
  download_id: string
;
  url: string
;
}
  >
    {
        let path = '/invoice/{ppo}/{invoicenr}/pdf';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{ppo}', ppo.toString());




            path = path.replace('{invoicenr}', invoicenr.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmGetCourierTemplates
    */
    crmGetCourierTemplates(
            
            
            appSpace: string
,
        parameters: {
                        'offset'?: number
,
                        'limit'?: number
,
        }  = {},
    ): Promise< {
  total?: number
;
  limit?: number
;
  offset?: number
;
  next_offset?: number|null
;
  templates?: Array<Courier_Template
>
;
}
  >
    {
        let path = '/crm/{appSpace}/courier/templates';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

              queryParameters['offset'] = 0;

            if(typeof parameters['offset'] !== 'undefined'){
                queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);




              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
                queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);





            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmSendMessages
    */
    crmSendMessages(
            
            appSpace: string
,
        parameters: {
        }  = {},
                        body:
                                                        Courier_Template_Create
,
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/courier/templates';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmGetCourierTemplateById
    */
    crmGetCourierTemplateById(
            appSpace: string
,
            courierTemplateId: string
,
        parameters: {
        }  = {},
    ): Promise< Courier_Template
  >
    {
        let path = '/crm/{appSpace}/courier/templates/{courierTemplateId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{courierTemplateId}', courierTemplateId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmGetCourierMessagesByTemplateId
    */
    crmGetCourierMessagesByTemplateId(
            
            
            appSpace: string
,
            courierTemplateId: string
,
        parameters: {
                        'offset'?: number
,
                        'limit'?: number
,
        }  = {},
    ): Promise< {
  total?: number
;
  limit?: number
;
  offset?: number
;
  next_offset?: number|null
;
  messages?: Array<Courier_Message
>
;
}
  >
    {
        let path = '/crm/{appSpace}/courier/templates/{courierTemplateId}/messages';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

              queryParameters['offset'] = 0;

            if(typeof parameters['offset'] !== 'undefined'){
                queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);




              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
                queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);





            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{courierTemplateId}', courierTemplateId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#getPublicAppSpaceGroups
    */
    getPublicAppSpaceGroups(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  groups?: User_Groups_List
;
}
  >
    {
        let path = '/ppo/{appSpace}/user-groups';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmGetAppSpaceGroups
    */
    crmGetAppSpaceGroups(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  groups?: User_Groups_List
;
}
  >
    {
        let path = '/crm/{appSpace}/user-groups';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmCreateAppSpaceGroup
    */
    crmCreateAppSpaceGroup(
            
            appSpace: string
,
        parameters: {
        }  = {},
                        body:
                                                        User_Group_Create
,
    ): Promise< User_Group
  >
    {
        let path = '/crm/{appSpace}/user-groups';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#getUsersByAppSpaceGroup
    */
    getUsersByAppSpaceGroup(
            appSpace: string
,
            
            
            
            
            
        parameters: {
                        'limit'?: number
,
                        'offset'?: number
,
                        'q'?: string
,
                        'userGroupIds'?: Array<string
>
,
                        'sorter'?: "_id" | "-_id" | "created_datetime" | "-created_datetime" | "person" | "-person" | "birthdate" | "-birthdate"
,
        }  = {},
    ): Promise< {
  total?: number
;
  limit?: number
;
  offset?: number
;
  next_offset?: number|null
;
  users?: Array<UserBasicProfileWithGroups
>
;
}
  >
    {
        let path = '/crm/{appSpace}/user-groups/users';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());



              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
                queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);




              queryParameters['offset'] = 0;

            if(typeof parameters['offset'] !== 'undefined'){
                queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);





            if(typeof parameters['q'] !== 'undefined'){
                queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);





            if(typeof parameters['userGroupIds'] !== 'undefined'){
                queryParameters['userGroupIds'] = parameters['userGroupIds'];
            }

            queryParameters['userGroupIds'] = this.transformParameter(queryParameters['userGroupIds']);





            if(typeof parameters['sorter'] !== 'undefined'){
                queryParameters['sorter'] = parameters['sorter'];
            }

            queryParameters['sorter'] = this.transformParameter(queryParameters['sorter']);





        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmGetAppSpaceGroup
    */
    crmGetAppSpaceGroup(
            appSpace: string
,
            userGroupId: string
,
        parameters: {
        }  = {},
    ): Promise< User_Group
  >
    {
        let path = '/crm/{appSpace}/user-groups/detail/{userGroupId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{userGroupId}', userGroupId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUpdateAppSpaceGroup
    */
    crmUpdateAppSpaceGroup(
            
            appSpace: string
,
            userGroupId: string
,
        parameters: {
        }  = {},
                        body:
                                                        User_Group_Modify
,
    ): Promise< User_Group
  >
    {
        let path = '/crm/{appSpace}/user-groups/detail/{userGroupId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{userGroupId}', userGroupId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#createInvitationRequestToGroups
    */
    createInvitationRequestToGroups(
            
            appSpace: string
,
        parameters: {
        }  = {},
                        body:
                                                        {
                              email: string
;
                              groupIds: Array<string
>
;
                            }
,
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/user-groups/invitation';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUpdateProfileLogo
    */
    crmUpdateProfileLogo(
            
            appSpace: string
,
        parameters: {
        } ,
            form: {
                            'upload': File
,
            }
                ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/settings/logo';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            form,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Metoda pre vymazanie loga PPO
    * @method
    * @name CoreApi#crmDeleteProfileLogo
    */
    crmDeleteProfileLogo(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/settings/logo';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUpdateProfileFavicon
    */
    crmUpdateProfileFavicon(
            
            appSpace: string
,
        parameters: {
        } ,
            form: {
                            'upload': File
,
            }
                ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/settings/favicon';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            form,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Metoda pre vymazanie favicon-u PPO
    * @method
    * @name CoreApi#crmDeleteProfileFavicon
    */
    crmDeleteProfileFavicon(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/settings/favicon';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Metoda pre ziskanie suhlasu s GDPR
    * @method
    * @name CoreApi#crmGetGDPRBody
    */
    crmGetGDPRBody(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  gdpr?: string
;
}
  >
    {
        let path = '/crm/{appSpace}/settings/gdpr';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Metoda pre update suhlasu s GDPR
    * @method
    * @name CoreApi#crmUpdateGDPRBody
    */
    crmUpdateGDPRBody(
            
            appSpace: string
,
        parameters: {
        }  = {},
                        body:
                                                        {
                              gdpr?: string
;
                            }
,
    ): Promise< {
  gdpr?: string
;
}
  >
    {
        let path = '/crm/{appSpace}/settings/gdpr';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Update PPO contact info
    * @method
    * @name CoreApi#crmUpdateContactInfo
    */
    crmUpdateContactInfo(
            
            appSpace: string
,
        parameters: {
        }  = {},
                        body:
                                                        PPOContactInfo
,
    ): Promise< PPOContactInfo
  >
    {
        let path = '/crm/{appSpace}/settings/contact-info';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Update PPO theme
    * @method
    * @name CoreApi#crmUpdateTheme
    */
    crmUpdateTheme(
            
            appSpace: string
,
        parameters: {
        }  = {},
                        body:
                                                        PPOTheme
,
    ): Promise< PPOTheme
  >
    {
        let path = '/crm/{appSpace}/settings/theme';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Metoda pre ziskanie sekvencii appspace
    * @method
    * @name CoreApi#crmGetSequence
    */
    crmGetSequence(
            appSpace: string
,
            sequenceId: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/sequences/{sequenceId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{sequenceId}', sequenceId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Metoda pre upravu sekvencie
    * @method
    * @name CoreApi#crmUpdateSequence
    */
    crmUpdateSequence(
            
            appSpace: string
,
            sequenceId: string
,
        parameters: {
        } ,
                        body:
                                                        {
                              pattern: string
;
                              value: number
;
                            }
,
    ): Promise< Sequence
  >
    {
        let path = '/crm/{appSpace}/sequences/{sequenceId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{sequenceId}', sequenceId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Metoda pre ziskanie zoznamu faktur podla appspace
    * @method
    * @name CoreApi#getInvoicesByAppspace
    */
    getInvoicesByAppspace(
            
            
            
            
            
            
            appSpace: string
,
        parameters: {
                        'limit'?: number
,
                        'offset'?: number
,
                        'buyer'?: string
,
                        'q'?: string
,
                        'deliveryDateFrom'?: string
,
                        'deliveryDateTo'?: string
,
        }  = {},
    ): Promise< {
  invoices?: Array<InvoiceItem
>
;
}
  >
    {
        let path = '/crm/{appSpace}/invoices';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
                queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);




              queryParameters['offset'] = 0;

            if(typeof parameters['offset'] !== 'undefined'){
                queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);





            if(typeof parameters['buyer'] !== 'undefined'){
                queryParameters['buyer'] = parameters['buyer'];
            }

            queryParameters['buyer'] = this.transformParameter(queryParameters['buyer']);





            if(typeof parameters['q'] !== 'undefined'){
                queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);





            if(typeof parameters['deliveryDateFrom'] !== 'undefined'){
                queryParameters['deliveryDateFrom'] = parameters['deliveryDateFrom'];
            }

            queryParameters['deliveryDateFrom'] = this.transformParameter(queryParameters['deliveryDateFrom']);





            if(typeof parameters['deliveryDateTo'] !== 'undefined'){
                queryParameters['deliveryDateTo'] = parameters['deliveryDateTo'];
            }

            queryParameters['deliveryDateTo'] = this.transformParameter(queryParameters['deliveryDateTo']);





            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Metoda pre ziskanie zoznamu otvorenych faktur
    * @method
    * @name CoreApi#getOpenedInvoiceItemsByAppspace
    */
    getOpenedInvoiceItemsByAppspace(
            
            
            appSpace: string
,
        parameters: {
                        'buyer'?: string
,
                        'q'?: string
,
        }  = {},
    ): Promise< {
  invoices?: Array<InvoiceItem
>
;
}
  >
    {
        let path = '/crm/{appSpace}/invoice-items';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            if(typeof parameters['buyer'] !== 'undefined'){
                queryParameters['buyer'] = parameters['buyer'];
            }

            queryParameters['buyer'] = this.transformParameter(queryParameters['buyer']);





            if(typeof parameters['q'] !== 'undefined'){
                queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);





            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Metoda pre uzavretie poloziek zbernej FA - vytvorenie finalnych FA
    * @method
    * @name CoreApi#commitOpenedInvoiceItems
    */
    commitOpenedInvoiceItems(
            
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        {
                              invoiceItemIds: Array<string
>
;
                              createdDate: string
;
                              payDueDate: string
;
                              deliveryDate: string
;
                            }
,
    ): Promise< {
  invoices?: Array<InvoiceItem
>
;
}
  >
    {
        let path = '/crm/{appSpace}/invoice-items';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Metoda pre ziskanie download ID pdf faktury
    * @method
    * @name CoreApi#getInvoicePdfByNr
    */
    getInvoicePdfByNr(
            appSpace: string
,
            invoiceNr: string
,
        parameters: {
        }  = {},
    ): Promise< {
  download_id: string
;
}
  >
    {
        let path = '/crm/{appSpace}/invoices/{invoiceNr}/pdf';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{invoiceNr}', invoiceNr.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Metoda pre stornovanie faktury
    * @method
    * @name CoreApi#stornoInvoice
    */
    stornoInvoice(
            appSpace: string
,
            invoiceNr: string
,
        parameters: {
        }  = {},
    ): Promise< InvoiceItem
  >
    {
        let path = '/crm/{appSpace}/invoices/{invoiceNr}/storno';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{invoiceNr}', invoiceNr.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Metoda pre ziskanie download ID pdf faktury
    * @method
    * @name CoreApi#getInvoicesPohodaXmlByIds
    */
    getInvoicesPohodaXmlByIds(
            appSpace: string
,
            
        parameters: {
                        'invoiceIds': Array<string
>
,
        } ,
    ): Promise< {
  download_id: string
;
}
  >
    {
        let path = '/crm/{appSpace}/invoices-export/pohoda';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            if(typeof parameters['invoiceIds'] !== 'undefined'){
                queryParameters['invoiceIds'] = parameters['invoiceIds'];
            }

            queryParameters['invoiceIds'] = this.transformParameter(queryParameters['invoiceIds']);



                if(typeof parameters['invoiceIds'] === 'undefined') {
                    throw new Error('Missing required parameter: invoiceIds');
                }


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Public metoda na ziskanie cennika sluzieb spolocnosti
    * @method
    * @name CoreApi#crmPPOPricelist
    */
    crmPPOPricelist(
            
            
            appSpace: string
,
        parameters: {
                        'applicationId'?: string
,
                        'type'?: string
,
        }  = {},
    ): Promise< {
  pricelist?: Array<PPOPricelistItem
>
;
}
  >
    {
        let path = '/crm/{appSpace}/pricelist';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            if(typeof parameters['applicationId'] !== 'undefined'){
                queryParameters['applicationId'] = parameters['applicationId'];
            }

            queryParameters['applicationId'] = this.transformParameter(queryParameters['applicationId']);





            if(typeof parameters['type'] !== 'undefined'){
                queryParameters['type'] = parameters['type'];
            }

            queryParameters['type'] = this.transformParameter(queryParameters['type']);





            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Create new pricelist item
    * @method
    * @name CoreApi#crmPPOPricelistItemCreate
    */
    crmPPOPricelistItemCreate(
            
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        PPOPricelistItem_CreateOrUpdate
,
    ): Promise< PPOPricelistItem
  >
    {
        let path = '/crm/{appSpace}/pricelist';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Public metoda na ziskanie detailu ceny
    * @method
    * @name CoreApi#crmPPOPricelistItem
    */
    crmPPOPricelistItem(
            appSpace: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< PPOPricelistItem
  >
    {
        let path = '/crm/{appSpace}/pricelist/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Update pricelist item
    * @method
    * @name CoreApi#crmPPOPricelistItemUpdate
    */
    crmPPOPricelistItemUpdate(
            
            appSpace: string
,
            itemId: string
,
        parameters: {
        } ,
                        body:
                                                        PPOPricelistItem_CreateOrUpdate
,
    ): Promise< PPOPricelistItem
  >
    {
        let path = '/crm/{appSpace}/pricelist/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Delete pricelist item
    * @method
    * @name CoreApi#crmPPOPricelistItemDelete
    */
    crmPPOPricelistItemDelete(
            appSpace: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/pricelist/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmOrganizationFullProfile
    */
    crmOrganizationFullProfile(
            appSpace: string
,
            orgId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: string
;
  full_name?: string
;
  name?: string
;
  legal_form?: string
;
  business_id?: string
;
  vat_nr?: string|null
;
  vat_foreign_trade_info?: string
;
  vat_alternatives?: Array<string
>
;
  email?: string
;
  www?: string
;
  phone?: string
;
  fax?: string
;
  contact_person?: string
;
  statutory?: string
;
  logo_public_url?: string|null
;
  favicon_public_url?: string|null
;
  bank_accounts?: Array<{
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
>
;
  addresses?: Array<{
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  street?: string
;
  number?: string|number
;
  conscription_number?: string|number
;
  city?: string
;
  zip?: string
;
  country?: string
;
  name?: string
;
  surname?: string
;
  company?: string
;
  type?: string
;
  location?: GeoLocation
;
}
>
;
  custom?: Array<{
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/organizations/{orgId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{orgId}', orgId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmListRelatedPPOs
    */
    crmListRelatedPPOs(
            
            
            
            
            appSpace: string
,
        parameters: {
                        'ppoType'?: Array<string
>
,
                        'sports'?: Array<string
>
,
                        'with'?: Array<"shortNames"
>
,
                        'output'?: "json" | "xlsx" | "xlsx-json"
,
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: string
;
  name?: string
;
  country?: string
;
  type?: string
;
  sectors?: Array<{
  category?: "sport"
;
  itemId?: string
;
  sectorId?: string
;
  matricityPPO?: string
;
  _id?: string
;
}
>
;
  sport?: string
;
  status?: string
;
  parent_relations?: Array<OrganizationProfile2
>
;
  matricity_relations?: Array<OrganizationProfile2
>
;
  organization_id?: string
;
  organization_name?: string
;
  logo_public_url?: string|null
;
  favicon_public_url?: string|null
;
  shortNames?: Array<OrganizationShortName
>
;
  relation?: OrganizationProfileRelation
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/related-ppos';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            if(typeof parameters['ppoType'] !== 'undefined'){
                queryParameters['ppoType'] = parameters['ppoType'];
            }

            queryParameters['ppoType'] = this.transformParameter(queryParameters['ppoType']);





            if(typeof parameters['sports'] !== 'undefined'){
                queryParameters['sports'] = parameters['sports'];
            }

            queryParameters['sports'] = this.transformParameter(queryParameters['sports']);





            if(typeof parameters['with'] !== 'undefined'){
                queryParameters['with'] = parameters['with'];
            }

            queryParameters['with'] = this.transformParameter(queryParameters['with']);




              queryParameters['output'] = "json";

            if(typeof parameters['output'] !== 'undefined'){
                queryParameters['output'] = parameters['output'];
            }

            queryParameters['output'] = this.transformParameter(queryParameters['output']);





            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Prislusnosti k inym PPO
    * @method
    * @name CoreApi#crmUpdateRelation
    */
    crmUpdateRelation(
            
            appSpace: string
,
            relationId: string
,
        parameters: {
        } ,
                        body:
                                                        OrganizationProfileRelationUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  related_ppo?: string
;
  date_from?: string
;
  date_to?: string|null
;
  member_until?: string|null
;
  status?: elementary_itemStatus
;
  related_ppo_name?: string
;
  related_ppo_organization_name?: string
;
}
  >
    {
        let path = '/crm/{appSpace}/related-ppos/{relationId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{relationId}', relationId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmPPOMembership
    */
    crmPPOMembership(
            appSpace: string
,
            ppo: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  valid_from?: string
;
  valid_to?: string
;
  status?: PPOMembershipStatus
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/ppos/{ppo}/membership';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{ppo}', ppo.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmPPOMembershipCreate
    */
    crmPPOMembershipCreate(
            
            appSpace: string
,
            ppo: string
,
        parameters: {
        } ,
                        body:
                                                        PPOMembershipCreate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  valid_from?: string
;
  valid_to?: string
;
  status?: PPOMembershipStatus
;
}
  >
    {
        let path = '/crm/{appSpace}/ppos/{ppo}/membership';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{ppo}', ppo.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmPPOMembershipGet
    */
    crmPPOMembershipGet(
            ppo: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  valid_from?: string
;
  valid_to?: string
;
  status?: PPOMembershipStatus
;
}
  >
    {
        let path = '/crm/{appSpace}/ppos/{ppo}/membership/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{ppo}', ppo.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmPPOMembershipUpdate
    */
    crmPPOMembershipUpdate(
            
            ppo: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        PPOMembershipUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  valid_from?: string
;
  valid_to?: string
;
  status?: PPOMembershipStatus
;
}
  >
    {
        let path = '/crm/{appSpace}/ppos/{ppo}/membership/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{ppo}', ppo.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmPPOMembershipDelete
    */
    crmPPOMembershipDelete(
            ppo: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/ppos/{ppo}/membership/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{ppo}', ppo.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmPPOSectors
    */
    crmPPOSectors(
            appSpace: string
,
            ppo: string
,
        parameters: {
        }  = {},
    ): Promise< {
  sectors?: Array<{
  _id: string
;
  category: "sport"
;
  itemId: string
;
  sectorId: string
;
  matricityPPO?: string
;
  matricityPPOName?: string
;
  matricityPPOOrgName?: string
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/ppos/{ppo}/sectors';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{ppo}', ppo.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmPPOSectorsUpdate
    */
    crmPPOSectorsUpdate(
            
            appSpace: string
,
            ppo: string
,
        parameters: {
        } ,
                        body:
                                                        {
                              sectors: Array<Sector
>
;
                            }
,
    ): Promise< {
  sectors?: Array<Sector
>
;
}
  >
    {
        let path = '/crm/{appSpace}/ppos/{ppo}/sectors';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{ppo}', ppo.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmPPOShortnames
    */
    crmPPOShortnames(
            appSpace: string
,
            ppo: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  shortname?: string
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/ppos/{ppo}/shortnames';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{ppo}', ppo.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmPPOShortnameCreate
    */
    crmPPOShortnameCreate(
            
            appSpace: string
,
            ppo: string
,
        parameters: {
        } ,
                        body:
                                                        PPOShortnameCreate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  shortname?: string
;
}
  >
    {
        let path = '/crm/{appSpace}/ppos/{ppo}/shortnames';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{ppo}', ppo.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmPPOShortnameUpdate
    */
    crmPPOShortnameUpdate(
            
            ppo: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        PPOShortnameUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  shortname?: string
;
}
  >
    {
        let path = '/crm/{appSpace}/ppos/{ppo}/shortnames/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{ppo}', ppo.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmPPOShortnameDelete
    */
    crmPPOShortnameDelete(
            ppo: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/ppos/{ppo}/shortnames/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{ppo}', ppo.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmPPOContactPersons
    */
    crmPPOContactPersons(
            appSpace: string
,
            ppo: string
,
        parameters: {
        }  = {},
    ): Promise< {
  contactPersons?: Array<{
  _id?: elementary__id
;
  sportnetId?: string
;
  name?: string
;
  surname?: string
;
  titles?: {
  before: string
;
  after: string
;
}
;
  email?: Array<string
>
;
  phone?: Array<string
>
;
  position?: string
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/ppos/{ppo}/contact-persons';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{ppo}', ppo.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmApps
    */
    crmApps(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  apps?: Array<{
  _id?: string
;
  name?: string
;
  description?: string|null
;
  url?: string|null
;
  marketingUrl?: string|null
;
  appSpaceUrlPattern?: string|null
;
  action_buttons?: Array<{
  label: string
;
  urlPattern: string
;
  roles?: Array<string
>
;
}
>
;
  logo?: string|null
;
  roles?: Array<AppRole
>
;
  flags?: Array<string
>
;
  child_apps?: Array<AppPublic_
>
;
  validTo?: string
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/apps';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmAppPayment
    */
    crmAppPayment(
            
            
            
            appSpace: string
,
            itemId: string
,
        parameters: {
                        'callbackUrl': string
,
                        'redirect'?: boolean
,
                        'test'?: boolean
,
        } ,
    ): Promise< PaygateInfo
  >
    {
        let path = '/crm/{appSpace}/app/{itemId}/payment';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            if(typeof parameters['callbackUrl'] !== 'undefined'){
                queryParameters['callbackUrl'] = parameters['callbackUrl'];
            }

            queryParameters['callbackUrl'] = this.transformParameter(queryParameters['callbackUrl']);



                if(typeof parameters['callbackUrl'] === 'undefined') {
                    throw new Error('Missing required parameter: callbackUrl');
                }

              queryParameters['redirect'] = false;

            if(typeof parameters['redirect'] !== 'undefined'){
                queryParameters['redirect'] = parameters['redirect'];
            }

            queryParameters['redirect'] = this.transformParameter(queryParameters['redirect']);




              queryParameters['test'] = false;

            if(typeof parameters['test'] !== 'undefined'){
                queryParameters['test'] = parameters['test'];
            }

            queryParameters['test'] = this.transformParameter(queryParameters['test']);





            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmAppPaymentResult
    */
    crmAppPaymentResult(
            
            appSpace: string
,
            orderId: number
,
        parameters: {
                        'test'?: boolean
,
        }  = {},
    ): Promise< AppSpace
  >
    {
        let path = '/crm/{appSpace}/app/{orderId}/payment/result';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

              queryParameters['test'] = false;

            if(typeof parameters['test'] !== 'undefined'){
                queryParameters['test'] = parameters['test'];
            }

            queryParameters['test'] = this.transformParameter(queryParameters['test']);





            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{orderId}', orderId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationRequestChange
    */
    crmMyOrganizationRequestChange(
            
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        OrganizationChangeRequest
,
    ): Promise< OrganizationChangeRequest
  >
    {
        let path = '/crm/{appSpace}/myorganization/request-change';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganization
    */
    crmMyOrganization(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< Organization
  >
    {
        let path = '/crm/{appSpace}/myorganization/profile';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationUpdate
    */
    crmMyOrganizationUpdate(
            
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        OrganizationUpdate
,
    ): Promise< Organization
  >
    {
        let path = '/crm/{appSpace}/myorganization/profile';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationMembership
    */
    crmMyOrganizationMembership(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  valid_from?: string
;
  valid_to?: string
;
  status?: PPOMembershipStatus
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/membership';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationContactPersons
    */
    crmMyOrganizationContactPersons(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  contactPersons?: Array<{
  _id?: elementary__id
;
  sportnetId?: string
;
  name?: string
;
  surname?: string
;
  titles?: {
  before: string
;
  after: string
;
}
;
  email?: Array<string
>
;
  phone?: Array<string
>
;
  position?: string
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/contact-persons';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationContactPersonCreate
    */
    crmMyOrganizationContactPersonCreate(
            
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        PPOContactPersonCreate
,
    ): Promise< {
  _id?: elementary__id
;
  sportnetId?: string
;
  name?: string
;
  surname?: string
;
  titles?: {
  before: string
;
  after: string
;
}
;
  email?: Array<string
>
;
  phone?: Array<string
>
;
  position?: string
;
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/contact-persons';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationContactPerson
    */
    crmMyOrganizationContactPerson(
            appSpace: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  contactPersons?: Array<{
  _id?: elementary__id
;
  sportnetId?: string
;
  name?: string
;
  surname?: string
;
  titles?: {
  before: string
;
  after: string
;
}
;
  email?: Array<string
>
;
  phone?: Array<string
>
;
  position?: string
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/contact-persons/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationContactPersonUpdate
    */
    crmMyOrganizationContactPersonUpdate(
            
            appSpace: string
,
            itemId: string
,
        parameters: {
        } ,
                        body:
                                                        PPOContactPersonUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  sportnetId?: string
;
  name?: string
;
  surname?: string
;
  titles?: {
  before: string
;
  after: string
;
}
;
  email?: Array<string
>
;
  phone?: Array<string
>
;
  position?: string
;
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/contact-persons/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationContactPersonDelete
    */
    crmMyOrganizationContactPersonDelete(
            appSpace: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/contact-persons/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationMembershipProlongInfo
    */
    crmMyOrganizationMembershipProlongInfo(
            appSpace: string
,
            membershipToPpo: string
,
        parameters: {
        }  = {},
    ): Promise< {
  pricelistItem?: PPOPricelistItem
;
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/membership-prolong/{membershipToPPO}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{membershipToPPO}', membershipToPpo.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationMembershipProlong
    */
    crmMyOrganizationMembershipProlong(
            appSpace: string
,
            membershipToPpo: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/membership-prolong/{membershipToPPO}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{membershipToPPO}', membershipToPpo.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationLogoUpload
    */
    crmMyOrganizationLogoUpload(
            
            appSpace: string
,
        parameters: {
        } ,
            form: {
                            'upload': File
,
            }
                ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/logo';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            form,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Metoda pre vymazanie loga organizacie
    * @method
    * @name CoreApi#crmMyOrganizationLogoDelete
    */
    crmMyOrganizationLogoDelete(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/logo';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationFaviconUpload
    */
    crmMyOrganizationFaviconUpload(
            
            appSpace: string
,
        parameters: {
        } ,
            form: {
                            'upload': File
,
            }
                ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/favicon';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            form,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Metoda pre vymazanie favicon-u organizacie
    * @method
    * @name CoreApi#crmMyOrganizationFaviconDelete
    */
    crmMyOrganizationFaviconDelete(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/favicon';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationAddresses
    */
    crmMyOrganizationAddresses(
            
            appSpace: string
,
        parameters: {
                        'type'?: Array<string
>
,
        }  = {},
    ): Promise< {
  _dataNotApproved?: boolean
;
  items?: Array<{
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  street?: string
;
  number?: string|number
;
  conscription_number?: string|number
;
  city?: string
;
  zip?: string
;
  country?: string
;
  name?: string
;
  surname?: string
;
  company?: string
;
  type?: string
;
  location?: GeoLocation
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/addresses';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            if(typeof parameters['type'] !== 'undefined'){
                queryParameters['type'] = parameters['type'];
            }

            queryParameters['type'] = this.transformParameter(queryParameters['type']);





            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationAddressesCreate
    */
    crmMyOrganizationAddressesCreate(
            
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        AddressCreateOrUpdate
,
    ): Promise< Address
  >
    {
        let path = '/crm/{appSpace}/myorganization/addresses';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationAddressesGet
    */
    crmMyOrganizationAddressesGet(
            appSpace: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  street?: string
;
  number?: string|number
;
  conscription_number?: string|number
;
  city?: string
;
  zip?: string
;
  country?: string
;
  name?: string
;
  surname?: string
;
  company?: string
;
  type?: string
;
  location?: GeoLocation
;
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/addresses/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationAddressesUpdate
    */
    crmMyOrganizationAddressesUpdate(
            
            appSpace: string
,
            itemId: string
,
        parameters: {
        } ,
                        body:
                                                        AddressCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  street?: string
;
  number?: string|number
;
  conscription_number?: string|number
;
  city?: string
;
  zip?: string
;
  country?: string
;
  name?: string
;
  surname?: string
;
  company?: string
;
  type?: string
;
  location?: GeoLocation
;
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/addresses/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationBankAccounts
    */
    crmMyOrganizationBankAccounts(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _dataNotApproved?: boolean
;
  items?: Array<{
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/bank-accounts';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationBankAccountsCreate
    */
    crmMyOrganizationBankAccountsCreate(
            
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        BankAccountCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/bank-accounts';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationBankAccountsGet
    */
    crmMyOrganizationBankAccountsGet(
            appSpace: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/bank-accounts/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationBankAccountsUpdate
    */
    crmMyOrganizationBankAccountsUpdate(
            
            appSpace: string
,
            itemId: string
,
        parameters: {
        } ,
                        body:
                                                        BankAccountCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/bank-accounts/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationCustomFields
    */
    crmMyOrganizationCustomFields(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _dataNotApproved?: boolean
;
  items?: Array<{
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/custom';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationCustomFieldsCreate
    */
    crmMyOrganizationCustomFieldsCreate(
            
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        CustomFieldCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/custom';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationCustomFieldsGet
    */
    crmMyOrganizationCustomFieldsGet(
            appSpace: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/custom/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmMyOrganizationCustomFieldsUpdate
    */
    crmMyOrganizationCustomFieldsUpdate(
            
            appSpace: string
,
            itemId: string
,
        parameters: {
        } ,
                        body:
                                                        CustomFieldCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
  >
    {
        let path = '/crm/{appSpace}/myorganization/custom/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmGetUserAcl
    */
    crmGetUserAcl(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/acl';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmGetAuthUser
    */
    crmGetAuthUser(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  user?: UserPublicProfile
;
  role?: AppSpaceUser
;
}
  >
    {
        let path = '/crm/{appSpace}/user';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmGetUsers
    */
    crmGetUsers(
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            
            appSpace: string
,
        parameters: {
                        'limit'?: number
,
                        'offset'?: number
,
                        'q'?: string
,
                        'autocomplete'?: string
,
                        'email'?: string
,
                        'ids'?: Array<string
>
,
                        'sorter'?: "_id" | "-_id" | "created_datetime" | "-created_datetime" | "person" | "-person" | "birthdate" | "-birthdate"
,
                        'athletType'?: Array<string
>
,
                        'affiliationActive'?: boolean
,
                        'sportExpertType'?: Array<string
>
,
                        'licenseType'?: Array<string
>
,
                        'birthdateFrom'?: string
,
                        'birthdateTo'?: string
,
                        'sex'?: "M" | "F"
,
                        'isAthlete'?: boolean
,
                        'isSportExpert'?: boolean
,
                        'relatedTo'?: string
,
                        'sportSector'?: string
,
                        'membershipValidTo'?: string
,
                        'membershipToPpo'?: string
,
                        'membershipValidToIsInvalid'?: boolean
,
                        'with'?: Array<"regnrs" | "membership" | "addresses" | "sport_orgs" | "sport_expert_orgs" | "medical_examinations" | "custom"
>
,
                        'output'?: "json" | "xlsx" | "xlsx-json"
,
        }  = {},
    ): Promise< {
  total?: number
;
  limit?: number
;
  offset?: number
;
  next_offset?: number|null
;
  users?: Array<UserProfile
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
                queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);




              queryParameters['offset'] = 0;

            if(typeof parameters['offset'] !== 'undefined'){
                queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);





            if(typeof parameters['q'] !== 'undefined'){
                queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);





            if(typeof parameters['autocomplete'] !== 'undefined'){
                queryParameters['autocomplete'] = parameters['autocomplete'];
            }

            queryParameters['autocomplete'] = this.transformParameter(queryParameters['autocomplete']);





            if(typeof parameters['email'] !== 'undefined'){
                queryParameters['email'] = parameters['email'];
            }

            queryParameters['email'] = this.transformParameter(queryParameters['email']);





            if(typeof parameters['ids'] !== 'undefined'){
                queryParameters['ids'] = parameters['ids'];
            }

            queryParameters['ids'] = this.transformParameter(queryParameters['ids']);




              queryParameters['sorter'] = "-created_datetime";

            if(typeof parameters['sorter'] !== 'undefined'){
                queryParameters['sorter'] = parameters['sorter'];
            }

            queryParameters['sorter'] = this.transformParameter(queryParameters['sorter']);





            if(typeof parameters['athletType'] !== 'undefined'){
                queryParameters['athlet_type'] = parameters['athletType'];
            }

            queryParameters['athlet_type'] = this.transformParameter(queryParameters['athlet_type']);





            if(typeof parameters['affiliationActive'] !== 'undefined'){
                queryParameters['affiliation_active'] = parameters['affiliationActive'];
            }

            queryParameters['affiliation_active'] = this.transformParameter(queryParameters['affiliation_active']);





            if(typeof parameters['sportExpertType'] !== 'undefined'){
                queryParameters['sport_expert_type'] = parameters['sportExpertType'];
            }

            queryParameters['sport_expert_type'] = this.transformParameter(queryParameters['sport_expert_type']);





            if(typeof parameters['licenseType'] !== 'undefined'){
                queryParameters['license_type'] = parameters['licenseType'];
            }

            queryParameters['license_type'] = this.transformParameter(queryParameters['license_type']);





            if(typeof parameters['birthdateFrom'] !== 'undefined'){
                queryParameters['birthdate_from'] = parameters['birthdateFrom'];
            }

            queryParameters['birthdate_from'] = this.transformParameter(queryParameters['birthdate_from']);





            if(typeof parameters['birthdateTo'] !== 'undefined'){
                queryParameters['birthdate_to'] = parameters['birthdateTo'];
            }

            queryParameters['birthdate_to'] = this.transformParameter(queryParameters['birthdate_to']);





            if(typeof parameters['sex'] !== 'undefined'){
                queryParameters['sex'] = parameters['sex'];
            }

            queryParameters['sex'] = this.transformParameter(queryParameters['sex']);





            if(typeof parameters['isAthlete'] !== 'undefined'){
                queryParameters['isAthlete'] = parameters['isAthlete'];
            }

            queryParameters['isAthlete'] = this.transformParameter(queryParameters['isAthlete']);





            if(typeof parameters['isSportExpert'] !== 'undefined'){
                queryParameters['isSportExpert'] = parameters['isSportExpert'];
            }

            queryParameters['isSportExpert'] = this.transformParameter(queryParameters['isSportExpert']);





            if(typeof parameters['relatedTo'] !== 'undefined'){
                queryParameters['relatedTo'] = parameters['relatedTo'];
            }

            queryParameters['relatedTo'] = this.transformParameter(queryParameters['relatedTo']);





            if(typeof parameters['sportSector'] !== 'undefined'){
                queryParameters['sportSector'] = parameters['sportSector'];
            }

            queryParameters['sportSector'] = this.transformParameter(queryParameters['sportSector']);





            if(typeof parameters['membershipValidTo'] !== 'undefined'){
                queryParameters['membershipValidTo'] = parameters['membershipValidTo'];
            }

            queryParameters['membershipValidTo'] = this.transformParameter(queryParameters['membershipValidTo']);





            if(typeof parameters['membershipToPpo'] !== 'undefined'){
                queryParameters['membershipToPPO'] = parameters['membershipToPpo'];
            }

            queryParameters['membershipToPPO'] = this.transformParameter(queryParameters['membershipToPPO']);





            if(typeof parameters['membershipValidToIsInvalid'] !== 'undefined'){
                queryParameters['membershipValidToIsInvalid'] = parameters['membershipValidToIsInvalid'];
            }

            queryParameters['membershipValidToIsInvalid'] = this.transformParameter(queryParameters['membershipValidToIsInvalid']);





            if(typeof parameters['with'] !== 'undefined'){
                queryParameters['with'] = parameters['with'];
            }

            queryParameters['with'] = this.transformParameter(queryParameters['with']);




              queryParameters['output'] = "json";

            if(typeof parameters['output'] !== 'undefined'){
                queryParameters['output'] = parameters['output'];
            }

            queryParameters['output'] = this.transformParameter(queryParameters['output']);





            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmCreateUser
    */
    crmCreateUser(
            
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        UserCreate_CRM
,
    ): Promise< UserProfile
  >
    {
        let path = '/crm/{appSpace}/users';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmGetUser
    */
    crmGetUser(
            userId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: string
;
  name?: string
;
  surname?: string
;
  family_name?: string
;
  titles?: {
  before?: string
;
  after?: string
;
}
;
  deleteAfter?: string
;
  email?: string
;
  phone?: string
;
  sex?: elementary_sex
;
  birthdate?: string|null
;
  birthplace?: string
;
  birthcountry?: string
;
  deathdate?: string|null
;
  shoesize?: string
;
  dresssize?: string
;
  nationality?: string
;
  citizenship?: string
;
  membership_status?: string
;
  import_id?: string
;
  external_id?: string
;
  created_datetime?: string
;
  email_verified?: string|null
;
  username?: string
;
  photo?: object|null
;
  idnr?: string|null
;
  ppoRelation?: UserPPORelation
;
  formalPhoto?: UserFormalPhoto
;
  _createdByAppId?: string
;
  _createdBySportnetId?: string
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#getUsersGroups
    */
    getUsersGroups(
            userId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  groups?: Array<{
  ppo?: string
;
  group?: User_Group
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/user-groups';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmSetUserGroups
    */
    crmSetUserGroups(
            
            userId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
                        body:
                                                        {
                              groupIds: Array<string
>
;
                            }
,
    ): Promise< UserBasicProfileWithGroups
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/user-groups';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmGetUserProfile
    */
    crmGetUserProfile(
            userId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< UserProfile
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/profile';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUpdateUserProfile
    */
    crmUpdateUserProfile(
            
            userId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        UserProfileUpdate_Registrar
,
    ): Promise< UserProfile
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/profile';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserPhotoUpload
    */
    crmUserPhotoUpload(
            
            
            userId: string
,
            appSpace: string
,
        parameters: {
        } ,
            form: {
                            'upload': File
,
                            'public'?: boolean
,
            }
                ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/photo';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');










            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            form,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserPhotoDelete
    */
    crmUserPhotoDelete(
            userId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/photo';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmEndUserRelation
    */
    crmEndUserRelation(
            userId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/endrelations';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserAddresses
    */
    crmUserAddresses(
            
            userId: string
,
            appSpace: string
,
        parameters: {
                        'type'?: Array<string
>
,
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  street?: string
;
  number?: string|number
;
  conscription_number?: string|number
;
  city?: string
;
  zip?: string
;
  country?: string
;
  name?: string
;
  surname?: string
;
  company?: string
;
  type?: string
;
  location?: GeoLocation
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/addresses';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            if(typeof parameters['type'] !== 'undefined'){
                queryParameters['type'] = parameters['type'];
            }

            queryParameters['type'] = this.transformParameter(queryParameters['type']);





            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserAddressesCreate
    */
    crmUserAddressesCreate(
            
            userId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        AddressCreateOrUpdate
,
    ): Promise< Address
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/addresses';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserAddressesGet
    */
    crmUserAddressesGet(
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  street?: string
;
  number?: string|number
;
  conscription_number?: string|number
;
  city?: string
;
  zip?: string
;
  country?: string
;
  name?: string
;
  surname?: string
;
  company?: string
;
  type?: string
;
  location?: GeoLocation
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/addresses/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserAddressesUpdate
    */
    crmUserAddressesUpdate(
            
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        AddressCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  street?: string
;
  number?: string|number
;
  conscription_number?: string|number
;
  city?: string
;
  zip?: string
;
  country?: string
;
  name?: string
;
  surname?: string
;
  company?: string
;
  type?: string
;
  location?: GeoLocation
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/addresses/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserAddressesDelete
    */
    crmUserAddressesDelete(
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/addresses/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserBankAccounts
    */
    crmUserBankAccounts(
            userId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/bank-accounts';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserBankAccountsCreate
    */
    crmUserBankAccountsCreate(
            
            userId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        BankAccountCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/bank-accounts';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserBankAccountsGet
    */
    crmUserBankAccountsGet(
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/bank-accounts/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserBankAccountsUpdate
    */
    crmUserBankAccountsUpdate(
            
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        BankAccountCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/bank-accounts/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserBankAccountsDelete
    */
    crmUserBankAccountsDelete(
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/bank-accounts/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserCustomFields
    */
    crmUserCustomFields(
            userId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/custom';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserCustomFieldsCreate
    */
    crmUserCustomFieldsCreate(
            
            userId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        CustomFieldCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/custom';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserCustomFieldsGet
    */
    crmUserCustomFieldsGet(
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/custom/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserCustomFieldsUpdate
    */
    crmUserCustomFieldsUpdate(
            
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        CustomFieldCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/custom/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserCustomFieldsDelete
    */
    crmUserCustomFieldsDelete(
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/custom/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserBio
    */
    crmUserBio(
            userId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  photo?: MediaManagerPhoto
;
  perex?: string
;
  facebook?: string
;
  twitter?: string
;
  instagram?: string
;
  youtube?: string
;
  widgets?: Array<Widget
>
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/bio';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserBioCreate
    */
    crmUserBioCreate(
            
            userId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        UserBio_CreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  photo?: MediaManagerPhoto
;
  perex?: string
;
  facebook?: string
;
  twitter?: string
;
  instagram?: string
;
  youtube?: string
;
  widgets?: Array<Widget
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/bio';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserBioGet
    */
    crmUserBioGet(
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  photo?: MediaManagerPhoto
;
  perex?: string
;
  facebook?: string
;
  twitter?: string
;
  instagram?: string
;
  youtube?: string
;
  widgets?: Array<Widget
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/bio/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserBioUpdate
    */
    crmUserBioUpdate(
            
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        UserBio_CreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  photo?: MediaManagerPhoto
;
  perex?: string
;
  facebook?: string
;
  twitter?: string
;
  instagram?: string
;
  youtube?: string
;
  widgets?: Array<Widget
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/bio/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserBioDelete
    */
    crmUserBioDelete(
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/bio/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserSportOrgs
    */
    crmUserSportOrgs(
            userId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  org_id?: string
;
  org_name?: string
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  source_ppo?: string
;
  source_ppo_name?: string
;
  sector?: Sector
;
  competence_type?: string
;
  date_from?: string
;
  date_to?: string|null
;
  transfer_type?: string
;
  valid_to?: string|null
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/sport-orgs';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserRegisterSportOrg
    */
    crmUserRegisterSportOrg(
            
            userId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        UserSportOrgCreate
,
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  org_id?: string
;
  org_name?: string
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  source_ppo?: string
;
  source_ppo_name?: string
;
  sector?: Sector
;
  competence_type?: string
;
  date_from?: string
;
  date_to?: string|null
;
  transfer_type?: string
;
  valid_to?: string|null
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/sport-orgs';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserGetSportOrg
    */
    crmUserGetSportOrg(
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  org_id?: string
;
  org_name?: string
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  source_ppo?: string
;
  source_ppo_name?: string
;
  sector?: Sector
;
  competence_type?: string
;
  date_from?: string
;
  date_to?: string|null
;
  transfer_type?: string
;
  valid_to?: string|null
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/sport-orgs/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserSportOrgUpdate
    */
    crmUserSportOrgUpdate(
            
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        UserSportOrgUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  org_id?: string
;
  org_name?: string
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  source_ppo?: string
;
  source_ppo_name?: string
;
  sector?: Sector
;
  competence_type?: string
;
  date_from?: string
;
  date_to?: string|null
;
  transfer_type?: string
;
  valid_to?: string|null
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/sport-orgs/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserSportOrgDelete
    */
    crmUserSportOrgDelete(
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/sport-orgs/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserSportExpertOrgs
    */
    crmUserSportExpertOrgs(
            userId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  org_id?: string
;
  org_profile_id?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  sector?: Sector
;
  competence_type?: string
;
  legal_form?: string
;
  license_id?: string
;
  license?: UserLicense
;
  date_from?: string
;
  date_to?: string|null
;
  valid_to?: string|null
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/sport-expert-orgs';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserRegisterSportExpertOrg
    */
    crmUserRegisterSportExpertOrg(
            
            userId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        UserSportExpertOrgCreate
,
    ): Promise< {
  _id?: elementary__id
;
  org_id?: string
;
  org_profile_id?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  sector?: Sector
;
  competence_type?: string
;
  legal_form?: string
;
  license_id?: string
;
  license?: UserLicense
;
  date_from?: string
;
  date_to?: string|null
;
  valid_to?: string|null
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/sport-expert-orgs';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserGetSportExpertOrg
    */
    crmUserGetSportExpertOrg(
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  org_id?: string
;
  org_profile_id?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  sector?: Sector
;
  competence_type?: string
;
  legal_form?: string
;
  license_id?: string
;
  license?: UserLicense
;
  date_from?: string
;
  date_to?: string|null
;
  valid_to?: string|null
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/sport-expert-orgs/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserSportExpertOrgUpdate
    */
    crmUserSportExpertOrgUpdate(
            
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        UserSportExpertOrgUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  org_id?: string
;
  org_profile_id?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  sector?: Sector
;
  competence_type?: string
;
  legal_form?: string
;
  license_id?: string
;
  license?: UserLicense
;
  date_from?: string
;
  date_to?: string|null
;
  valid_to?: string|null
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/sport-expert-orgs/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserSportExpertOrgDelete
    */
    crmUserSportExpertOrgDelete(
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/sport-expert-orgs/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserAwards
    */
    crmUserAwards(
            userId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  org_profile_id?: string
;
  sport?: string
;
  sport_sector?: string
;
  award_type?: string
;
  issue_date?: string
;
  issued_by?: string
;
  created_datetime?: string
;
  org_id?: string
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/awards';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserAwardsCreate
    */
    crmUserAwardsCreate(
            
            userId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        UserAwardCreate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  sport?: string
;
  sport_sector?: string
;
  award_type?: string
;
  issue_date?: string
;
  issued_by?: string
;
  created_datetime?: string
;
  org_id?: string
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/awards';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserAwardsGet
    */
    crmUserAwardsGet(
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  sport?: string
;
  sport_sector?: string
;
  award_type?: string
;
  issue_date?: string
;
  issued_by?: string
;
  created_datetime?: string
;
  org_id?: string
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/awards/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserAwardsUpdate
    */
    crmUserAwardsUpdate(
            
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        UserAwardUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  sport?: string
;
  sport_sector?: string
;
  award_type?: string
;
  issue_date?: string
;
  issued_by?: string
;
  created_datetime?: string
;
  org_id?: string
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/awards/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmProlongUsersMembership
    */
    crmProlongUsersMembership(
            
            appSpace: string
,
            membershipToPpo: string
,
        parameters: {
        } ,
                        body:
                                                        {
                              userIds: Array<string
>
;
                            }
,
    ): Promise< {
  results?: Array<{
  userId: string
;
  success: boolean
;
  error?: Error
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users-membership-prolong/{membershipToPPO}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{membershipToPPO}', membershipToPpo.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmProlongPPOsMembership
    */
    crmProlongPPOsMembership(
            
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        {
                              PPOIds: Array<string
>
;
                            }
,
    ): Promise< {
  results?: Array<{
  PPOId: string
;
  success: boolean
;
  error?: Error
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/ppos-membership-prolong';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserMembership
    */
    crmUserMembership(
            userId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  valid_from?: string
;
  valid_to?: string
;
  status?: UserMembershipStatus
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/membership';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserMembershipCreate
    */
    crmUserMembershipCreate(
            
            userId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        UserMembershipCreate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  valid_from?: string
;
  valid_to?: string
;
  status?: UserMembershipStatus
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/membership';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserMembershipGet
    */
    crmUserMembershipGet(
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  valid_from?: string
;
  valid_to?: string
;
  status?: UserMembershipStatus
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/membership/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserMembershipUpdate
    */
    crmUserMembershipUpdate(
            
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        UserMembershipUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  valid_from?: string
;
  valid_to?: string
;
  status?: UserMembershipStatus
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/membership/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserMembershipDelete
    */
    crmUserMembershipDelete(
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/membership/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserRegnrs
    */
    crmUserRegnrs(
            userId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  regnr?: string
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/regnrs';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserRegnrCreate
    */
    crmUserRegnrCreate(
            
            userId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        UserRegnrCreate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  regnr?: string
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/regnrs';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserRegnrGet
    */
    crmUserRegnrGet(
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  regnr?: string
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/regnrs/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserRegnrUpdate
    */
    crmUserRegnrUpdate(
            
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        UserRegnrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  regnr?: string
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/regnrs/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserRegnrDelete
    */
    crmUserRegnrDelete(
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/regnrs/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserDocuments
    */
    crmUserDocuments(
            userId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  org_profile_id?: string
;
  name?: string
;
  mimetype?: string
;
  size?: number
;
  filepath?: string
;
  type?: string
;
  created_date?: string
;
  note?: string
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/documents';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserDocumentCreate
    */
    crmUserDocumentCreate(
            
            
            
            userId: string
,
            appSpace: string
,
        parameters: {
        } ,
            form: {
                            'upload': File
,
                            'type': string
,
                            'orgProfileId': string
,
            }
                ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  name?: string
;
  mimetype?: string
;
  size?: number
;
  filepath?: string
;
  type?: string
;
  created_date?: string
;
  note?: string
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/documents';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');














            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            form,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserDocumentGet
    */
    crmUserDocumentGet(
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  type?: string
;
  name?: string
;
  documentnr?: string
;
  valid_from?: string
;
  valid_to?: string|null
;
  issued_by_po?: string
;
  verified_by_person?: string
;
  verified_by_position?: string
;
  registration_date?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  note?: string
;
  files?: Array<FileObject
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/documents/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Metoda pre vymazanie dokumentu
    * @method
    * @name CoreApi#crmUserDocumentDelete
    */
    crmUserDocumentDelete(
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/documents/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserDocumentDownloader
    */
    crmUserDocumentDownloader(
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  url: string
;
  download_id: string
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/documents/{itemId}/downloader';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserLicenses
    */
    crmUserLicenses(
            userId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  org_profile_id?: string
;
  type?: string
;
  name?: string
;
  documentnr?: string
;
  valid_from?: string
;
  valid_to?: string|null
;
  issued_by_po?: string
;
  verified_by_person?: string
;
  verified_by_position?: string
;
  registration_date?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  note?: string
;
  files?: Array<FileObject
>
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/licenses';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserLicenseCreate
    */
    crmUserLicenseCreate(
            
            userId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        UserLicenseCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  type?: string
;
  name?: string
;
  documentnr?: string
;
  valid_from?: string
;
  valid_to?: string|null
;
  issued_by_po?: string
;
  verified_by_person?: string
;
  verified_by_position?: string
;
  registration_date?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  note?: string
;
  files?: Array<FileObject
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/licenses';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserLicenseGet
    */
    crmUserLicenseGet(
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  type?: string
;
  name?: string
;
  documentnr?: string
;
  valid_from?: string
;
  valid_to?: string|null
;
  issued_by_po?: string
;
  verified_by_person?: string
;
  verified_by_position?: string
;
  registration_date?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  note?: string
;
  files?: Array<FileObject
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/licenses/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserLicenseUpdate
    */
    crmUserLicenseUpdate(
            
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        UserLicenseCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  type?: string
;
  name?: string
;
  documentnr?: string
;
  valid_from?: string
;
  valid_to?: string|null
;
  issued_by_po?: string
;
  verified_by_person?: string
;
  verified_by_position?: string
;
  registration_date?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  note?: string
;
  files?: Array<FileObject
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/licenses/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserLicenseFileUpload
    */
    crmUserLicenseFileUpload(
            
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        } ,
            form: {
                            'upload': File
,
            }
                ): Promise< FileObject
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/licenses/{itemId}/files';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            form,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Use url to download file
    * @method
    * @name CoreApi#crmUserLicenseFileGet
    */
    crmUserLicenseFileGet(
            userId: string
,
            itemId: string
,
            appSpace: string
,
            fileId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  url: string
;
  download_id: string
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/licenses/{itemId}/files/{fileId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{fileId}', fileId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Metoda pre vymazanie suboru licencie
    * @method
    * @name CoreApi#crmUserLicenseFileDelete
    */
    crmUserLicenseFileDelete(
            userId: string
,
            itemId: string
,
            appSpace: string
,
            fileId: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/licenses/{itemId}/files/{fileId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{fileId}', fileId.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserMedicalExaminations
    */
    crmUserMedicalExaminations(
            userId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  org_profile_id: string
;
  doctor?: string
;
  valid_from?: string
;
  valid_to?: string|null
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/medical-examinations';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserMedicalExaminationCreate
    */
    crmUserMedicalExaminationCreate(
            
            userId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        UserMedicalExaminationCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id: string
;
  doctor?: string
;
  valid_from?: string
;
  valid_to?: string|null
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/medical-examinations';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserMedicalExaminationGet
    */
    crmUserMedicalExaminationGet(
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id: string
;
  doctor?: string
;
  valid_from?: string
;
  valid_to?: string|null
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/medical-examinations/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserMedicalExaminationUpdate
    */
    crmUserMedicalExaminationUpdate(
            
            userId: string
,
            itemId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        UserMedicalExaminationCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id: string
;
  doctor?: string
;
  valid_from?: string
;
  valid_to?: string|null
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/medical-examinations/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserFormalPhotos
    */
    crmUserFormalPhotos(
            userId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  token?: string
;
  public?: boolean
;
  public_url?: string
;
}
>
;
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/formal-photo';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmUserFormalPhotoUpload
    */
    crmUserFormalPhotoUpload(
            
            
            userId: string
,
            appSpace: string
,
        parameters: {
        } ,
            form: {
                            'upload': File
,
                            'public'?: boolean
,
            }
                ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/formal-photo';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');










            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            form,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Metoda pre vymazanie formalnej fotky
    * @method
    * @name CoreApi#crmUserFormalPhotoDelete
    */
    crmUserFormalPhotoDelete(
            userId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/users/{userId}/formal-photo';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#createDSGEditRequest
    */
    createDSGEditRequest(
            
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        {
                              targetGroups?: Array<{
  iban?: string
;
}
>
;
                              users?: Array<{
  type?: "add" | "remove"
;
  user?: {
  sportnetId?: string
;
  name?: string
;
  type?: string
;
}
;
}
>
;
                            }
,
    ): Promise< Request
  >
    {
        let path = '/crm/{appSpace}/create-request/type/dsg-edit';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#createPersonTransferRequest
    */
    createPersonTransferRequest(
            
            appSpace: string
,
            transferType: "permanent" | "limited"
,
        parameters: {
        } ,
                        body:
                                                        {
                              targetPerson: {
  sportnetId: elementary__id
;
  displayName: string
;
}
;
                              sector: Sector
;
                              dateFrom: string
;
                              dateTo?: string|null
;
                            }
,
    ): Promise< Request
  >
    {
        let path = '/crm/{appSpace}/create-request/person-transfer-{transferType}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{transferType}', transferType.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmGetRequests
    */
    crmGetRequests(
            
            
            
            
            
            appSpace: string
,
        parameters: {
                        'limit'?: number
,
                        'offset'?: number
,
                        'q'?: string
,
                        'statuses'?: Array<string
>
,
                        'requestTypes'?: Array<string
>
,
        }  = {},
    ): Promise< {
  total?: number
;
  limit?: number
;
  offset?: number
;
  next_offset?: number|null
;
  requests?: Array<RequestListItem
>
;
}
  >
    {
        let path = '/crm/{appSpace}/requests';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
                queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);




              queryParameters['offset'] = 0;

            if(typeof parameters['offset'] !== 'undefined'){
                queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);





            if(typeof parameters['q'] !== 'undefined'){
                queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);





            if(typeof parameters['statuses'] !== 'undefined'){
                queryParameters['statuses'] = parameters['statuses'];
            }

            queryParameters['statuses'] = this.transformParameter(queryParameters['statuses']);





            if(typeof parameters['requestTypes'] !== 'undefined'){
                queryParameters['requestTypes'] = parameters['requestTypes'];
            }

            queryParameters['requestTypes'] = this.transformParameter(queryParameters['requestTypes']);





            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmRequestDetail
    */
    crmRequestDetail(
            appSpace: string
,
            requestId: string
,
        parameters: {
        }  = {},
    ): Promise< Request
  >
    {
        let path = '/crm/{appSpace}/requests/{requestId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{requestId}', requestId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmGetRelatedRequests
    */
    crmGetRelatedRequests(
            appSpace: string
,
            requestId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  requests?: Array<RequestListItem
>
;
}
  >
    {
        let path = '/crm/{appSpace}/requests/{requestId}/related';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{requestId}', requestId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmGetRequestAttachmentFile
    */
    crmGetRequestAttachmentFile(
            appSpace: string
,
            requestId: string
,
            idx: number
,
        parameters: {
        }  = {},
    ): Promise< File
  >
    {
        let path = '/crm/{appSpace}/requests/{requestId}/attachment/{idx}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{requestId}', requestId.toString());




            path = path.replace('{idx}', idx.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmChangeRequestStatus
    */
    crmChangeRequestStatus(
            
            appSpace: string
,
            requestId: string
,
        parameters: {
        } ,
                        body:
                                                        {
                              status?: string
;
                              note?: string
;
                            }
,
    ): Promise< Request
  >
    {
        let path = '/crm/{appSpace}/requests/{requestId}/changeStatus';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{requestId}', requestId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmConfirmTransferRequest
    */
    crmConfirmTransferRequest(
            
            appSpace: string
,
            requestId: string
,
        parameters: {
        } ,
                        body:
                                                        {
                            }
,
    ): Promise< Request
  >
    {
        let path = '/crm/{appSpace}/requests/{requestId}/confirm/transfer';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{requestId}', requestId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmConfirmPoDataChangeRequest
    */
    crmConfirmPoDataChangeRequest(
            
            appSpace: string
,
            requestId: string
,
        parameters: {
        } ,
                        body:
                                                        {
                            }
,
    ): Promise< Request
  >
    {
        let path = '/crm/{appSpace}/requests/{requestId}/confirm/po-data-change';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{requestId}', requestId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmConfirmUserProfileChangeRequest
    */
    crmConfirmUserProfileChangeRequest(
            
            appSpace: string
,
            requestId: string
,
        parameters: {
        } ,
                        body:
                                                        {
                            }
,
    ): Promise< Request
  >
    {
        let path = '/crm/{appSpace}/requests/{requestId}/confirm/user-profile-change';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{requestId}', requestId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmConfirmSportOrgRequest
    */
    crmConfirmSportOrgRequest(
            
            appSpace: string
,
            requestId: string
,
        parameters: {
        } ,
                        body:
                                                        {
                            }
,
    ): Promise< Request
  >
    {
        let path = '/crm/{appSpace}/requests/{requestId}/confirm/sport-org';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{requestId}', requestId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmConfirmSportExpertOrgRequest
    */
    crmConfirmSportExpertOrgRequest(
            
            appSpace: string
,
            requestId: string
,
        parameters: {
        } ,
                        body:
                                                        {
                              verified_by_person: string
;
                              verified_by_position: string
;
                            }
,
    ): Promise< Request
  >
    {
        let path = '/crm/{appSpace}/requests/{requestId}/confirm/sport-expert-org';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{requestId}', requestId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmConfirmDSGEditRequest
    */
    crmConfirmDSGEditRequest(
            
            appSpace: string
,
            requestId: string
,
        parameters: {
        } ,
                        body:
                                                        {
                              ppo?: {
  register_apps?: Array<{
  app_id: string
;
  userId: string
;
  userName: string
;
  role: string
;
  grant: boolean
;
}
>
;
}
;
                            }
,
    ): Promise< Request
  >
    {
        let path = '/crm/{appSpace}/requests/{requestId}/confirm/dsg-edit';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{requestId}', requestId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmConfirmPoRegistryRequest
    */
    crmConfirmPoRegistryRequest(
            appSpace: string
,
            requestId: string
,
        parameters: {
        }  = {},
    ): Promise< Request
  >
    {
        let path = '/crm/{appSpace}/requests/{requestId}/confirm/po-registry';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{requestId}', requestId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmConfirmLicenseRequest
    */
    crmConfirmLicenseRequest(
            
            appSpace: string
,
            requestId: string
,
        parameters: {
        }  = {},
                        body:
                                                        {
                              validFrom: string
;
                              validTo: string
;
                              verifiedByPerson: string
;
                              licenseName: string
;
                              licenseType: string
;
                              licenseSubTypeId: number
;
                              ownerUserSportnetId: string
;
                            }
,
    ): Promise< Request
  >
    {
        let path = '/crm/{appSpace}/requests/{requestId}/confirm/license';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{requestId}', requestId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmClosePoRegistryRequest
    */
    crmClosePoRegistryRequest(
            
            appSpace: string
,
            requestId: string
,
        parameters: {
        } ,
                        body:
                                                        {
                              ppo?: {
  sport: string
;
  register_apps?: Array<{
  app_id: string
;
  userId: string
;
  userName: string
;
  role: string
;
  grant: boolean
;
}
>
;
}
;
                            }
,
    ): Promise< Request
  >
    {
        let path = '/crm/{appSpace}/requests/{requestId}/close/po-registry';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{requestId}', requestId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#requestDetail
    */
    requestDetail(
            requestId: string
,
        parameters: {
        }  = {},
    ): Promise< Request
  >
    {
        let path = '/requests/{requestId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{requestId}', requestId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#uploadRequestAttachment
    */
    uploadRequestAttachment(
            
            
            
            requestId: string
,
        parameters: {
        } ,
            form: {
                            'upload'?: File
,
                            'fileId': string
,
                            'name': string
,
            }
                ): Promise< Request
  >
    {
        let path = '/requests/{requestId}/attachment';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');














            path = path.replace('{requestId}', requestId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            form,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#getRequestAttachmentFile
    */
    getRequestAttachmentFile(
            requestId: string
,
            idx: number
,
        parameters: {
        }  = {},
    ): Promise< File
  >
    {
        let path = '/requests/{requestId}/attachment/{idx}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{requestId}', requestId.toString());




            path = path.replace('{idx}', idx.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#cancelRequest
    */
    cancelRequest(
            
            requestId: string
,
        parameters: {
        } ,
                        body:
                                                        {
                              user_note?: string
;
                            }
,
    ): Promise< Request
  >
    {
        let path = '/requests/{requestId}/cancel';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{requestId}', requestId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#approveRequest
    */
    approveRequest(
            
            requestId: string
,
        parameters: {
        } ,
                        body:
                                                        {
                              user_note?: string
;
                            }
,
    ): Promise< Request
  >
    {
        let path = '/requests/{requestId}/approve';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{requestId}', requestId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#getOrganizations
    */
    getOrganizations(
            
            
            
            
        parameters: {
                        'q'?: string
,
                        'businessId'?: string
,
                        'offset'?: number
,
                        'limit'?: number
,
        }  = {},
    ): Promise< {
  total?: number
;
  limit?: number
;
  offset?: number
;
  next_offset?: number|null
;
  organizations?: Array<OrganizationWithProfiles
>
;
}
  >
    {
        let path = '/organizations';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            if(typeof parameters['q'] !== 'undefined'){
                queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);





            if(typeof parameters['businessId'] !== 'undefined'){
                queryParameters['businessId'] = parameters['businessId'];
            }

            queryParameters['businessId'] = this.transformParameter(queryParameters['businessId']);




              queryParameters['offset'] = 0;

            if(typeof parameters['offset'] !== 'undefined'){
                queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);




              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
                queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);





        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#getOrganizationProfiles
    */
    getOrganizationProfiles(
            orgId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: string
;
  name?: string
;
  country?: string
;
  type?: string
;
  sectors?: Array<{
  category?: "sport"
;
  itemId?: string
;
  sectorId?: string
;
  matricityPPO?: string
;
  _id?: string
;
}
>
;
  sport?: string
;
  status?: string
;
  parent_relations?: Array<OrganizationProfile2
>
;
  matricity_relations?: Array<OrganizationProfile2
>
;
  organization_id?: string
;
  organization_name?: string
;
  logo_public_url?: string|null
;
  favicon_public_url?: string|null
;
  shortNames?: Array<OrganizationShortName
>
;
  relations?: Array<OrganizationProfileRelation
>
;
}
>
;
}
  >
    {
        let path = '/organizations/{orgId}/profiles';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{orgId}', orgId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminGetOrganizations
    */
    adminGetOrganizations(
            
            
            
            
            
        parameters: {
                        'limit'?: number
,
                        'offset'?: number
,
                        'q'?: string
,
                        'ids'?: Array<string
>
,
                        'sorter'?: "name" | "-name"
,
        }  = {},
    ): Promise< {
  total?: number
;
  limit?: number
;
  offset?: number
;
  next_offset?: number|null
;
  items?: Array<Organization
>
;
}
  >
    {
        let path = '/admin/organizations';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
                queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);




              queryParameters['offset'] = 0;

            if(typeof parameters['offset'] !== 'undefined'){
                queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);





            if(typeof parameters['q'] !== 'undefined'){
                queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);





            if(typeof parameters['ids'] !== 'undefined'){
                queryParameters['ids'] = parameters['ids'];
            }

            queryParameters['ids'] = this.transformParameter(queryParameters['ids']);




              queryParameters['sorter'] = "-name";

            if(typeof parameters['sorter'] !== 'undefined'){
                queryParameters['sorter'] = parameters['sorter'];
            }

            queryParameters['sorter'] = this.transformParameter(queryParameters['sorter']);





        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminCreateOrganization
    */
    adminCreateOrganization(
            
        parameters: {
        } ,
                        body:
                                                        OrganizationCreate
,
    ): Promise< Organization
  >
    {
        let path = '/admin/organizations';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminGetOrganization
    */
    adminGetOrganization(
            orgId: string
,
        parameters: {
        }  = {},
    ): Promise< Organization
  >
    {
        let path = '/admin/organizations/{orgId}/profile';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{orgId}', orgId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUpdateOrganization
    */
    adminUpdateOrganization(
            
            orgId: string
,
        parameters: {
        } ,
                        body:
                                                        OrganizationUpdate
,
    ): Promise< Organization
  >
    {
        let path = '/admin/organizations/{orgId}/profile';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{orgId}', orgId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminOrganizationAddresses
    */
    adminOrganizationAddresses(
            
            orgId: string
,
        parameters: {
                        'type'?: Array<string
>
,
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  street?: string
;
  number?: string|number
;
  conscription_number?: string|number
;
  city?: string
;
  zip?: string
;
  country?: string
;
  name?: string
;
  surname?: string
;
  company?: string
;
  type?: string
;
  location?: GeoLocation
;
}
>
;
}
  >
    {
        let path = '/admin/organizations/{orgId}/addresses';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            if(typeof parameters['type'] !== 'undefined'){
                queryParameters['type'] = parameters['type'];
            }

            queryParameters['type'] = this.transformParameter(queryParameters['type']);





            path = path.replace('{orgId}', orgId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminOrganizationAddressesCreate
    */
    adminOrganizationAddressesCreate(
            
            orgId: string
,
        parameters: {
        } ,
                        body:
                                                        AddressCreateOrUpdate
,
    ): Promise< Address
  >
    {
        let path = '/admin/organizations/{orgId}/addresses';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{orgId}', orgId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminOrganizationAddressesGet
    */
    adminOrganizationAddressesGet(
            orgId: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  street?: string
;
  number?: string|number
;
  conscription_number?: string|number
;
  city?: string
;
  zip?: string
;
  country?: string
;
  name?: string
;
  surname?: string
;
  company?: string
;
  type?: string
;
  location?: GeoLocation
;
}
  >
    {
        let path = '/admin/organizations/{orgId}/addresses/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{orgId}', orgId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminOrganizationAddressesUpdate
    */
    adminOrganizationAddressesUpdate(
            
            orgId: string
,
            itemId: string
,
        parameters: {
        } ,
                        body:
                                                        AddressCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  street?: string
;
  number?: string|number
;
  conscription_number?: string|number
;
  city?: string
;
  zip?: string
;
  country?: string
;
  name?: string
;
  surname?: string
;
  company?: string
;
  type?: string
;
  location?: GeoLocation
;
}
  >
    {
        let path = '/admin/organizations/{orgId}/addresses/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{orgId}', orgId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminOrganizationAddressesDelete
    */
    adminOrganizationAddressesDelete(
            orgId: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/admin/organizations/{orgId}/addresses/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{orgId}', orgId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminOrganizationBankAccounts
    */
    adminOrganizationBankAccounts(
            orgId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
>
;
}
  >
    {
        let path = '/admin/organizations/{orgId}/bank-accounts';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{orgId}', orgId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminOrganizationBankAccountsCreate
    */
    adminOrganizationBankAccountsCreate(
            
            orgId: string
,
        parameters: {
        } ,
                        body:
                                                        BankAccountCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
  >
    {
        let path = '/admin/organizations/{orgId}/bank-accounts';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{orgId}', orgId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminOrganizationBankAccountsGet
    */
    adminOrganizationBankAccountsGet(
            orgId: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
  >
    {
        let path = '/admin/organizations/{orgId}/bank-accounts/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{orgId}', orgId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminOrganizationBankAccountsUpdate
    */
    adminOrganizationBankAccountsUpdate(
            
            orgId: string
,
            itemId: string
,
        parameters: {
        } ,
                        body:
                                                        BankAccountCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
  >
    {
        let path = '/admin/organizations/{orgId}/bank-accounts/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{orgId}', orgId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminOrganizationBankAccountsDelete
    */
    adminOrganizationBankAccountsDelete(
            orgId: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/admin/organizations/{orgId}/bank-accounts/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{orgId}', orgId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminOrganizationCustomFields
    */
    adminOrganizationCustomFields(
            orgId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
>
;
}
  >
    {
        let path = '/admin/organizations/{orgId}/custom';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{orgId}', orgId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminOrganizationCustomFieldsCreate
    */
    adminOrganizationCustomFieldsCreate(
            
            orgId: string
,
        parameters: {
        } ,
                        body:
                                                        CustomFieldCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
  >
    {
        let path = '/admin/organizations/{orgId}/custom';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{orgId}', orgId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminOrganizationCustomFieldsGet
    */
    adminOrganizationCustomFieldsGet(
            orgId: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
  >
    {
        let path = '/admin/organizations/{orgId}/custom/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{orgId}', orgId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminOrganizationCustomFieldsUpdate
    */
    adminOrganizationCustomFieldsUpdate(
            
            orgId: string
,
            itemId: string
,
        parameters: {
        } ,
                        body:
                                                        CustomFieldCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
  >
    {
        let path = '/admin/organizations/{orgId}/custom/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{orgId}', orgId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminOrganizationCustomFieldsDelete
    */
    adminOrganizationCustomFieldsDelete(
            orgId: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/admin/organizations/{orgId}/custom/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{orgId}', orgId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminOrganizationProfiles
    */
    adminOrganizationProfiles(
            orgId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<OrganizationProfile
>
;
}
  >
    {
        let path = '/admin/organizations/{orgId}/profiles';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{orgId}', orgId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminOrganizationProfileCreate
    */
    adminOrganizationProfileCreate(
            
            orgId: string
,
        parameters: {
        } ,
                        body:
                                                        OrganizationProfileCreate
,
    ): Promise< OrganizationProfile
  >
    {
        let path = '/admin/organizations/{orgId}/profiles';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{orgId}', orgId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminOrganizationProfile
    */
    adminOrganizationProfile(
            orgId: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<OrganizationProfile
>
;
}
  >
    {
        let path = '/admin/organizations/{orgId}/profiles/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{orgId}', orgId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminOrganizationProfileUpdate
    */
    adminOrganizationProfileUpdate(
            
            orgId: string
,
            itemId: string
,
        parameters: {
        } ,
                        body:
                                                        OrganizationProfileUpdate
,
    ): Promise< OrganizationProfile
  >
    {
        let path = '/admin/organizations/{orgId}/profiles/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{orgId}', orgId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Zoznam aplikacii povolenych pre PPO
    * @method
    * @name CoreApi#adminOrganizationProfileApps
    */
    adminOrganizationProfileApps(
            orgId: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  apps?: Array<{
  _id?: string
;
  name?: string
;
  description?: string|null
;
  url?: string|null
;
  marketingUrl?: string|null
;
  appSpaceUrlPattern?: string|null
;
  action_buttons?: Array<{
  label: string
;
  urlPattern: string
;
  roles?: Array<string
>
;
}
>
;
  logo?: string|null
;
  roles?: Array<AppRole
>
;
  flags?: Array<string
>
;
  child_apps?: Array<AppPublic_
>
;
  validTo?: string
;
}
>
;
}
  >
    {
        let path = '/admin/organizations/{orgId}/profiles/{itemId}/apps';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{orgId}', orgId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Prislusnosti k inym PPO
    * @method
    * @name CoreApi#adminOrganizationProfileRelations
    */
    adminOrganizationProfileRelations(
            orgId: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  related_ppo?: string
;
  date_from?: string
;
  date_to?: string|null
;
  member_until?: string|null
;
  status?: elementary_itemStatus
;
  related_ppo_name?: string
;
  related_ppo_organization_name?: string
;
}
>
;
}
  >
    {
        let path = '/admin/organizations/{orgId}/profiles/{itemId}/relations';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{orgId}', orgId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Prislusnosti k inym PPO - vytvorenie novej
    * @method
    * @name CoreApi#adminOrganizationProfileCreateRelation
    */
    adminOrganizationProfileCreateRelation(
            
            orgId: string
,
            itemId: string
,
        parameters: {
        } ,
                        body:
                                                        OrganizationProfileRelationCreate
,
    ): Promise< {
  _id?: elementary__id
;
  related_ppo?: string
;
  date_from?: string
;
  date_to?: string|null
;
  member_until?: string|null
;
  status?: elementary_itemStatus
;
  related_ppo_name?: string
;
  related_ppo_organization_name?: string
;
}
  >
    {
        let path = '/admin/organizations/{orgId}/profiles/{itemId}/relations';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{orgId}', orgId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Prislusnosti k inym PPO
    * @method
    * @name CoreApi#adminOrganizationProfileRelation
    */
    adminOrganizationProfileRelation(
            orgId: string
,
            itemId: string
,
            relationId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  related_ppo?: string
;
  date_from?: string
;
  date_to?: string|null
;
  member_until?: string|null
;
  status?: elementary_itemStatus
;
  related_ppo_name?: string
;
  related_ppo_organization_name?: string
;
}
  >
    {
        let path = '/admin/organizations/{orgId}/profiles/{itemId}/relations/{relationId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{orgId}', orgId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{relationId}', relationId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminCreatePPOSector
    */
    adminCreatePPOSector(
            
            appSpace: string
,
        parameters: {
        }  = {},
                        body:
                                                        {
                              _id: string
;
                              matricityPPO?: string
;
                            }
,
    ): Promise< {
  sectors?: Array<Sector
>
;
}
  >
    {
        let path = '/admin/ppo/{appSpace}/sectors';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminDeletePPOSector
    */
    adminDeletePPOSector(
            appSpace: string
,
            sectorId: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/admin/ppo/{appSpace}/sectors/{sectorId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{sectorId}', sectorId.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Zoznam aplikacii povolenych pre PPO
    * @method
    * @name CoreApi#adminPpoApps
    */
    adminPpoApps(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  apps?: Array<{
  _id?: string
;
  app_id?: string
;
  validTo?: string
;
}
>
;
}
  >
    {
        let path = '/admin/ppo/{appSpace}/apps';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Vytvorenie novej PPO
    * @method
    * @name CoreApi#adminCreateOrganizationPPOProfile
    */
    adminCreateOrganizationPPOProfile(
            
        parameters: {
        }  = {},
                        body:
                                                        {
                              _id: string
;
                              name: string
;
                              type: string
;
                              status: string
;
                              business_id: string
;
                              country?: string
;
                            }
,
    ): Promise< {
  _id?: string
;
  name?: string
;
  country?: string
;
  type?: string
;
  sectors?: Array<{
  category?: "sport"
;
  itemId?: string
;
  sectorId?: string
;
  matricityPPO?: string
;
  _id?: string
;
}
>
;
  sport?: string
;
  status?: string
;
  parent_relations?: Array<OrganizationProfile2
>
;
  matricity_relations?: Array<OrganizationProfile2
>
;
  organization_id?: string
;
  organization_name?: string
;
  logo_public_url?: string|null
;
  favicon_public_url?: string|null
;
  shortNames?: Array<OrganizationShortName
>
;
  createdBy?: {
  type?: string
;
  payload?: {
}
;
}
;
  professional?: boolean
;
  seasonCategories?: Array<{
}
>
;
}
  >
    {
        let path = '/admin/ppo';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Get business info based on ICO
    * @method
    * @name CoreApi#getBusinessInfo
    */
    getBusinessInfo(
            
            
            
            ico: string
,
        parameters: {
                        'forceFinstat'?: boolean
,
                        'sportnetOnly'?: boolean
,
                        'withPpoIds'?: boolean
,
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/business-info/{ico}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

              queryParameters['forceFinstat'] = false;

            if(typeof parameters['forceFinstat'] !== 'undefined'){
                queryParameters['forceFinstat'] = parameters['forceFinstat'];
            }

            queryParameters['forceFinstat'] = this.transformParameter(queryParameters['forceFinstat']);




              queryParameters['sportnetOnly'] = false;

            if(typeof parameters['sportnetOnly'] !== 'undefined'){
                queryParameters['sportnetOnly'] = parameters['sportnetOnly'];
            }

            queryParameters['sportnetOnly'] = this.transformParameter(queryParameters['sportnetOnly']);




              queryParameters['withPPOIds'] = false;

            if(typeof parameters['withPpoIds'] !== 'undefined'){
                queryParameters['withPPOIds'] = parameters['withPpoIds'];
            }

            queryParameters['withPPOIds'] = this.transformParameter(queryParameters['withPPOIds']);





            path = path.replace('{ico}', ico.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Get codelist ids
    * @method
    * @name CoreApi#listCodelists
    */
    listCodelists(
        parameters: {
        }  = {},
    ): Promise< {
  codelists?: Array<string
>
;
}
  >
    {
        let path = '/codelist';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Get codelist
    * @method
    * @name CoreApi#getCodelist
    */
    getCodelist(
            codelist: string
,
        parameters: {
        }  = {},
    ): Promise< Codelist
  >
    {
        let path = '/codelist/{codelist}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{codelist}', codelist.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Get codelist
    * @method
    * @name CoreApi#getCodelistSector
    */
    getCodelistSector(
            codelist: string
,
            codelistItem: string
,
        parameters: {
        }  = {},
    ): Promise< Codelist
  >
    {
        let path = '/codelist/{codelist}/{codelistItem}/sector';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{codelist}', codelist.toString());




            path = path.replace('{codelistItem}', codelistItem.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Get codelist
    * @method
    * @name CoreApi#getCodelistSectorDiscipline
    */
    getCodelistSectorDiscipline(
            codelist: string
,
            codelistItem: string
,
            sectorItem: string
,
        parameters: {
        }  = {},
    ): Promise< Codelist
  >
    {
        let path = '/codelist/{codelist}/{codelistItem}/sector/{sectorItem}/discipline';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{codelist}', codelist.toString());




            path = path.replace('{codelistItem}', codelistItem.toString());




            path = path.replace('{sectorItem}', sectorItem.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#processAppgrantInvitationResponse
    */
    processAppgrantInvitationResponse(
            invitationId: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/appgrant-invitation-callback/{invitationId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{invitationId}', invitationId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#processContactPersonInvitationResponse
    */
    processContactPersonInvitationResponse(
            invitationId: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm-contact-person-invitation-callback/{invitationId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{invitationId}', invitationId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#processPersonRelationInvitationResponse
    */
    processPersonRelationInvitationResponse(
            invitationId: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/person-relation-invitation-callback/{invitationId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{invitationId}', invitationId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Invite email for `appId` in `appSpace`. Use this method for update user permissions via invitation also.
    * @method
    * @name CoreApi#appgrantInviteUser
    */
    appgrantInviteUser(
            
            appId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        {
                              email: string
;
                              role: string
;
                              grant: boolean
;
                              child_apps_role?: Array<{
  app_id: string
;
  role: string|null
;
}
>
;
                            }
,
    ): Promise< {
}
  >
    {
        let path = '/appgrant-invitation/{appId}/{appSpace}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appId}', appId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Get grant roles for `appId` in `appSpace`
    * @method
    * @name CoreApi#appgrantGetRoles
    */
    appgrantGetRoles(
            appId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  users?: Array<AppSpaceUser
>
;
}
  >
    {
        let path = '/appgrant/{appId}/{appSpace}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appId}', appId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Set grant roles for `appId` in `appSpace`. Use this method for update user permissions also.
    * @method
    * @name CoreApi#appgrantRegisterUser
    */
    appgrantRegisterUser(
            
            appId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        {
                              user_id: string
;
                              role: string
;
                              grant: boolean
;
                              display_name?: string
;
                              child_apps_role?: Array<{
  app_id: string
;
  role: string|null
;
}
>
;
                            }
,
    ): Promise< {
  roles?: Array<AppSpaceUser
>
;
}
  >
    {
        let path = '/appgrant/{appId}/{appSpace}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appId}', appId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Delete grant role for `appId` in `appSpace` for `userId`
    * @method
    * @name CoreApi#appgrantDeleteRoleForUser
    */
    appgrantDeleteRoleForUser(
            appId: string
,
            appSpace: string
,
            userId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  roles?: Array<AppSpaceUser
>
;
}
  >
    {
        let path = '/appgrant/{appId}/{appSpace}/{userId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appId}', appId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{userId}', userId.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Helper method to get user public profile by userId
    * @method
    * @name CoreApi#appgrantGetProfileForUser
    */
    appgrantGetProfileForUser(
            appId: string
,
            appSpace: string
,
            userId: string
,
        parameters: {
        }  = {},
    ): Promise< UserPublicProfile
  >
    {
        let path = '/appgrant/{appId}/{appSpace}/{userId}/profile';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appId}', appId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{userId}', userId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Get public (primary) apps list
    * @method
    * @name CoreApi#getPublicApps
    */
    getPublicApps(
            
        parameters: {
                        'graph'?: boolean
,
        }  = {},
    ): Promise< {
  apps?: Array<AppPublic
>
;
}
  >
    {
        let path = '/apps';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

              queryParameters['graph'] = false;

            if(typeof parameters['graph'] !== 'undefined'){
                queryParameters['graph'] = parameters['graph'];
            }

            queryParameters['graph'] = this.transformParameter(queryParameters['graph']);





        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Get public app detail
    * @method
    * @name CoreApi#getPublicApp
    */
    getPublicApp(
            appId: string
,
        parameters: {
        }  = {},
    ): Promise< AppPublic
  >
    {
        let path = '/apps/{appId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appId}', appId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * List of registered apps
    * @method
    * @name CoreApi#getApps
    */
    getApps(
        parameters: {
        }  = {},
    ): Promise< {
  apps?: Array<App
>
;
}
  >
    {
        let path = '/admin/apps';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Register new app
    * @method
    * @name CoreApi#createApp
    */
    createApp(
            
        parameters: {
        } ,
                        body:
                                                        AppCreate
,
    ): Promise< App
  >
    {
        let path = '/admin/apps';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Get app detail
    * @method
    * @name CoreApi#getApp
    */
    getApp(
            appId: string
,
        parameters: {
        }  = {},
    ): Promise< App
  >
    {
        let path = '/admin/apps/{appId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appId}', appId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Update app details (app secret, registered roles, subapp ids)
    * @method
    * @name CoreApi#updateApp
    */
    updateApp(
            
            appId: string
,
        parameters: {
        } ,
                        body:
                                                        AppUpdate
,
    ): Promise< App
  >
    {
        let path = '/admin/apps/{appId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appId}', appId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Get appspaces registered for app
    * @method
    * @name CoreApi#getAppAppSpaces
    */
    getAppAppSpaces(
            appId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  appspaces?: Array<{
  app_space: string
;
  org_profile?: OrganizationProfile
;
}
>
;
}
  >
    {
        let path = '/admin/apps/{appId}/appspaces';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appId}', appId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Register new appspace (allow organization profile id to use the app)
    * @method
    * @name CoreApi#registerAppSpace
    */
    registerAppSpace(
            
            appId: string
,
        parameters: {
        } ,
                        body:
                                                        {
                              org_profile_id: string
;
                            }
,
    ): Promise< {
}
  >
    {
        let path = '/admin/apps/{appId}/appspaces';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appId}', appId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Get appspaces detail - list of users
    * @method
    * @name CoreApi#getAppSpaceUsers
    */
    getAppSpaceUsers(
            appId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  users?: Array<AppSpaceUser
>
;
}
  >
    {
        let path = '/admin/apps/{appId}/appspaces/{appSpace}/users';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appId}', appId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Register new user to appspace
    * @method
    * @name CoreApi#registerAppSpaceUser
    */
    registerAppSpaceUser(
            
            appId: string
,
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        {
                              user_id: string
;
                              role: string
;
                              grant: boolean
;
                              display_name?: string
;
                              child_apps_role?: Array<{
  app_id: string
;
  role: string|null
;
}
>
;
                            }
,
    ): Promise< {
  users?: Array<AppSpaceUser
>
;
}
  >
    {
        let path = '/admin/apps/{appId}/appspaces/{appSpace}/users';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appId}', appId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Delete all user's rights in apps in some appSpace
    * @method
    * @name CoreApi#deleteUserAppSpaces
    */
    deleteUserAppSpaces(
            
            appSpace: string
,
            userId: string
,
        parameters: {
                        'appIds'?: Array<string
>
,
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/admin/apps/appspaces/{appSpace}/users/{userId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            if(typeof parameters['appIds'] !== 'undefined'){
                queryParameters['appIds'] = parameters['appIds'];
            }

            queryParameters['appIds'] = this.transformParameter(queryParameters['appIds']);





            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{userId}', userId.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Update appspace user
    * @method
    * @name CoreApi#updateAppSpaceUser
    */
    updateAppSpaceUser(
            
            appId: string
,
            appSpace: string
,
            userId: string
,
        parameters: {
        } ,
                        body:
                                                        {
                              display_name: string
;
                              role: string
;
                              grant: boolean
;
                              child_apps_role: Array<{
  app_id: string
;
  role: string|null
;
}
>
;
                            }
,
    ): Promise< {
  users?: Array<AppSpaceUser
>
;
}
  >
    {
        let path = '/admin/apps/{appId}/appspaces/{appSpace}/users/{userId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appId}', appId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{userId}', userId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Delete appspace user
    * @method
    * @name CoreApi#deleteAppSpaceUser
    */
    deleteAppSpaceUser(
            appId: string
,
            appSpace: string
,
            userId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  users?: Array<AppSpaceUser
>
;
}
  >
    {
        let path = '/admin/apps/{appId}/appspaces/{appSpace}/users/{userId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appId}', appId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{userId}', userId.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminGetUsers
    */
    adminGetUsers(
            
            
            
            
            
            
            
            
        parameters: {
                        'limit'?: number
,
                        'offset'?: number
,
                        'q'?: string
,
                        'email'?: string
,
                        'importId'?: string
,
                        'externalId'?: string
,
                        'ids'?: Array<string
>
,
                        'sorter'?: "_id" | "-_id" | "created_datetime" | "-created_datetime" | "person" | "-person" | "birthdate" | "-birthdate-person"
,
        }  = {},
    ): Promise< {
  total?: number
;
  limit?: number
;
  offset?: number
;
  next_offset?: number|null
;
  users?: Array<UserProfile
>
;
}
  >
    {
        let path = '/admin/users';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
                queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);




              queryParameters['offset'] = 0;

            if(typeof parameters['offset'] !== 'undefined'){
                queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);





            if(typeof parameters['q'] !== 'undefined'){
                queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);





            if(typeof parameters['email'] !== 'undefined'){
                queryParameters['email'] = parameters['email'];
            }

            queryParameters['email'] = this.transformParameter(queryParameters['email']);





            if(typeof parameters['importId'] !== 'undefined'){
                queryParameters['import_id'] = parameters['importId'];
            }

            queryParameters['import_id'] = this.transformParameter(queryParameters['import_id']);





            if(typeof parameters['externalId'] !== 'undefined'){
                queryParameters['external_id'] = parameters['externalId'];
            }

            queryParameters['external_id'] = this.transformParameter(queryParameters['external_id']);





            if(typeof parameters['ids'] !== 'undefined'){
                queryParameters['ids'] = parameters['ids'];
            }

            queryParameters['ids'] = this.transformParameter(queryParameters['ids']);




              queryParameters['sorter'] = "-created_datetime";

            if(typeof parameters['sorter'] !== 'undefined'){
                queryParameters['sorter'] = parameters['sorter'];
            }

            queryParameters['sorter'] = this.transformParameter(queryParameters['sorter']);





        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminGetUser
    */
    adminGetUser(
            userId: string
,
        parameters: {
        }  = {},
    ): Promise< UserProfile
  >
    {
        let path = '/admin/users/{userId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminDeleteUser
    */
    adminDeleteUser(
            userId: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/admin/users/{userId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * List of all user roles, in all appspaces, divided by app_id

    * @method
    * @name CoreApi#adminGetUserAppSpaces
    */
    adminGetUserAppSpaces(
            
            userId: string
,
        parameters: {
                        'expandApp'?: boolean
,
        }  = {},
    ): Promise< {
  apps?: Array<{
  app_id?: string
;
  app?: AppPublic
;
  appspaces?: Array<AppSpaceUser
>
;
}
>
;
}
  >
    {
        let path = '/admin/users/{userId}/appspaces';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

              queryParameters['expandApp'] = false;

            if(typeof parameters['expandApp'] !== 'undefined'){
                queryParameters['expandApp'] = parameters['expandApp'];
            }

            queryParameters['expandApp'] = this.transformParameter(queryParameters['expandApp']);





            path = path.replace('{userId}', userId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Get list of user's relations
    * @method
    * @name CoreApi#adminGetUserRelations
    */
    adminGetUserRelations(
            userId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  relations?: Array<{
  relatedId: string
;
  type: string
;
  user?: UserPublicProfile
;
}
>
;
}
  >
    {
        let path = '/admin/users/{userId}/relations';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Add new relation to user
    * @method
    * @name CoreApi#adminAddUserRelation
    */
    adminAddUserRelation(
            
            userId: string
,
        parameters: {
        } ,
                        body:
                                                        {
                              relatedId: string
;
                              type: string
;
                            }
,
    ): Promise< {
}
  >
    {
        let path = '/admin/users/{userId}/relations';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminDeleteUserRelation
    */
    adminDeleteUserRelation(
            userId: string
,
            relatedId: string
,
            
        parameters: {
                        'deleteOpponentRelation'?: boolean
,
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/admin/users/{userId}/relations/{relatedId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{relatedId}', relatedId.toString());




            if(typeof parameters['deleteOpponentRelation'] !== 'undefined'){
                queryParameters['deleteOpponentRelation'] = parameters['deleteOpponentRelation'];
            }

            queryParameters['deleteOpponentRelation'] = this.transformParameter(queryParameters['deleteOpponentRelation']);





        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminGetUserProfile
    */
    adminGetUserProfile(
            userId: string
,
        parameters: {
        }  = {},
    ): Promise< UserProfile
  >
    {
        let path = '/admin/users/{userId}/profile';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUpdateUserProfile
    */
    adminUpdateUserProfile(
            
            userId: string
,
        parameters: {
        } ,
                        body:
                                                        UserProfileUpdate_Admin
,
    ): Promise< UserProfile
  >
    {
        let path = '/admin/users/{userId}/profile';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserAddresses
    */
    adminUserAddresses(
            
            userId: string
,
        parameters: {
                        'type'?: Array<string
>
,
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  street?: string
;
  number?: string|number
;
  conscription_number?: string|number
;
  city?: string
;
  zip?: string
;
  country?: string
;
  name?: string
;
  surname?: string
;
  company?: string
;
  type?: string
;
  location?: GeoLocation
;
}
>
;
}
  >
    {
        let path = '/admin/users/{userId}/addresses';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            if(typeof parameters['type'] !== 'undefined'){
                queryParameters['type'] = parameters['type'];
            }

            queryParameters['type'] = this.transformParameter(queryParameters['type']);





            path = path.replace('{userId}', userId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserAddressesCreate
    */
    adminUserAddressesCreate(
            
            userId: string
,
        parameters: {
        } ,
                        body:
                                                        AddressCreateOrUpdate
,
    ): Promise< Address
  >
    {
        let path = '/admin/users/{userId}/addresses';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserAddressesGet
    */
    adminUserAddressesGet(
            userId: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  street?: string
;
  number?: string|number
;
  conscription_number?: string|number
;
  city?: string
;
  zip?: string
;
  country?: string
;
  name?: string
;
  surname?: string
;
  company?: string
;
  type?: string
;
  location?: GeoLocation
;
}
  >
    {
        let path = '/admin/users/{userId}/addresses/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserAddressesUpdate
    */
    adminUserAddressesUpdate(
            
            userId: string
,
            itemId: string
,
        parameters: {
        } ,
                        body:
                                                        AddressCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  street?: string
;
  number?: string|number
;
  conscription_number?: string|number
;
  city?: string
;
  zip?: string
;
  country?: string
;
  name?: string
;
  surname?: string
;
  company?: string
;
  type?: string
;
  location?: GeoLocation
;
}
  >
    {
        let path = '/admin/users/{userId}/addresses/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserAddressesDelete
    */
    adminUserAddressesDelete(
            userId: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/admin/users/{userId}/addresses/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserBankAccounts
    */
    adminUserBankAccounts(
            userId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
>
;
}
  >
    {
        let path = '/admin/users/{userId}/bank-accounts';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserBankAccountsCreate
    */
    adminUserBankAccountsCreate(
            
            userId: string
,
        parameters: {
        } ,
                        body:
                                                        BankAccountCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
  >
    {
        let path = '/admin/users/{userId}/bank-accounts';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserBankAccountsGet
    */
    adminUserBankAccountsGet(
            userId: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
  >
    {
        let path = '/admin/users/{userId}/bank-accounts/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserBankAccountsUpdate
    */
    adminUserBankAccountsUpdate(
            
            userId: string
,
            itemId: string
,
        parameters: {
        } ,
                        body:
                                                        BankAccountCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
  >
    {
        let path = '/admin/users/{userId}/bank-accounts/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserBankAccountsDelete
    */
    adminUserBankAccountsDelete(
            userId: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/admin/users/{userId}/bank-accounts/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserPpoRelations
    */
    adminUserPpoRelations(
            userId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  ppoRelations?: Array<PpoRelation
>
;
}
  >
    {
        let path = '/admin/users/{userId}/ppo-relations';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserCustomFields
    */
    adminUserCustomFields(
            userId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
>
;
}
  >
    {
        let path = '/admin/users/{userId}/custom';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserCustomFieldsCreate
    */
    adminUserCustomFieldsCreate(
            
            userId: string
,
        parameters: {
        } ,
                        body:
                                                        CustomFieldCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
  >
    {
        let path = '/admin/users/{userId}/custom';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserCustomFieldsGet
    */
    adminUserCustomFieldsGet(
            userId: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
  >
    {
        let path = '/admin/users/{userId}/custom/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserCustomFieldsUpdate
    */
    adminUserCustomFieldsUpdate(
            
            userId: string
,
            itemId: string
,
        parameters: {
        } ,
                        body:
                                                        CustomFieldCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
  >
    {
        let path = '/admin/users/{userId}/custom/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserCustomFieldsDelete
    */
    adminUserCustomFieldsDelete(
            userId: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/admin/users/{userId}/custom/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserSportOrgs
    */
    adminUserSportOrgs(
            userId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  org_id?: string
;
  org_name?: string
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  source_ppo?: string
;
  source_ppo_name?: string
;
  sector?: Sector
;
  competence_type?: string
;
  date_from?: string
;
  date_to?: string|null
;
  transfer_type?: string
;
  valid_to?: string|null
;
}
>
;
}
  >
    {
        let path = '/admin/users/{userId}/sport-orgs';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserGetSportOrg
    */
    adminUserGetSportOrg(
            userId: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  org_id?: string
;
  org_name?: string
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  source_ppo?: string
;
  source_ppo_name?: string
;
  sector?: Sector
;
  competence_type?: string
;
  date_from?: string
;
  date_to?: string|null
;
  transfer_type?: string
;
  valid_to?: string|null
;
}
  >
    {
        let path = '/admin/users/{userId}/sport-orgs/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserSportExpertOrgs
    */
    adminUserSportExpertOrgs(
            userId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  org_id?: string
;
  org_profile_id?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  sector?: Sector
;
  competence_type?: string
;
  legal_form?: string
;
  license_id?: string
;
  license?: UserLicense
;
  date_from?: string
;
  date_to?: string|null
;
  valid_to?: string|null
;
}
>
;
}
  >
    {
        let path = '/admin/users/{userId}/sport-expert-orgs';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserGetSportExpertOrg
    */
    adminUserGetSportExpertOrg(
            userId: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  org_id?: string
;
  org_profile_id?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  sector?: Sector
;
  competence_type?: string
;
  legal_form?: string
;
  license_id?: string
;
  license?: UserLicense
;
  date_from?: string
;
  date_to?: string|null
;
  valid_to?: string|null
;
}
  >
    {
        let path = '/admin/users/{userId}/sport-expert-orgs/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserAwards
    */
    adminUserAwards(
            userId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  org_profile_id?: string
;
  sport?: string
;
  sport_sector?: string
;
  award_type?: string
;
  issue_date?: string
;
  issued_by?: string
;
  created_datetime?: string
;
  org_id?: string
;
}
>
;
}
  >
    {
        let path = '/admin/users/{userId}/awards';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserAwardsGet
    */
    adminUserAwardsGet(
            userId: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  sport?: string
;
  sport_sector?: string
;
  award_type?: string
;
  issue_date?: string
;
  issued_by?: string
;
  created_datetime?: string
;
  org_id?: string
;
}
  >
    {
        let path = '/admin/users/{userId}/awards/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserAwardsUpdate
    */
    adminUserAwardsUpdate(
            
            userId: string
,
            itemId: string
,
        parameters: {
        } ,
                        body:
                                                        UserAwardUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  sport?: string
;
  sport_sector?: string
;
  award_type?: string
;
  issue_date?: string
;
  issued_by?: string
;
  created_datetime?: string
;
  org_id?: string
;
}
  >
    {
        let path = '/admin/users/{userId}/awards/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserExternalProfiles
    */
    adminUserExternalProfiles(
            userId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  external_profiles?: Array<UserExternalProfile
>
;
}
  >
    {
        let path = '/admin/users/{userId}/external-profiles';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserExternalProfile
    */
    adminUserExternalProfile(
            userId: string
,
            externalService: string
,
        parameters: {
        }  = {},
    ): Promise< UserExternalProfile
  >
    {
        let path = '/admin/users/{userId}/external-profiles/{externalService}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{externalService}', externalService.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserExternalProfileDelete
    */
    adminUserExternalProfileDelete(
            userId: string
,
            externalService: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/admin/users/{userId}/external-profiles/{externalService}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{externalService}', externalService.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserLicenses
    */
    adminUserLicenses(
            userId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  org_profile_id?: string
;
  type?: string
;
  name?: string
;
  documentnr?: string
;
  valid_from?: string
;
  valid_to?: string|null
;
  issued_by_po?: string
;
  verified_by_person?: string
;
  verified_by_position?: string
;
  registration_date?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  note?: string
;
  files?: Array<FileObject
>
;
}
>
;
}
  >
    {
        let path = '/admin/users/{userId}/licenses';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#adminUserLicenseGet
    */
    adminUserLicenseGet(
            userId: string
,
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  type?: string
;
  name?: string
;
  documentnr?: string
;
  valid_from?: string
;
  valid_to?: string|null
;
  issued_by_po?: string
;
  verified_by_person?: string
;
  verified_by_position?: string
;
  registration_date?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  note?: string
;
  files?: Array<FileObject
>
;
}
  >
    {
        let path = '/admin/users/{userId}/licenses/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Metoda pre ziskanie download ID suboru licencie
    * @method
    * @name CoreApi#adminUserLicenseFileGet
    */
    adminUserLicenseFileGet(
            userId: string
,
            itemId: string
,
            fileId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  url: string
;
  download_id: string
;
}
  >
    {
        let path = '/admin/users/{userId}/licenses/{itemId}/files/{fileId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{fileId}', fileId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Metoda pre vymazanie suboru licencie
    * @method
    * @name CoreApi#adminUserLicenseFileDelete
    */
    adminUserLicenseFileDelete(
            userId: string
,
            itemId: string
,
            fileId: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/admin/users/{userId}/licenses/{itemId}/files/{fileId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{itemId}', itemId.toString());




            path = path.replace('{fileId}', fileId.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#me
    */
    me(
            
            
        parameters: {
                        'externalProfile'?: string
,
                        'withGroups'?: boolean
,
        }  = {},
    ): Promise< UserPublicProfile
  >
    {
        let path = '/me';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            if(typeof parameters['externalProfile'] !== 'undefined'){
                queryParameters['externalProfile'] = parameters['externalProfile'];
            }

            queryParameters['externalProfile'] = this.transformParameter(queryParameters['externalProfile']);




              queryParameters['withGroups'] = false;

            if(typeof parameters['withGroups'] !== 'undefined'){
                queryParameters['withGroups'] = parameters['withGroups'];
            }

            queryParameters['withGroups'] = this.transformParameter(queryParameters['withGroups']);





        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meFavorites
    */
    meFavorites(
            
            
            
            
            
            
        parameters: {
                        'offset'?: number
,
                        'limit'?: number
,
                        'appId'?: string
,
                        'appItemId'?: Array<string
>
,
                        'appFavType'?: Array<string
>
,
                        'appSpace'?: Array<string
>
,
        }  = {},
    ): Promise< {
  total?: number
;
  limit?: number
;
  offset?: number
;
  next_offset?: number|null
;
  favorites?: Array<FavoriteItem
>
;
}
  >
    {
        let path = '/me/favorites';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

              queryParameters['offset'] = 0;

            if(typeof parameters['offset'] !== 'undefined'){
                queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);




              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
                queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);





            if(typeof parameters['appId'] !== 'undefined'){
                queryParameters['appId'] = parameters['appId'];
            }

            queryParameters['appId'] = this.transformParameter(queryParameters['appId']);





            if(typeof parameters['appItemId'] !== 'undefined'){
                queryParameters['appItemId'] = parameters['appItemId'];
            }

            queryParameters['appItemId'] = this.transformParameter(queryParameters['appItemId']);





            if(typeof parameters['appFavType'] !== 'undefined'){
                queryParameters['appFavType'] = parameters['appFavType'];
            }

            queryParameters['appFavType'] = this.transformParameter(queryParameters['appFavType']);





            if(typeof parameters['appSpace'] !== 'undefined'){
                queryParameters['appSpace'] = parameters['appSpace'];
            }

            queryParameters['appSpace'] = this.transformParameter(queryParameters['appSpace']);





        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meFavoritesCreateItem
    */
    meFavoritesCreateItem(
            
        parameters: {
        } ,
                        body:
                                                        {
                              favorites: Array<FavoriteItemCreate
>
;
                            }
,
    ): Promise< FavoriteItem
  >
    {
        let path = '/me/favorites';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Reset (remove) all favorite items created by appId / appSpace - based on token.
    * @method
    * @name CoreApi#meFavoritesResetForApp
    */
    meFavoritesResetForApp(
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/me/favorites';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * System checks the appId of the item, unless token belongs to privileged app (my.sportnet.online for example).
    * @method
    * @name CoreApi#meFavoritesRemoveItem
    */
    meFavoritesRemoveItem(
            favId: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/me/favorites/{favId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{favId}', favId.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meInvitations
    */
    meInvitations(
            
            
        parameters: {
                        'offset'?: number
,
                        'limit'?: number
,
        }  = {},
    ): Promise< {
  total?: number
;
  limit?: number
;
  offset?: number
;
  next_offset?: number|null
;
  invitations?: Array<InvitationUser
>
;
}
  >
    {
        let path = '/me/invitations';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

              queryParameters['offset'] = 0;

            if(typeof parameters['offset'] !== 'undefined'){
                queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);




              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
                queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);





        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meRelations
    */
    meRelations(
        parameters: {
        }  = {},
    ): Promise< {
  relations?: Array<{
  relatedId: string
;
  type: string
;
  user?: UserPublicProfile
;
}
>
;
}
  >
    {
        let path = '/me/relations';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meNewRelationInvitation
    */
    meNewRelationInvitation(
            
        parameters: {
        } ,
                        body:
                                                        {
                              email: string
;
                              type: string
;
                              relatedSportnetIds: Array<string
>
;
                            }
,
    ): Promise< {
}
  >
    {
        let path = '/me/relations-invitation';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meRelationTakeover
    */
    meRelationTakeover(
            relatedId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  access_token?: string
;
}
  >
    {
        let path = '/me/relations/{relatedId}/takeover';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{relatedId}', relatedId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meMessages
    */
    meMessages(
            
            
            
            
            
        parameters: {
                        'offset'?: number
,
                        'limit'?: number
,
                        'q'?: string
,
                        'read'?: boolean
,
                        'important'?: boolean
,
        }  = {},
    ): Promise< {
  total?: number
;
  limit?: number
;
  offset?: number
;
  next_offset?: number|null
;
  messages?: Array<Courier_Message
>
;
}
  >
    {
        let path = '/me/messages';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

              queryParameters['offset'] = 0;

            if(typeof parameters['offset'] !== 'undefined'){
                queryParameters['offset'] = parameters['offset'];
            }

            queryParameters['offset'] = this.transformParameter(queryParameters['offset']);




              queryParameters['limit'] = 100;

            if(typeof parameters['limit'] !== 'undefined'){
                queryParameters['limit'] = parameters['limit'];
            }

            queryParameters['limit'] = this.transformParameter(queryParameters['limit']);





            if(typeof parameters['q'] !== 'undefined'){
                queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);





            if(typeof parameters['read'] !== 'undefined'){
                queryParameters['read'] = parameters['read'];
            }

            queryParameters['read'] = this.transformParameter(queryParameters['read']);





            if(typeof parameters['important'] !== 'undefined'){
                queryParameters['important'] = parameters['important'];
            }

            queryParameters['important'] = this.transformParameter(queryParameters['important']);





        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meMarkAllMessagesAsRead
    */
    meMarkAllMessagesAsRead(
        parameters: {
        }  = {},
    ): Promise< {
  matchedCount?: number
;
  modifiedCount?: number
;
}
  >
    {
        let path = '/me/messages';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meUnreadMessagesCount
    */
    meUnreadMessagesCount(
        parameters: {
        }  = {},
    ): Promise< {
  count?: number
;
}
  >
    {
        let path = '/me/messages-unread-count';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meMessageById
    */
    meMessageById(
            courierMessageIds: Array<string
>
,
        parameters: {
        }  = {},
    ): Promise< Courier_Message
  >
    {
        let path = '/me/messages/{courierMessageIds}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{courierMessageIds}', courierMessageIds.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meMessageStatusChange
    */
    meMessageStatusChange(
            
            courierMessageIds: Array<string
>
,
        parameters: {
        }  = {},
                        body:
                                                        {
                              read?: boolean
;
                              important?: boolean
;
                            }
,
    ): Promise< Courier_Message
  >
    {
        let path = '/me/messages/{courierMessageIds}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{courierMessageIds}', courierMessageIds.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meUserGroups
    */
    meUserGroups(
        parameters: {
        }  = {},
    ): Promise< {
  groups?: Array<{
  ppo?: string
;
  group?: User_Group
;
  organization?: OrganizationProfile
;
}
>
;
}
  >
    {
        let path = '/me/groups';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meRequests
    */
    meRequests(
        parameters: {
        }  = {},
    ): Promise< {
  requests?: Array<Request
>
;
}
  >
    {
        let path = '/me/requests';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meInvoices
    */
    meInvoices(
        parameters: {
        }  = {},
    ): Promise< {
  invoices?: Array<InvoiceItem
>
;
}
  >
    {
        let path = '/me/invoices';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Metoda pre ziskanie download ID pdf faktury
    * @method
    * @name CoreApi#meInvoicePdf
    */
    meInvoicePdf(
            invoiceId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  download_id: string
;
}
  >
    {
        let path = '/me/invoices/{invoiceId}/pdf';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{invoiceId}', invoiceId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meAwards
    */
    meAwards(
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  org_profile_id?: string
;
  sport?: string
;
  sport_sector?: string
;
  award_type?: string
;
  issue_date?: string
;
  issued_by?: string
;
  created_datetime?: string
;
  org_id?: string
;
}
>
;
}
  >
    {
        let path = '/me/awards';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meAwardsGet
    */
    meAwardsGet(
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  sport?: string
;
  sport_sector?: string
;
  award_type?: string
;
  issue_date?: string
;
  issued_by?: string
;
  created_datetime?: string
;
  org_id?: string
;
}
  >
    {
        let path = '/me/awards/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meMembership
    */
    meMembership(
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  valid_from?: string
;
  valid_to?: string
;
  status?: UserMembershipStatus
;
}
>
;
}
  >
    {
        let path = '/me/membership';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meMembershipGet
    */
    meMembershipGet(
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  valid_from?: string
;
  valid_to?: string
;
  status?: UserMembershipStatus
;
}
  >
    {
        let path = '/me/membership/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meRegnrs
    */
    meRegnrs(
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  org_name?: string
;
  regnr?: string
;
}
>
;
}
  >
    {
        let path = '/me/regnrs';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meBios
    */
    meBios(
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  org_profile_id?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  name?: string
;
  widgets?: Array<Widget
>
;
}
>
;
}
  >
    {
        let path = '/me/bios';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meProfiles
    */
    meProfiles(
        parameters: {
        }  = {},
    ): Promise< {
  external_profiles?: Array<UserExternalProfile
>
;
}
  >
    {
        let path = '/me/profiles';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meProfilesIssf
    */
    meProfilesIssf(
        parameters: {
        }  = {},
    ): Promise< UserExternalProfile
  >
    {
        let path = '/me/profiles/issf';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meProfilesIssfConnect
    */
    meProfilesIssfConnect(
            
            
        parameters: {
                        'code': string
,
                        'redirectUri': string
,
        } ,
    ): Promise< UserExternalProfile
  >
    {
        let path = '/me/profiles/issf/connect';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            if(typeof parameters['code'] !== 'undefined'){
                queryParameters['code'] = parameters['code'];
            }

            queryParameters['code'] = this.transformParameter(queryParameters['code']);



                if(typeof parameters['code'] === 'undefined') {
                    throw new Error('Missing required parameter: code');
                }


            if(typeof parameters['redirectUri'] !== 'undefined'){
                queryParameters['redirect_uri'] = parameters['redirectUri'];
            }

            queryParameters['redirect_uri'] = this.transformParameter(queryParameters['redirect_uri']);



                if(typeof parameters['redirectUri'] === 'undefined') {
                    throw new Error('Missing required parameter: redirectUri');
                }


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meProfilesIssfToken
    */
    meProfilesIssfToken(
        parameters: {
        }  = {},
    ): Promise< {
  access_token?: string
;
  token_type?: string
;
}
  >
    {
        let path = '/me/profiles/issf/token';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meProfilesFacebookConnect
    */
    meProfilesFacebookConnect(
            
        parameters: {
        }  = {},
                        body:
                                                        {
                              access_token: string
;
                            }
,
    ): Promise< UserExternalProfile
  >
    {
        let path = '/me/profiles/facebook/connect';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meProfilesFacebookDisconnect
    */
    meProfilesFacebookDisconnect(
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/me/profiles/facebook';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meProfilesGoogleDisconnect
    */
    meProfilesGoogleDisconnect(
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/me/profiles/google';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meProfilesAppleDisconnect
    */
    meProfilesAppleDisconnect(
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/me/profiles/apple';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meProfile
    */
    meProfile(
        parameters: {
        }  = {},
    ): Promise< UserProfile
  >
    {
        let path = '/me/profile';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meProfileUpdate
    */
    meProfileUpdate(
            
        parameters: {
        } ,
                        body:
                                                        UserProfileUpdate
,
    ): Promise< UserProfile
  >
    {
        let path = '/me/profile';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meAddresses
    */
    meAddresses(
            
        parameters: {
                        'type'?: Array<string
>
,
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  street?: string
;
  number?: string|number
;
  conscription_number?: string|number
;
  city?: string
;
  zip?: string
;
  country?: string
;
  name?: string
;
  surname?: string
;
  company?: string
;
  type?: string
;
  location?: GeoLocation
;
}
>
;
}
  >
    {
        let path = '/me/addresses';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            if(typeof parameters['type'] !== 'undefined'){
                queryParameters['type'] = parameters['type'];
            }

            queryParameters['type'] = this.transformParameter(queryParameters['type']);





        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meAddressesCreate
    */
    meAddressesCreate(
            
        parameters: {
        } ,
                        body:
                                                        AddressCreateOrUpdate
,
    ): Promise< Address
  >
    {
        let path = '/me/addresses';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meAddressesGet
    */
    meAddressesGet(
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  street?: string
;
  number?: string|number
;
  conscription_number?: string|number
;
  city?: string
;
  zip?: string
;
  country?: string
;
  name?: string
;
  surname?: string
;
  company?: string
;
  type?: string
;
  location?: GeoLocation
;
}
  >
    {
        let path = '/me/addresses/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meAddressesUpdate
    */
    meAddressesUpdate(
            
            itemId: string
,
        parameters: {
        } ,
                        body:
                                                        AddressCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  street?: string
;
  number?: string|number
;
  conscription_number?: string|number
;
  city?: string
;
  zip?: string
;
  country?: string
;
  name?: string
;
  surname?: string
;
  company?: string
;
  type?: string
;
  location?: GeoLocation
;
}
  >
    {
        let path = '/me/addresses/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meAddressesDelete
    */
    meAddressesDelete(
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/me/addresses/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meBankAccounts
    */
    meBankAccounts(
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
>
;
}
  >
    {
        let path = '/me/bank-accounts';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meBankAccountsCreate
    */
    meBankAccountsCreate(
            
        parameters: {
        } ,
                        body:
                                                        BankAccountCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
  >
    {
        let path = '/me/bank-accounts';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meBankAccountsGet
    */
    meBankAccountsGet(
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
  >
    {
        let path = '/me/bank-accounts/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meBankAccountsUpdate
    */
    meBankAccountsUpdate(
            
            itemId: string
,
        parameters: {
        } ,
                        body:
                                                        BankAccountCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  IBAN?: string
;
  type?: string
;
}
  >
    {
        let path = '/me/bank-accounts/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meBankAccountsDelete
    */
    meBankAccountsDelete(
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/me/bank-accounts/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meCustomFields
    */
    meCustomFields(
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
>
;
}
  >
    {
        let path = '/me/custom';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meCustomFieldsCreate
    */
    meCustomFieldsCreate(
            
        parameters: {
        } ,
                        body:
                                                        CustomFieldCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
  >
    {
        let path = '/me/custom';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meCustomFieldsGet
    */
    meCustomFieldsGet(
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
  >
    {
        let path = '/me/custom/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meCustomFieldsUpdate
    */
    meCustomFieldsUpdate(
            
            itemId: string
,
        parameters: {
        } ,
                        body:
                                                        CustomFieldCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id: string
;
  label: string
;
  value: string
;
}
  >
    {
        let path = '/me/custom/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meCustomFieldsDelete
    */
    meCustomFieldsDelete(
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/me/custom/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Store various app data for user. Data is scoped for user, appId and appSpace. appId and appSpace is read from access token.
Data is in schema of type object (key-value, where value can be any type).

    * @method
    * @name CoreApi#meAppDataGet
    */
    meAppDataGet(
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/me/appdata';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meAppDataReplace
    */
    meAppDataReplace(
            
        parameters: {
        } ,
                        body:
                                                        {
                            }
,
    ): Promise< {
}
  >
    {
        let path = '/me/appdata';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meAppDataUpdate
    */
    meAppDataUpdate(
            
        parameters: {
        } ,
                        body:
                                                        {
                            }
,
    ): Promise< {
}
  >
    {
        let path = '/me/appdata';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'PATCH',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meSportOrgs
    */
    meSportOrgs(
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  org_id?: string
;
  org_name?: string
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  source_ppo?: string
;
  source_ppo_name?: string
;
  sector?: Sector
;
  competence_type?: string
;
  date_from?: string
;
  date_to?: string|null
;
  transfer_type?: string
;
  valid_to?: string|null
;
}
>
;
}
  >
    {
        let path = '/me/sport-orgs';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meSportOrgItem
    */
    meSportOrgItem(
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  org_id?: string
;
  org_name?: string
;
  org_profile_id?: string
;
  org_profile_name?: string
;
  source_ppo?: string
;
  source_ppo_name?: string
;
  sector?: Sector
;
  competence_type?: string
;
  date_from?: string
;
  date_to?: string|null
;
  transfer_type?: string
;
  valid_to?: string|null
;
}
  >
    {
        let path = '/me/sport-orgs/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meSportExpertOrgs
    */
    meSportExpertOrgs(
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  org_id?: string
;
  org_profile_id?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  sector?: Sector
;
  competence_type?: string
;
  legal_form?: string
;
  license_id?: string
;
  license?: UserLicense
;
  date_from?: string
;
  date_to?: string|null
;
  valid_to?: string|null
;
}
>
;
}
  >
    {
        let path = '/me/sport-expert-orgs';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meSportExpertOrgItem
    */
    meSportExpertOrgItem(
            itemId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: elementary__id
;
  status?: elementary_itemStatus
;
  org_id?: string
;
  org_profile_id?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  sector?: Sector
;
  competence_type?: string
;
  legal_form?: string
;
  license_id?: string
;
  license?: UserLicense
;
  date_from?: string
;
  date_to?: string|null
;
  valid_to?: string|null
;
}
  >
    {
        let path = '/me/sport-expert-orgs/{itemId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{itemId}', itemId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meLicenses
    */
    meLicenses(
        parameters: {
        }  = {},
    ): Promise< {
  items?: Array<{
  _id?: elementary__id
;
  org_profile_id?: string
;
  type?: string
;
  name?: string
;
  documentnr?: string
;
  valid_from?: string
;
  valid_to?: string|null
;
  issued_by_po?: string
;
  verified_by_person?: string
;
  verified_by_position?: string
;
  registration_date?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  note?: string
;
  files?: Array<FileObject
>
;
}
>
;
}
  >
    {
        let path = '/me/licenses';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meLicensesCreate
    */
    meLicensesCreate(
            
        parameters: {
        } ,
                        body:
                                                        UserLicenseCreateOrUpdate
,
    ): Promise< {
  _id?: elementary__id
;
  org_profile_id?: string
;
  type?: string
;
  name?: string
;
  documentnr?: string
;
  valid_from?: string
;
  valid_to?: string|null
;
  issued_by_po?: string
;
  verified_by_person?: string
;
  verified_by_position?: string
;
  registration_date?: string
;
  org_name?: string
;
  org_profile_name?: string
;
  note?: string
;
  files?: Array<FileObject
>
;
}
  >
    {
        let path = '/me/licenses';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * List of all user roles, in all appspaces, divided by app_id

    * @method
    * @name CoreApi#meAppSpaces
    */
    meAppSpaces(
            
        parameters: {
                        'expandApp'?: boolean
,
        }  = {},
    ): Promise< {
  apps?: Array<{
  app_id?: string
;
  app?: AppPublic
;
  appspaces?: Array<AppSpaceUser
>
;
}
>
;
}
  >
    {
        let path = '/me/appspaces';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

              queryParameters['expandApp'] = false;

            if(typeof parameters['expandApp'] !== 'undefined'){
                queryParameters['expandApp'] = parameters['expandApp'];
            }

            queryParameters['expandApp'] = this.transformParameter(queryParameters['expandApp']);





        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * User role in appspace
    * @method
    * @name CoreApi#meAppSpacesForApp
    */
    meAppSpacesForApp(
            appId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  appspaces?: Array<AppSpaceUser
>
;
}
  >
    {
        let path = '/me/appspaces/{appId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appId}', appId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * User role in ppo
    * @method
    * @name CoreApi#meAppInfoForAppSpace
    */
    meAppInfoForAppSpace(
            appId: string
,
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< AppSpaceUser
  >
    {
        let path = '/me/appspaces/{appId}/appspace/{appSpace}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appId}', appId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * List of user's access tokens
    * @method
    * @name CoreApi#meAccessTokens
    */
    meAccessTokens(
        parameters: {
        }  = {},
    ): Promise< {
  accessTokens?: Array<{
  client_id?: string
;
  token_type?: "Bearer"
;
  expires_at?: number
;
  expires_in?: number
;
  expires_at_date?: string
;
  user_id?: string
;
  scopes?: Array<string
>
;
  userinfo?: UserPublicProfile
;
  usergroups?: Array<{
  ppo?: string
;
  group?: Array<string
>
;
}
>
;
  appspace?: AppSpaceUser
;
  org_info?: Organization
;
  ppo_info?: OrganizationProfile
;
  token?: string
;
  appInfo?: AppPublic
;
}
>
;
}
  >
    {
        let path = '/me/access-tokens';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Change user password
    * @method
    * @name CoreApi#mePasswd
    */
    mePasswd(
            
        parameters: {
        }  = {},
                        body:
                                                        {
                              old_password: string
;
                              new_password: string
;
                            }
,
    ): Promise< {
}
  >
    {
        let path = '/me/passwd';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#mePhotoUpload
    */
    mePhotoUpload(
            
        parameters: {
        } ,
            form: {
                            'upload': File
,
            }
                ): Promise< {
}
  >
    {
        let path = '/me/photo';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'multipart/form-data');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            form,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Metoda pre vymazanie fotky uzivatela
    * @method
    * @name CoreApi#mePhotoDelete
    */
    mePhotoDelete(
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/me/photo';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#meDelete
    */
    meDelete(
            
        parameters: {
        }  = {},
                        body:
                                                        {
                              delete: boolean
;
                            }
,
    ): Promise< UserPublicProfile
  >
    {
        let path = '/me/delete';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Get codelist ids for this PPO
    * @method
    * @name CoreApi#listPPOCodelists
    */
    listPPOCodelists(
            appSpace: string
,
        parameters: {
        }  = {},
    ): Promise< {
  codelists?: Array<string
>
;
}
  >
    {
        let path = '/crm/{appSpace}/codelist';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Get codelist for this PPO
    * @method
    * @name CoreApi#getPPOCodelist
    */
    getPPOCodelist(
            appSpace: string
,
            codelist: string
,
        parameters: {
        }  = {},
    ): Promise< Codelist
  >
    {
        let path = '/crm/{appSpace}/codelist/{codelist}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{codelist}', codelist.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Set codelist for this PPO
    * @method
    * @name CoreApi#setPPOCodelist
    */
    setPPOCodelist(
            
            appSpace: string
,
            codelist: string
,
        parameters: {
        } ,
                        body:
                                                        {
                              items: Array<{
  value: string
;
  label?: string
;
}
>
;
                            }
,
    ): Promise< Codelist
  >
    {
        let path = '/crm/{appSpace}/codelist/{codelist}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{codelist}', codelist.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#crmSendNewUserRequestInvitation
    */
    crmSendNewUserRequestInvitation(
            
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        {
                              emails: Array<string
>
;
                              sector: string
;
                              register_matricity?: boolean
;
                            }
,
    ): Promise< {
}
  >
    {
        let path = '/crm/{appSpace}/user-request-invitation';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Endpoint pre vytvorenie objektov pre prihlaseneho usera z vyplnenej pozvanky
    * @method
    * @name CoreApi#createNewPersonRequests
    */
    createNewPersonRequests(
            
            appSpace: string
,
        parameters: {
        }  = {},
                        body:
                                                        {
                              registerMatricity?: boolean
;
                              isChildRegistration?: boolean
;
                              userProfile: UserPublicProfile
;
                              requests: Array<{
  type?: string
;
  values?: {
}
;
  callbacks?: RequestCallbacks
;
}
>
;
                            }
,
    ): Promise< {
  requests?: Array<Request
>
;
}
  >
    {
        let path = '/create-request/{appSpace}/person';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#createPoRegistryRequest
    */
    createPoRegistryRequest(
            
            appSpace: string
,
        parameters: {
        } ,
                        body:
                                                        RequestCreate_PoRegistry
,
    ): Promise< Request
  >
    {
        let path = '/create-request/{appSpace}/organization';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Endpoint pre ziskanie zoznamu ziadosti, ktore su zlucene do jednej requests group
    * @method
    * @name CoreApi#getRequestsGroup
    */
    getRequestsGroup(
            requestsGroupId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: string
;
  user?: UserPublicProfile
;
  requests?: Array<Request
>
;
}
  >
    {
        let path = '/requests-groups/{requestsGroupId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{requestsGroupId}', requestsGroupId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Obnova hesla pouzivatela
    * @method
    * @name CoreApi#oauthRestorePasswordRequest
    */
    oauthRestorePasswordRequest(
            
        parameters: {
        }  = {},
                        body:
                                                        {
                              username: string
;
                            }
,
    ): Promise< {
}
  >
    {
        let path = '/oauth/restore-password';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#oauthRestorePasswordInfo
    */
    oauthRestorePasswordInfo(
            passwordToken: string
,
        parameters: {
        }  = {},
    ): Promise< {
  username: string
;
}
  >
    {
        let path = '/oauth/restore-password/{passwordToken}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{passwordToken}', passwordToken.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Obnova hesla pouzivatela
    * @method
    * @name CoreApi#oauthRestorePassword
    */
    oauthRestorePassword(
            
            passwordToken: string
,
        parameters: {
        }  = {},
                        body:
                                                        {
                              password: string
;
                            }
,
    ): Promise< {
}
  >
    {
        let path = '/oauth/restore-password/{passwordToken}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{passwordToken}', passwordToken.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Prihlasenie pouzivatela - ziskanie tokenu

Tato metoda bud vyhlasi neznamu chybu, alebo redirectne spat na referrera, alebo redirectne na redirect uri s tokenom alebo inou chybou.

V pripade redirectu na referrera moze nastat nasledovne chyby:

- INVALID_REQUEST (XHR nie je povolene)
- INVALID_CLIENT (client_id je nezname)
- INVALID_REFERER (poziadavka bola odoslana zo blbej URL)
- INVALID_CREDENTIALS (login a heslo nie su vporiadku)
- INVALID_REDIRECT_URI (redirect uri nie je medzi zadanymi v zozname aplikacii)
- INVALID_RESPONSE_TYPE (napriklad implicit metoda nemusi byt povolena vsetkym apkam)
- TOKEN_CLIENT_ID_NOT_ALLOWED (bol poskytnuty accessToken, ale nepatri my.sportnet.online)

Referer je default https://my.sportnet.online/auth/authorize pokial nie je uvedeny iny referrer ktory sa zhoduje s tymi nastavenymi v aplikacii.

Zaroven je vrateny status 302 a nastavena location hlavicka.

    * @method
    * @name CoreApi#oauthAuthorize
    */
    oauthAuthorize(
            
            
            
            
            
            
            
            
            
        parameters: {
                        'clientId': string
,
                        'redirectUri': string
,
                        'responseType'?: string
,
                        'scope': string
,
                        'state'?: string
,
                        'appSpace'?: string
,
        } ,
            form: {
                            'username'?: string
,
                            'password'?: string
,
                            'accessToken'?: string
,
            }
                ): Promise<void>
    {
        let path = '/oauth/authorize';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Accept', 'text/html');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');


            if(typeof parameters['clientId'] !== 'undefined'){
                queryParameters['client_id'] = parameters['clientId'];
            }

            queryParameters['client_id'] = this.transformParameter(queryParameters['client_id']);



                if(typeof parameters['clientId'] === 'undefined') {
                    throw new Error('Missing required parameter: clientId');
                }


            if(typeof parameters['redirectUri'] !== 'undefined'){
                queryParameters['redirect_uri'] = parameters['redirectUri'];
            }

            queryParameters['redirect_uri'] = this.transformParameter(queryParameters['redirect_uri']);



                if(typeof parameters['redirectUri'] === 'undefined') {
                    throw new Error('Missing required parameter: redirectUri');
                }

              queryParameters['response_type'] = "token";

            if(typeof parameters['responseType'] !== 'undefined'){
                queryParameters['response_type'] = parameters['responseType'];
            }

            queryParameters['response_type'] = this.transformParameter(queryParameters['response_type']);





            if(typeof parameters['scope'] !== 'undefined'){
                queryParameters['scope'] = parameters['scope'];
            }

            queryParameters['scope'] = this.transformParameter(queryParameters['scope']);



                if(typeof parameters['scope'] === 'undefined') {
                    throw new Error('Missing required parameter: scope');
                }


            if(typeof parameters['state'] !== 'undefined'){
                queryParameters['state'] = parameters['state'];
            }

            queryParameters['state'] = this.transformParameter(queryParameters['state']);





            if(typeof parameters['appSpace'] !== 'undefined'){
                queryParameters['appSpace'] = parameters['appSpace'];
            }

            queryParameters['appSpace'] = this.transformParameter(queryParameters['appSpace']);

















        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            form,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Rovnake kontroly a chyby ako v pripade autorizacie menom a heslom

## authService=issf

- Presmerovanie na ISSF s redirect URI naspat na API, obsahuje aj parametre potrebne pre finalny redirect uri
- Ziskanie tokenu pomocou ISSF code, ak chyba, presmerovanie na redirect uri s chybou
- Ziskanie usera, porovnanie s DB, update/vytvorenie konta
- Vytvorenie sportnet access tokenu pre toto issf konto
- Redirect na finalne redirect URI

    * @method
    * @name CoreApi#oauthAuthorizeWithIssf
    */
    oauthAuthorizeWithIssf(
            
            
            
            
            
            
            
            
            
        parameters: {
                        'clientId': string
,
                        'redirectUri': string
,
                        'responseType'?: string
,
                        'scope': string
,
                        'state'?: string
,
                        'appSpace'?: string
,
                        'code'?: string
,
                        'error'?: string
,
                        'errorDescription'?: string
,
        } ,
    ): Promise<void>
    {
        let path = '/oauth/authorize/issf';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Accept', 'text/html');
        headers.append('Content-Type', 'application/json');


            if(typeof parameters['clientId'] !== 'undefined'){
                queryParameters['client_id'] = parameters['clientId'];
            }

            queryParameters['client_id'] = this.transformParameter(queryParameters['client_id']);



                if(typeof parameters['clientId'] === 'undefined') {
                    throw new Error('Missing required parameter: clientId');
                }


            if(typeof parameters['redirectUri'] !== 'undefined'){
                queryParameters['redirect_uri'] = parameters['redirectUri'];
            }

            queryParameters['redirect_uri'] = this.transformParameter(queryParameters['redirect_uri']);



                if(typeof parameters['redirectUri'] === 'undefined') {
                    throw new Error('Missing required parameter: redirectUri');
                }

              queryParameters['response_type'] = "token";

            if(typeof parameters['responseType'] !== 'undefined'){
                queryParameters['response_type'] = parameters['responseType'];
            }

            queryParameters['response_type'] = this.transformParameter(queryParameters['response_type']);





            if(typeof parameters['scope'] !== 'undefined'){
                queryParameters['scope'] = parameters['scope'];
            }

            queryParameters['scope'] = this.transformParameter(queryParameters['scope']);



                if(typeof parameters['scope'] === 'undefined') {
                    throw new Error('Missing required parameter: scope');
                }


            if(typeof parameters['state'] !== 'undefined'){
                queryParameters['state'] = parameters['state'];
            }

            queryParameters['state'] = this.transformParameter(queryParameters['state']);





            if(typeof parameters['appSpace'] !== 'undefined'){
                queryParameters['appSpace'] = parameters['appSpace'];
            }

            queryParameters['appSpace'] = this.transformParameter(queryParameters['appSpace']);





            if(typeof parameters['code'] !== 'undefined'){
                queryParameters['code'] = parameters['code'];
            }

            queryParameters['code'] = this.transformParameter(queryParameters['code']);





            if(typeof parameters['error'] !== 'undefined'){
                queryParameters['error'] = parameters['error'];
            }

            queryParameters['error'] = this.transformParameter(queryParameters['error']);





            if(typeof parameters['errorDescription'] !== 'undefined'){
                queryParameters['error_description'] = parameters['errorDescription'];
            }

            queryParameters['error_description'] = this.transformParameter(queryParameters['error_description']);





        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#oauthRevokeToken
    */
    oauthRevokeToken(
            
        parameters: {
        } ,
                        body:
                                                        {
                              access_token: string
;
                            }
,
    ): Promise< {
}
  >
    {
        let path = '/oauth/revoke';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#oauthTokenCode
    */
    oauthTokenCode(
            
        parameters: {
        } ,
                        body:
                                                        {
                              authorization_code: string
;
                            }
,
    ): Promise< {
}
  >
    {
        let path = '/oauth/token/code';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#oauthTokenClientCredentials
    */
    oauthTokenClientCredentials(
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/oauth/token/client-credentials';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');


        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#oauthTokenSocialMedia
    */
    oauthTokenSocialMedia(
            
            service: "apple" | "facebook" | "google"
,
            
        parameters: {
                        'clientId'?: string
,
        } ,
                        body:
                                                        {
                              access_token: string
;
                              response_type: "token" | "code"
;
                              scope: Array<string
>
;
                              state?: string
;
                              appSpace: string
;
                              userGroup?: string
;
                              user?: {
  name?: {
  firstName?: string
;
  lastName?: string
;
}
;
  email?: string
;
}
;
                              originIp?: string
;
                              originUserAgent?: string
;
                            }
,
    ): Promise< {
  code?: string
;
  access_token?: string
;
  token_type?: "Bearer"
;
  expires_at?: number
;
  expires_in?: number
;
  state?: string
;
  scopes?: Array<string
>
;
}
  >
    {
        let path = '/oauth/token/social/{service}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{service}', service.toString());




            if(typeof parameters['clientId'] !== 'undefined'){
                queryParameters['client_id'] = parameters['clientId'];
            }

            queryParameters['client_id'] = this.transformParameter(queryParameters['client_id']);





        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Endpoint vymiena externy access token za sportnet access token (implicit flow), alebo code access token (code flow).
Ak je response_type=code, tak sa token ziskava klasicky cez /oauth/token/code.

Aplikacia zaroven musi mat povolenie na externu autorizaciu a sluzbu, pricom aplikacia sa zistuje podla basic auth v hlavicke (client_id+client_secret).

- Dokumentacia Apple:
  - https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_rest_api/verifying_a_user
  - https://sarunw.com/posts/sign-in-with-apple-3/
  - https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/incorporating_sign_in_with_apple_into_other_platforms
    - zasielanie mena a priezviska uzivatela (kapitola: Handle the Response)
- Facebook: Klasika - overenie usera podla tokenu (graph api /me napriklad)
- Google:
  - https://developers.google.com/identity/sign-in/android/backend-auth#using-a-google-api-client-library

Request matchne na existujuceho pouzivatela, alebo vytvori noveho pouzivatela podla dat z tej-ktorej sluzby.

POZOR: Treba doplnit aj requesty pre my.sportnet.online a moznosti prepojenia konta na tieto sluzby.

    * @method
    * @name CoreApi#oauthTokenExternal
    */
    oauthTokenExternal(
            
            service: "apple" | "facebook" | "google" | "sme" | "smejwt"
,
        parameters: {
        } ,
                        body:
                                                        {
                              access_token: string
;
                              response_type: "token" | "code"
;
                              scope: Array<string
>
;
                              state?: string
;
                              appSpace: string
;
                              userGroup?: string
;
                              user?: {
  name?: {
  firstName?: string
;
  lastName?: string
;
}
;
  email?: string
;
}
;
                              originIp?: string
;
                              originUserAgent?: string
;
                            }
,
    ): Promise< {
  code?: string
;
  access_token?: string
;
  token_type?: "Bearer"
;
  expires_at?: number
;
  expires_in?: number
;
  state?: string
;
  scopes?: Array<string
>
;
}
  >
    {
        let path = '/oauth/token/external/{service}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






            path = path.replace('{service}', service.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Získanie informácie o tokene.

V tele requestu môže byť zadané `app_id` a `app_space`.
Ak sú zadané, client_id v tokene sa kontroluje aj voči povoleným nadradeným aplikáciam autentifikovaného clienta.

Príklad: Máme token vygenerovaný aplikáciou `CSM`. V tele requestu okrem access_token zadám aj `app_id=CSM`.
Access token je platný ak je request autentifikovaný klientom `CSM`, alebo ak je autentifikovaný klientom,
ktorý mý nastavené ako nadradenú aplikáciu `CSM` (teda napríklad `mediamanager`).

    * @method
    * @name CoreApi#oauthTokenInfo
    */
    oauthTokenInfo(
            
        parameters: {
        } ,
                        body:
                                                        {
                              access_token: string
;
                              app_id?: string
;
                              app_space?: string
;
                              org_info?: boolean
;
                            }
,
    ): Promise< TokenInfo
  >
    {
        let path = '/oauth/tokeninfo';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Registracia noveho usera
    * @method
    * @name CoreApi#oauthNewRegistration
    */
    oauthNewRegistration(
            
        parameters: {
        } ,
                        body:
                                                        {
                              username: string
;
                              password: string
;
                              idnr?: string|null
;
                              name: string
;
                              surname: string
;
                              titles?: {
  before: string
;
  after: string
;
}
;
                              phone?: string
;
                              email: elementary_email
;
                              sex?: elementary_sex
;
                              birthdate?: string|null
;
                              birthplace?: string
;
                              birthcountry?: string
;
                              shoesize?: string
;
                              dresssize?: string
;
                              nationality?: string
;
                              citizenship?: string
;
                            }
,
    ): Promise< UserPublicProfile
  >
    {
        let path = '/oauth/registration';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * Spracuje dáta poslané z kontaktného formulára z public časti stránky sportnet.online.

    * @method
    * @name CoreApi#webContactFormRequest
    */
    webContactFormRequest(
            
        parameters: {
        } ,
                        body:
                                                        {
                              firstName: string
;
                              lastName: string
;
                              email: elementary_email
;
                              tel: string
;
                              serviceType: string
;
                              message: string
;
                            }
,
    ): Promise< {
}
  >
    {
        let path = '/request/web-contact-form';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');






        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#getDownloaderFile
    */
    getDownloaderFile(
            downloadId: string
,
        parameters: {
        }  = {},
    ): Promise< File
  >
    {
        let path = '/downloader/{download_id}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Accept', 'application/pdf');
        headers.append('Accept', 'image/jpeg');
        headers.append('Accept', 'image/png');
        headers.append('Accept', 'image/gif');
        headers.append('Accept', 'text/plain');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{download_id}', downloadId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Get Public User Profile
    * @method
    * @name CoreApi#getPublicUserProfile
    */
    getPublicUserProfile(
            userId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id?: string
;
  name?: string
;
  surname?: string
;
  family_name?: string
;
  titles?: {
  before?: string
;
  after?: string
;
}
;
  deleteAfter?: string
;
  birthyear?: number
;
  age?: number
;
  sex?: elementary_sex
;
  citizenship?: string
;
}
  >
    {
        let path = '/users/{userId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Can be used as image src
    * @method
    * @name CoreApi#getPublicUserImage
    */
    getPublicUserImage(
            userId: string
,
        parameters: {
        }  = {},
    ): Promise< File
  >
    {
        let path = '/user-photo/{userId}.jpg';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Accept', 'image/png');
        headers.append('Accept', 'image/jpeg');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * Can be used as image src
    * @method
    * @name CoreApi#getUserImage
    */
    getUserImage(
            userId: string
,
            token: string
,
        parameters: {
        }  = {},
    ): Promise< File
  >
    {
        let path = '/users/{userId}/photo/{token}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Accept', 'image/png');
        headers.append('Accept', 'image/jpeg');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{userId}', userId.toString());




            path = path.replace('{token}', token.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

    /**
    * 
    * @method
    * @name CoreApi#processInvitationResponseToGroups
    */
    processInvitationResponseToGroups(
            invitationId: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/crm-group-invitation/{invitationId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');


            path = path.replace('{invitationId}', invitationId.toString());




        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }

}

export default new CoreApi();
export { CoreApi };
