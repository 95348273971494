import * as React from 'react';
import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField';
import Header from '@sportnet/ui/Header';
import Input from '@sportnet/ui/Input';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import SmarttagTags from '@sportnet/ui/SmarttagsControl/SmarttagTags';
import Text from '@sportnet/ui/Text';
import __ from '../../utilities/getText';

interface OwnProps {
  isOpen: boolean;
  title: string;
  availableKeys: string[];
  onSubmit: (val: { key: string }) => void;
  onCancel: () => void;
}

const SmarttagKeyPrompt: React.FC<OwnProps> = ({
  isOpen,
  title,
  availableKeys,
  onSubmit,
  onCancel,
}) => {
  const [selectedKey, setSelectedKey] = React.useState('');
  const [otherKey, setOtherKey] = React.useState('');

  const handleCloseConfirmation = () => {
    setSelectedKey('');
    setOtherKey('');
  };

  React.useEffect(() => {
    if (isOpen) {
      setSelectedKey('');
      setOtherKey('');
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} handleClose={onCancel} size="xs">
      {!!isOpen && (
        <>
          <ModalContent>
            <Header withSeparator>{title}</Header>
            {availableKeys.length > 0 ? (
              <SmarttagTags
                showWhitespace
                items={availableKeys.map((key) => ({
                  key: '',
                  values: [{ key }],
                }))}
                onClickTag={(key: string, value: string) => {
                  setSelectedKey(value);
                }}
              />
            ) : (
              <Text faded>{__('Žiadne tagy')}</Text>
            )}
            <br />
            <br />
            <FormField
              label={__('Iný tag')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setOtherKey(e.target.value);
              }}
              value={otherKey}
            >
              <input />
              <Input.Button
                primary
                disabled={!otherKey}
                onClick={() => {
                  setSelectedKey(otherKey);
                }}
                icon="check"
              />
            </FormField>
          </ModalContent>
          <ModalActions>
            <div />
            <Button onClick={onCancel}>{__('Zavrieť')}</Button>{' '}
          </ModalActions>

          <Modal
            isOpen={!!selectedKey}
            handleClose={handleCloseConfirmation}
            size="xs"
          >
            <ModalContent>
              <Header>{__('Potvrďte presun do vybraného tagu')}</Header>
              <SmarttagTags
                showWhitespace
                items={[{ key: '', values: [{ key: selectedKey }] }]}
              />
            </ModalContent>
            <ModalActions>
              <Button onClick={handleCloseConfirmation}>{__('Späť')}</Button>
              <Button
                primary
                onClick={() => {
                  handleCloseConfirmation();
                  onSubmit({
                    key: selectedKey,
                  });
                }}
              >
                {__('Potvrdiť')}
              </Button>
            </ModalActions>
          </Modal>
        </>
      )}
    </Modal>
  );
};

export default SmarttagKeyPrompt;
