export const smarttagsSelectionToStandardFormat = (selection: {
  [key: string]: {
    [key: string]: boolean;
  };
}) =>
  Object.keys(selection).map((key) => ({
    key,
    values: Object.keys(selection[key]).map((valueKey) => ({ key: valueKey })),
  }));

export default smarttagsSelectionToStandardFormat;
