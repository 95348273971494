export const mapSmarttagSelection = (
  callackfn: (value: { key: string; value: string }) => any,
  selection: Readonly<{
    [key: string]: Readonly<{
      [key: string]: boolean;
    }>;
  }>
) =>
  Object.keys(selection).reduce(
    (acc: Array<{ [key: string]: { from: string; to: string } }>, key) => {
      Object.keys(selection[key]).forEach((value) => {
        acc.push(callackfn({ key, value }));
      });
      return acc;
    },
    []
  );

export default mapSmarttagSelection;
