import { Smarttag } from '../library/Common';

export const sortSmarttags = (smarttags: Smarttag[]) => {
  return smarttags.slice().sort((a, b) => {
    const s = (a.system ? 0 : 1) - (b.system ? 0 : 1);
    if (s !== 0) {
      return s;
    }
    return a.key.localeCompare(b.key);
  });
};

export default sortSmarttags;
