const config = {
  APP_ID: 'tagmanager',
  API_URL:
    process.env.REACT_APP_API_BASE_URL ||
    'https://tagmanager.sportnet.online/api/v3',
  SPORTNET_API_URL:
    process.env.REACT_APP_SPORTNET_API_BASE_URL ||
    'https://api.sportnet.online/v1',
};

export default config;
