import CoreApi from './api/CoreApi';
import TagmanagerApi from './api/TagmanagerApi';
import config from './config';

if (config.SPORTNET_API_URL) {
  CoreApi.setBaseUrl(String(config.SPORTNET_API_URL));
} else {
  throw Error('REACT_APP_SPORTNET_API_BASE_URL not set');
}

if (config.API_URL) {
  TagmanagerApi.setBaseUrl(String(config.API_URL));
} else {
  throw Error('REACT_APP_API_BASE_URL not set');
}
