import { rem } from 'polished';
import EventStack from '@sportnet/ui/EventStack';
import React from 'react';
import ReactDOM from 'react-dom';
import styled, { createGlobalStyle, css } from 'styled-components';
import uniqueId from 'lodash.uniqueid';

const BASE_ZINDEX = 100;
let zIndexCounter = 0;

const Backdrop = styled('div')<{ $isOpen?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
  ${({ $isOpen }) =>
    $isOpen
      ? css`
          opacity: 1;
        `
      : css`
          pointer-events: none;
          opacity: 0;
        `};
`;

const ZIndexOverlay = styled('div')<{ $isOpen?: boolean; $zIndex: number }>`
  position: fixed;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  ${({ $isOpen }) =>
    !$isOpen &&
    css`
      pointer-events: none;
      overflow: hidden;
    `};
  z-index: ${({ $zIndex }) => $zIndex};
`;

interface IPositioner {
  $size?: string;
  $isOpen?: boolean;
  $centered?: boolean;
}
const Positioner = styled.div<IPositioner>`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => rem(theme.grid.gutterWidth)};
  z-index: 100;
  transition: all 0.3s;

  ${({ $centered }) =>
    $centered &&
    css`
      position: relative;
      margin: auto;
    `}

  ${({ $size, theme }) => {
    if ($size === 'fullscreen') {
      return css`
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        padding: 0;
        position: absolute;
      `;
    } else if ($size === 'xs') {
      return css`
        top: 0;
        max-width: ${rem(350 + theme.grid.gutterWidth * 2)};
        width: 100%;
      `;
    } else if ($size === 'full') {
      return css`
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
      `;
    }
    return css`
      top: 0;
      max-width: ${rem(800 + theme.grid.gutterWidth * 2)};
      width: 100%;
    `;
  }};

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          transform: translateY(0);
          visibility: visible;
          opacity: 1;
        `
      : css`
          transform: translateY(-50px);
          visibility: hidden;
          opacity: 0;
        `};
`;

export const ModalContent = styled.div`
  ${({ theme }) => css`
    padding: ${rem(theme.grid.gutterWidth * 2)} ${rem(theme.grid.gutterWidth)};
  `}
`;

const Wrapper = styled.div<{ $size?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background: #fff;
  border-radius: ${rem(4)};
  box-shadow: 0 0 ${rem(40)} 0 rgba(0, 0, 0, 0.2);

  ${({ $size }) =>
    $size === 'fullscreen' &&
    css`
      border-radius: 0;
      box-shadow: none;
      & > ${ModalContent} {
        overflow-y: auto;
      }
    `}
`;

export const ModalActions = styled.div`
  border-top: ${rem(1)} solid #eee;
  padding: ${({ theme }) => rem(theme.grid.gutterWidth)};
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
`;

let element: HTMLDivElement | null = null;
let modalRoot: HTMLElement;
if (typeof document !== 'undefined') {
  const existingRoot = document.getElementById('modal-root');
  if (!existingRoot) {
    element = document.createElement('div');
    element.setAttribute('id', 'modal-root');
    modalRoot = document.body.appendChild(element);
  } else {
    modalRoot = existingRoot;
  }
}

const bodyOverflowHiddenClass = 'modal-opened';

interface OwnProps {
  isOpen: boolean;
  size?: string; // m | xs | full | fullscreen
  centered?: boolean;
  forceZindex?: number;
  handleClose: () => void;
  children?: React.ReactNode;
}

type Props = OwnProps;

const GlobalStyle = createGlobalStyle`
  body.${bodyOverflowHiddenClass} {
    overflow: hidden;
  }
`;

const Modal: React.FC<Props> = ({
  isOpen,
  handleClose,
  forceZindex,
  centered,
  size = 'm',
  children,
  ...restProps
}) => {
  const [zIndex, setZIndex] = React.useState(0);
  const [isOpened, setIsOpened] = React.useState(false);

  const uidRef = React.useRef<string>(uniqueId());
  const el = React.useRef<HTMLDivElement | null>(
    typeof document !== 'undefined' ? document.createElement('div') : null
  );

  React.useEffect(() => {
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    };

    const init = () => {
      if (typeof document !== 'undefined') {
        document.body.classList.add(
          `${bodyOverflowHiddenClass}-${uidRef.current}`
        );
        setZIndex(++zIndexCounter);
        EventStack.add(document.body, 'keydown', handleKeyDown);
        setIsOpened(true);
      }
    };

    const destroy = () => {
      if (typeof document !== 'undefined') {
        document.body.classList.remove(
          `${bodyOverflowHiddenClass}-${uidRef.current}`
        );
        EventStack.remove(document.body, 'keydown', handleKeyDown);
        setIsOpened(false);
      }
    };

    modalRoot.appendChild(el.current!);

    if (isOpen) {
      init();
    } else {
      destroy();
    }

    const modalElement = el.current!;

    return () => {
      modalRoot.removeChild(modalElement);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return ReactDOM.createPortal(
    <ZIndexOverlay
      $isOpen={isOpened}
      $zIndex={forceZindex || zIndex + BASE_ZINDEX}
    >
      <Positioner $centered={centered} $size={size} $isOpen={isOpened}>
        <Wrapper $size={size} {...restProps}>
          {isOpened && children}
        </Wrapper>
      </Positioner>
      <Backdrop $isOpen={isOpened} onClick={handleClose} />
      <GlobalStyle />
    </ZIndexOverlay>,
    el.current!
  );
};

export default Modal;
