import './setupApis';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { createRoot } from 'react-dom/client';
import { theme } from './theme/theme';
import App from './pages/App';
import GlobalStyle from './theme/globalStyles';
import React from 'react';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container!);

root.render(
  <ThemeProvider theme={theme}>
    <Router>
      <App />
      <GlobalStyle />
    </Router>
  </ThemeProvider>
);
