import * as React from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { SmartTagKeyValuePair } from '../../../library/Common';
import { darken, rem } from 'polished';
import Checkbox from '@sportnet/ui/Checkbox';
import Icon from '@sportnet/ui/Icon';
import WithWhitespace from '../../../components/WithWhitespace';
import __ from '../../../utilities/getText';
import styled, { css } from 'styled-components';

const ROW_HEIGHT = 34;

const LIST_STYLES = {
  outline: 0,
};

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  outline: none;
  width: 300;
`;

const ColumnContent = styled.div`
  height: 100%;
  display: flex;
  flex: 1 0 0;
  width: 300;
  border-right: 1px solid ${({ theme }) => theme.separatorColor};
`;

const LabelValue = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Header = styled.div`
  background: ${({ theme }) => theme.table.headerBg};
  display: flex;
  font-size: 0.8em;
  font-weight: bold;
  flex-direction: row;
  align-items: center;
  height: ${rem(ROW_HEIGHT)};
  padding: 0 ${({ theme }) => rem(theme.grid.gutterWidth)};
  border-right: 1px solid ${({ theme }) => theme.separatorColor};
  border-bottom: 1px solid ${({ theme }) => theme.separatorColor};
`;

// TODO: dostylovat, nefunguje pretazenie Header
const SubHeader = styled.div`
  cursor: pointer;
  background: ${({ theme }) => theme.table.headerBg};
  display: flex;
  font-size: 0.8em;
  font-weight: bold;
  flex-direction: row;
  align-items: center;
  height: ${rem(ROW_HEIGHT)};
  padding: 0 ${({ theme }) => rem(theme.grid.gutterWidth)};
  border-right: 1px solid ${({ theme }) => theme.separatorColor};
  border-bottom: 1px solid ${({ theme }) => theme.separatorColor};
`;

const Flex = styled('div')`
  flex: 1 0 0;
`;

const resetButtonStyles = css`
  border: none;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditButton = styled.button`
  ${resetButtonStyles}
  width: ${rem(24)};
  height: ${rem(24)};
  border-radius: ${rem(2)};
  &:hover {
    background-color: white;
  }
`;

const EditIcon = styled(Icon)`
  opacity: 0;
  transition: 0.1s all ease;
  .active {
    opacity: 1;
  }
`;

const RowLabel = styled('label')<{
  $active: boolean;
}>`
  cursor: pointer;
  padding: 0 ${({ theme }) => rem(theme.grid.gutterWidth)};
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.8em;

  &:hover {
    background: ${({ theme }) => theme.table.hover};
  }

  &:hover .editIcon {
    opacity: 1;
  }
  &:focus,
  &:active {
    background: ${({ theme }) => darken(0.1, theme.table.hover)};
  }
  ${({ $active = false }) =>
    $active &&
    css`
      background: ${({ theme }) => theme.table.activeRowBg};
      color: ${({ theme }) => theme.table.activeRow};
    `}
`;

const ActionButton = styled.button`
  ${resetButtonStyles};
  cursor: pointer;
  margin-left: ${({ theme }) => rem(theme.grid.gutterWidth / 2)};
`;

export interface Item {
  _id: string;
  key: string;
}

interface OwnProps {
  className?: string;
  items: ReadonlyArray<Item>;
  smarttagKey: string;
  onSelect: (smarttags: SmartTagKeyValuePair[]) => void;
  onDeselect: (smarttag: SmartTagKeyValuePair[]) => void;
  onDeselectAllFromColumn: (key: string) => void;
  selectedValues: {
    [key: string]: boolean;
  };
  onClickAdd: (val: { key: string }) => void;
  onClickEdit: (val: { key: string; value: string }) => void;
  onClickSetSystemTag: (key: string) => void;
  onClickUnsetSystemTag: (key: string) => void;
  isSystemTag: boolean;
}

const Column: React.FC<OwnProps> = ({
  className,
  items,
  smarttagKey,
  onSelect,
  onDeselect,
  onDeselectAllFromColumn,
  selectedValues,
  onClickAdd,
  onClickEdit,
  onClickSetSystemTag,
  onClickUnsetSystemTag,
  isSystemTag,
}) => {
  const handleChangeCheckbox = (
    item: Item,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.checked) {
      onSelect([{ key: smarttagKey, value: item.key }]);
    } else {
      onDeselect([{ key: smarttagKey, value: item.key }]);
    }
  };

  const handleEdit = (val: { key: string; value: string }) => {
    onClickEdit(val);
  };

  const handleClickLockOpen = () => onClickSetSystemTag(smarttagKey);

  const handleClickLock = () => onClickUnsetSystemTag(smarttagKey);

  const handleAdd = () => {
    onClickAdd({ key: smarttagKey });
  };

  const rowRenderer = ({ index, key, style }: any) => {
    const item = items[index];
    const isChecked = item.key in selectedValues;

    return (
      <RowLabel key={item._id || key} style={style} $active={isChecked}>
        <Checkbox
          checked={isChecked}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            e.stopPropagation();
            handleChangeCheckbox(item, e);
          }}
        />
        <LabelValue title={item.key}>
          <WithWhitespace>{item.key}</WithWhitespace>
        </LabelValue>

        <Flex />
        <EditButton
          onClick={(event: any) => {
            event.stopPropagation();
            const [key] = item._id.split(':');
            event.preventDefault();
            handleEdit({ key: key, value: item.key }); //item.key je value selectnuteho
          }}
        >
          <EditIcon className="editIcon" name="pencil" />
        </EditButton>
      </RowLabel>
    );
  };

  const handleChangeBulkCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      onSelect(items.map((item) => ({ key: smarttagKey, value: item.key })));
    } else {
      onDeselectAllFromColumn(smarttagKey);
    }
  };

  const renderLock = () => {
    if (isSystemTag) {
      return (
        <ActionButton onClick={handleClickLock}>
          <Icon name="lock" />
        </ActionButton>
      );
    }
    return (
      <ActionButton onClick={handleClickLockOpen}>
        <Icon name="lock-open" />
      </ActionButton>
    );
  };

  const isAllChecked = items.length === Object.keys(selectedValues).length;
  return (
    <Wrapper className={className}>
      <Header>
        <WithWhitespace>{smarttagKey}</WithWhitespace>
        <Flex />
        <ActionButton name="plus" onClick={handleAdd}>
          <Icon name="plus" />
        </ActionButton>
        {renderLock()}
      </Header>
      <SubHeader>
        <Checkbox checked={isAllChecked} onChange={handleChangeBulkCheckbox} />
        <LabelValue>
          {isAllChecked ? __('Odznačiť všetko') : __('Označiť všetko')} (
          {items.length})
        </LabelValue>
      </SubHeader>
      <ColumnContent>
        <AutoSizer>
          {(autoSizerProps: any) => {
            const { width, height } = autoSizerProps;
            return (
              <List
                style={LIST_STYLES}
                height={height}
                rowCount={items.length}
                rowHeight={ROW_HEIGHT}
                rowRenderer={rowRenderer}
                width={width}
                selectedValues={selectedValues} // this prop will trigger List rerender
              />
            );
          }}
        </AutoSizer>
      </ColumnContent>
    </Wrapper>
  );
};

export default Column;
