import { styled } from 'styled-components';
import { transparentize } from 'polished';
import Loader from '@sportnet/ui/Loader';

const LoaderOverflowWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: ${transparentize(0.3, '#fff')};
  z-index: 93;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderOverflow: React.FC = () => (
  <LoaderOverflowWrapper>
    <Loader size="xl" />
  </LoaderOverflowWrapper>
);

export default LoaderOverflow;
