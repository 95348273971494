import { Theme, getTheme } from '@sportnet/ui/Themes/baseTheme';

export type ITheme = Theme;

export const theme = getTheme({
  fontFamily: "'Barlow', sans-serif",
  textColor: '#000',
  grid: {
    gutterWidth: 12,
  },
  color: {
    fadedText: '#888',
  },
});
