export type ThenArg<T> = T extends Promise<infer U>
    ? U
    : T extends (...args: any[]) => Promise<infer U>
    ? U
    : T;


type QueryParameters = { [key: string]: string | number | string[] | number[] | boolean | boolean[] | undefined };

export type PublicSmartTagResponse = {
  _id: string
;
  appId: Array<string
>
;
  appSpace: string
;
  identifier: string
;
  key: string
;
  value: string
;
  meta?: {
  title?: string
;
  description?: string
;
  ogImage?: {
  public_url: string
;
  media_url: string
;
  name?: string
;
  filepath?: string
;
  dimenstions?: {
  W: number
;
  H: number
;
  X: number
;
  Y: number
;
}
;
}
;
}
;
  url?: string
;
}
;

export type SmartTagResponse = {
  _id: string
;
  appId: Array<string
>
;
  appSpace: string
;
  identifier: string
;
  key: string
;
  value: string
;
  meta?: {
  title?: string
;
  description?: string
;
  ogImage?: {
  public_url: string
;
  media_url: string
;
  name?: string
;
  filepath?: string
;
  dimenstions?: {
  W: number
;
  H: number
;
  X: number
;
  Y: number
;
}
;
}
;
}
;
  url?: string
;
  content?: Array<{
}
>
;
}
;

export type SmartTagInsert = {
  key: string
;
  values: Array<{
  key: string
;
}
>
;
}
;

export type ValuesList = {
  values: Array<{
  key: string
;
  title?: string
;
}
>
;
}
;

export type SmarttagsList = {
  smarttags: Array<{
  _id: string
;
  appId: Array<string
>
;
  appSpace: string
;
  identifier: string
;
  key: string
;
  value: string
;
  meta?: {
  title?: string
;
  description?: string
;
  ogImage?: {
  public_url: string
;
  media_url: string
;
  name?: string
;
  filepath?: string
;
  dimenstions?: {
  W: number
;
  H: number
;
  X: number
;
  Y: number
;
}
;
}
;
}
;
  url?: string
;
  content?: Array<{
}
>
;
}
>
;
}
;

export type PublicSmarttagsList = {
  smarttags: Array<{
  _id: string
;
  appId: Array<string
>
;
  appSpace: string
;
  identifier: string
;
  key: string
;
  value: string
;
  meta?: {
  title?: string
;
  description?: string
;
  ogImage?: {
  public_url: string
;
  media_url: string
;
  name?: string
;
  filepath?: string
;
  dimenstions?: {
  W: number
;
  H: number
;
  X: number
;
  Y: number
;
}
;
}
;
}
;
  url?: string
;
}
>
;
}
;

export type SmarttagValue = {
  key: string
;
  title?: string
;
}
;

export type SmarttagMetadata = {
  title?: string
;
  description?: string
;
  ogImage?: {
  public_url: string
;
  media_url: string
;
  name?: string
;
  filepath?: string
;
  dimenstions?: {
  W: number
;
  H: number
;
  X: number
;
  Y: number
;
}
;
}
;
}
;

export type Elementary_TagKey = string
;

export type Elementary_TagValue = string
;

export type TagList = Array<{
  appSpace?: string
;
  key?: string
;
  values?: Array<{
  _id?: string
;
  key?: string
;
  appId?: Array<string
>
;
}
>
;
}
>
;

export type Error = {
  statusCode: number
;
  name: string
;
  description: string
;
  payload?: {
}
;
  userinfo?: string
;
}
;


class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}


/**
 * 
 * @class TagmanagerApi
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class TagmanagerApi {

    protected baseUrl: string = "";
    protected headers: {name: string, value: string}[] = [];
    protected token: string = '';

    serializeQueryParams(parameters: QueryParameters) {
        return Object.keys(parameters)
            .reduce((acc: string[], p) => {
                const param = parameters[p];
                if(typeof param === 'undefined' || param === '') {
                    return acc;
                }
                return [...acc, `${encodeURIComponent(p)}=${encodeURIComponent(
                String(parameters[p]),
                )}`];
            }, [])
            .join('&');
    }

    protected transformParameter(value: any, transformOperation?: string) {
        switch(transformOperation) {
            case 'joinUsingPipes':
                return Array.isArray(value) ? value.join('|') : value;
            default:
                return value;
        }
    }

    public setBaseUrl(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public setHeaders(headers: {name: string, value: string}[]) {
        this.headers = headers;
    }

    public setToken(token: string) {
        this.token = token;
    }


    protected appendAuthHeaders(headerParams: Headers) {
      const headers = new Headers(headerParams);
      if (this.token) {
        headers.append('Authorization', `Bearer ${this.token}`);
      }
      return headers;
    }

    private async request(method: string, url: string, body: any, headers: Headers = new Headers(), queryParameters: QueryParameters = {}) {
        const queryParams = queryParameters && Object.keys(queryParameters).length ? this.serializeQueryParams(queryParameters) : null ;
        const urlWithParams = url + (queryParams ? '?' + queryParams : '');

        if(headers.get('Content-Type') === 'multipart/form-data') {
            const form = new FormData();
            for (let k in body) {
                form.append(k, body[k]);
            }
            body = form;
        } else if(headers.get('Content-Type') === 'application/json' && body && Object.keys(body).length > 0) {
            body = JSON.stringify(body);
        } else {
            body = undefined;
        }

        if(headers.get('Content-Type') === 'multipart/form-data') {
            headers.delete('Content-Type');
        }

        if (this.headers.length > 0) {
            this.headers.forEach(h => {
                headers.append(h.name, h.value);
            });
        }

        const response = await fetch(urlWithParams, { method, headers, body,  });

        if (response.status === 204) return response;
        
        if(response.ok) {
            const responseContentType =
                (response.headers && response.headers.get('Content-Type')) || '';
            if (responseContentType.includes('application/json')) {
                return response.json();
            }
            return response;
        } else {
            const err = new ApiError(response.statusText);
            err.details = await response.json();
            throw err;
        }
    }

    /**
    * Set flag system to true on this key
    * @method
    * @name TagmanagerApi#adminSetSystemKey
    */
    adminSetSystemKey(
            appSpace: string
,
            key: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/admin/{appSpace}/keys/{key}/system';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{key}', key.toString());




        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }
    /**
    * Set flag system to false on this key
    * @method
    * @name TagmanagerApi#adminUnsetSystemKey
    */
    adminUnsetSystemKey(
            appSpace: string
,
            key: string
,
        parameters: {
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/admin/{appSpace}/keys/{key}/system';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{key}', key.toString());




        return this.request(
            'DELETE',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }
    /**
    * Update smarttag detail
    * @method
    * @name TagmanagerApi#adminSetSmarttagDetail
    */
    adminSetSmarttagDetail(
            appSpace: string
,
            key: string
,
            value: string
,
            
        parameters: {
        }  = {},
        body: 
                                                {
                          meta?: {
  title?: string
;
  description?: string
;
  ogImage?: {
  public_url: string
;
  media_url: string
;
  name?: string
;
  filepath?: string
;
  dimenstions?: {
  W: number
;
  H: number
;
  X: number
;
  Y: number
;
}
;
}
;
}
;
                          url?: string
;
                          content?: Array<{
}
>
;
                          children?: Array<string
>
;
                        }
,
    ): Promise< {
  _id: string
;
  appId: Array<string
>
;
  appSpace: string
;
  identifier: string
;
  key: string
;
  value: string
;
  meta?: {
  title?: string
;
  description?: string
;
  ogImage?: {
  public_url: string
;
  media_url: string
;
  name?: string
;
  filepath?: string
;
  dimenstions?: {
  W: number
;
  H: number
;
  X: number
;
  Y: number
;
}
;
}
;
}
;
  url?: string
;
  content?: Array<{
}
>
;
}
  >
    {
        let path = '/admin/{appSpace}/keys/{key}/values/{value}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{key}', key.toString());




            path = path.replace('{value}', value.toString());








        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }
    /**
    * Get *ALL* appSpace smarttags, groupped by keys
    * @method
    * @name TagmanagerApi#adminGetAllTags
    */
    adminGetAllTags(
            
            appSpace: string
,
        parameters: {
                        'key'?: Array<string
>
,
        }  = {},
    ): Promise< {
  keys?: Array<string
>
;
  tags?: Array<{
  appSpace?: string
;
  key?: string
;
  values?: Array<{
  _id?: string
;
  key?: string
;
  appId?: Array<string
>
;
}
>
;
}
>
;
}
  >
    {
        let path = '/admin/{appSpace}/tags';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');


            if(typeof parameters['key'] !== 'undefined'){
                queryParameters['key'] = parameters['key'];
            }

            queryParameters['key'] = this.transformParameter(queryParameters['key']);





            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }
    /**
    * Update smart:tags by list of commands
    * @method
    * @name TagmanagerApi#adminApplyCommands
    */
    adminApplyCommands(
            appSpace: string
,
            
        parameters: {
        }  = {},
        body: 
                                                {
                          commands: Array<{
  moveKey?: {
  from: string
;
  to: string
;
}
;
  moveTag?: {
  from: string
;
  to: string
;
}
;
  delete?: {
  tag?: string
;
  key?: string
;
}
;
  mergeTags?: {
  from: Array<string
>
;
  to: string
;
}
;
}
>
;
                        }
,
    ): Promise< {
  tags?: Array<{
  appSpace?: string
;
  key?: string
;
  values?: Array<{
  _id?: string
;
  key?: string
;
  appId?: Array<string
>
;
}
>
;
}
>
;
}
  >
    {
        let path = '/admin/{appSpace}/tags';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());








        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }
    /**
    * Create new tag
    * @method
    * @name TagmanagerApi#adminCreateTag
    */
    adminCreateTag(
            appSpace: string
,
            
        parameters: {
        }  = {},
        body: 
                                                {
                          key: string
;
                          value: string
;
                        }
,
    ): Promise< {
  tags?: Array<{
  appSpace?: string
;
  key?: string
;
  values?: Array<{
  _id?: string
;
  key?: string
;
  appId?: Array<string
>
;
}
>
;
}
>
;
}
  >
    {
        let path = '/admin/{appSpace}/tags';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());








        return this.request(
            'POST',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }
    /**
    * Get keys of smarttags by appId and appSpace
    * @method
    * @name TagmanagerApi#appGetSmarttagsKeys
    */
    appGetSmarttagsKeys(
            
            appId: string
,
            appSpace: string
,
        parameters: {
                        'q'?: string
,
        }  = {},
    ): Promise< {
}
  >
    {
        let path = '/app/{appId}/{appSpace}/smarttags';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');


            if(typeof parameters['q'] !== 'undefined'){
                queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);





            path = path.replace('{appId}', appId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }
    /**
    * Smarttags add to db if not already present
    * @method
    * @name TagmanagerApi#appPutSmarttags
    */
    appPutSmarttags(
            appId: string
,
            appSpace: string
,
            
        parameters: {
        }  = {},
        body: 
                                                {
                          smarttags: Array<{
  key: string
;
  values: Array<{
  key: string
;
}
>
;
}
>
;
                        }
,
    ): Promise< {
  smarttags: Array<{
  _id: string
;
  appId: Array<string
>
;
  appSpace: string
;
  identifier: string
;
  key: string
;
  value: string
;
  meta?: {
  title?: string
;
  description?: string
;
  ogImage?: {
  public_url: string
;
  media_url: string
;
  name?: string
;
  filepath?: string
;
  dimenstions?: {
  W: number
;
  H: number
;
  X: number
;
  Y: number
;
}
;
}
;
}
;
  url?: string
;
  content?: Array<{
}
>
;
}
>
;
}
  >
    {
        let path = '/app/{appId}/{appSpace}/smarttags';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');


            path = path.replace('{appId}', appId.toString());




            path = path.replace('{appSpace}', appSpace.toString());








        return this.request(
            'PUT',
            `${this.baseUrl}${path}`,
            body,
            headers,
            queryParameters,
            
        );
    }
    /**
    * Get values of smarttag by appId, appSpace and key, If q is not set then max 1000 values can be returned
    * @method
    * @name TagmanagerApi#appGetSmarttagValues
    */
    appGetSmarttagValues(
            appId: string
,
            appSpace: string
,
            key: string
,
            
        parameters: {
                        'q'?: string
,
        }  = {},
    ): Promise< {
  values: Array<{
  key: string
;
  title?: string
;
}
>
;
}
  >
    {
        let path = '/app/{appId}/{appSpace}/smarttags/{key}/values';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers = this.appendAuthHeaders(headers);
        headers.append('Accept', 'application/json');


            path = path.replace('{appId}', appId.toString());




            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{key}', key.toString());




            if(typeof parameters['q'] !== 'undefined'){
                queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);





        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }
    /**
    * Get values of smarttag by appSpace and key, If q is not set then max 1000 values can be returned

    * @method
    * @name TagmanagerApi#publicGetSmarttagValues
    */
    publicGetSmarttagValues(
            appSpace: string
,
            key: string
,
            
        parameters: {
                        'q'?: string
,
        }  = {},
    ): Promise< {
  values: Array<{
  key: string
;
  title?: string
;
}
>
;
}
  >
    {
        let path = '/public/{appSpace}/smarttags/{key}/values';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{key}', key.toString());




            if(typeof parameters['q'] !== 'undefined'){
                queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);





        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }
    /**
    * Get detail of smarttag by appSpace key and value

    * @method
    * @name TagmanagerApi#publicGetSmarttag
    */
    publicGetSmarttag(
            appSpace: string
,
            key: string
,
            value: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id: string
;
  appId: Array<string
>
;
  appSpace: string
;
  identifier: string
;
  key: string
;
  value: string
;
  meta?: {
  title?: string
;
  description?: string
;
  ogImage?: {
  public_url: string
;
  media_url: string
;
  name?: string
;
  filepath?: string
;
  dimenstions?: {
  W: number
;
  H: number
;
  X: number
;
  Y: number
;
}
;
}
;
}
;
  url?: string
;
  content?: Array<{
}
>
;
}
  >
    {
        let path = '/public/{appSpace}/smarttag/{key}/values/{value}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{key}', key.toString());




            path = path.replace('{value}', value.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }
    /**
    * Get detail of smarttag by appSpace key and value

    * @method
    * @name TagmanagerApi#publicGetSmarttagKeyValue
    */
    publicGetSmarttagKeyValue(
            appSpace: string
,
            key: string
,
            value: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id: string
;
  appId: Array<string
>
;
  appSpace: string
;
  identifier: string
;
  key: string
;
  value: string
;
  meta?: {
  title?: string
;
  description?: string
;
  ogImage?: {
  public_url: string
;
  media_url: string
;
  name?: string
;
  filepath?: string
;
  dimenstions?: {
  W: number
;
  H: number
;
  X: number
;
  Y: number
;
}
;
}
;
}
;
  url?: string
;
  content?: Array<{
}
>
;
}
  >
    {
        let path = '/public/{appSpace}/smarttag/{key}:{value}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{key}', key.toString());




            path = path.replace('{value}', value.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }
    /**
    * Get values of related smarttags by key:value
    * @method
    * @name TagmanagerApi#publicGetRelatedSmarttags
    */
    publicGetRelatedSmarttags(
            appSpace: string
,
            key: string
,
            value: string
,
            
        parameters: {
                        'type': "allUp" | "allDown" | "directUp" | "directDown"
,
        } ,
    ): Promise< {
  values: Array<{
  _id: string
;
  appId: Array<string
>
;
  appSpace: string
;
  identifier: string
;
  key: string
;
  value: string
;
  meta?: {
  title?: string
;
  description?: string
;
  ogImage?: {
  public_url: string
;
  media_url: string
;
  name?: string
;
  filepath?: string
;
  dimenstions?: {
  W: number
;
  H: number
;
  X: number
;
  Y: number
;
}
;
}
;
}
;
  url?: string
;
}
>
;
}
  >
    {
        let path = '/public/{appSpace}/smarttag/{key}:{value}/related';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{key}', key.toString());




            path = path.replace('{value}', value.toString());




            if(typeof parameters['type'] !== 'undefined'){
                queryParameters['type'] = parameters['type'];
            }

            queryParameters['type'] = this.transformParameter(queryParameters['type']);



                if(typeof parameters['type'] === 'undefined') {
                    throw new Error('Missing required parameter: type');
                }


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }
    /**
    * Get detail of smarttag for PPO defined by unique identifier
    * @method
    * @name TagmanagerApi#publicGetSmarttagById
    */
    publicGetSmarttagById(
            appSpace: string
,
            keyId: string
,
            valueId: string
,
        parameters: {
        }  = {},
    ): Promise< {
  _id: string
;
  appId: Array<string
>
;
  appSpace: string
;
  identifier: string
;
  key: string
;
  value: string
;
  meta?: {
  title?: string
;
  description?: string
;
  ogImage?: {
  public_url: string
;
  media_url: string
;
  name?: string
;
  filepath?: string
;
  dimenstions?: {
  W: number
;
  H: number
;
  X: number
;
  Y: number
;
}
;
}
;
}
;
  url?: string
;
  content?: Array<{
}
>
;
}
  >
    {
        let path = '/public/{appSpace}/smarttagId/{keyId}/{valueId}';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{keyId}', keyId.toString());




            path = path.replace('{valueId}', valueId.toString());




        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }
    /**
    * Get values of related smarttags by identifier
    * @method
    * @name TagmanagerApi#publicGetRelatedSmarttagsByIdentifier
    */
    publicGetRelatedSmarttagsByIdentifier(
            appSpace: string
,
            keyId: string
,
            valueId: string
,
            
        parameters: {
                        'type': "allUp" | "allDown" | "directUp" | "directDown"
,
        } ,
    ): Promise< {
  values: Array<{
  _id: string
;
  appId: Array<string
>
;
  appSpace: string
;
  identifier: string
;
  key: string
;
  value: string
;
  meta?: {
  title?: string
;
  description?: string
;
  ogImage?: {
  public_url: string
;
  media_url: string
;
  name?: string
;
  filepath?: string
;
  dimenstions?: {
  W: number
;
  H: number
;
  X: number
;
  Y: number
;
}
;
}
;
}
;
  url?: string
;
}
>
;
}
  >
    {
        let path = '/public/{appSpace}/smarttagId/{keyId}/{valueId}/related';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            path = path.replace('{keyId}', keyId.toString());




            path = path.replace('{valueId}', valueId.toString());




            if(typeof parameters['type'] !== 'undefined'){
                queryParameters['type'] = parameters['type'];
            }

            queryParameters['type'] = this.transformParameter(queryParameters['type']);



                if(typeof parameters['type'] === 'undefined') {
                    throw new Error('Missing required parameter: type');
                }


        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }
    /**
    * Search keys and values by one query
    * @method
    * @name TagmanagerApi#publicGetSmarttags
    */
    publicGetSmarttags(
            appSpace: string
,
            
            
        parameters: {
                        'q'?: string
,
                        'smarttags'?: Array<string
>
,
        }  = {},
    ): Promise< {
  values: Array<{
  _id: string
;
  appId: Array<string
>
;
  appSpace: string
;
  identifier: string
;
  key: string
;
  value: string
;
  meta?: {
  title?: string
;
  description?: string
;
  ogImage?: {
  public_url: string
;
  media_url: string
;
  name?: string
;
  filepath?: string
;
  dimenstions?: {
  W: number
;
  H: number
;
  X: number
;
  Y: number
;
}
;
}
;
}
;
  url?: string
;
}
>
;
}
  >
    {
        let path = '/public/{appSpace}/smarttag';
        let headers: Headers = new Headers();
        let queryParameters: QueryParameters = {};

        headers.append('Accept', 'application/json');


            path = path.replace('{appSpace}', appSpace.toString());




            if(typeof parameters['q'] !== 'undefined'){
                queryParameters['q'] = parameters['q'];
            }

            queryParameters['q'] = this.transformParameter(queryParameters['q']);





            if(typeof parameters['smarttags'] !== 'undefined'){
                queryParameters['smarttags'] = parameters['smarttags'];
            }

            queryParameters['smarttags'] = this.transformParameter(queryParameters['smarttags']);





        return this.request(
            'GET',
            `${this.baseUrl}${path}`,
            {},
            headers,
            queryParameters,
            
        );
    }
}

export default new TagmanagerApi();
export { TagmanagerApi };
