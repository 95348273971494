import { Smarttag } from '../../library/Common';
import { useAuth } from '@sportnet/auth-react/AuthProvider';
import Button from '@sportnet/ui/Button';
import Header from '@sportnet/ui/Header';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import React from 'react';
import SmarttagControl from '@sportnet/ui/SmarttagsControl/SmarttagControl';
import SmarttagTags from '@sportnet/ui/SmarttagsControl/SmarttagTags';
import TagmanagerConnector from '@sportnet/tagmanager-connector';
import __ from '../../utilities/getText';
import config from '../../config';
import countSmarttagsInSelection from '../../utilities/countSmarttagsInSelection';
import smarttagsSelectionToStandardFormat from '../../utilities/smarttagsSelectionToStandardFormat';

interface OwnProps {
  isOpen: boolean;
  title: string;
  suggestedSmarttags?: Smarttag[];
  onSubmit: (val: { key: string; value: string }) => void;
  onCancel: () => void;
}

type Props = OwnProps;

const SmarttagPrompt: React.FC<Props> = ({
  isOpen,
  title,
  suggestedSmarttags,
  onSubmit,
  onCancel,
}) => {
  const { ppo, accessToken } = useAuth();
  const [smarttag, setSmartTag] = React.useState<
    | {
        key: string;
        values: [{ key: string }];
      }[]
    | null
  >(null);

  const handleCloseConfirmation = () => {
    setSmartTag(null);
  };

  React.useEffect(() => {
    if (isOpen) {
      setSmartTag(null);
    }
  }, [isOpen]);

  const limitSmarttags = (
    smarttags: Smarttag[],
    minSmarttags: number,
    maxSmarttags: number
  ) => {
    const newSmarttags: { [key: string]: { [value: string]: boolean } } = {};
    let assignedSmarttags = 0;
    smarttags.forEach((key) => {
      newSmarttags[key.key] = {};
      if (assignedSmarttags < maxSmarttags) {
        (key.values || []).forEach((value) => {
          if (assignedSmarttags < maxSmarttags) {
            newSmarttags[key.key][value.key] = true;
            assignedSmarttags++;
          }
        });
      }
    });
    if (countSmarttagsInSelection(newSmarttags) <= minSmarttags) {
      return [];
    }
    return smarttagsSelectionToStandardFormat(newSmarttags);
  };

  const suggestedSmarttagsLimited = limitSmarttags(
    suggestedSmarttags || [],
    1,
    20
  );

  return (
    <Modal isOpen={isOpen} handleClose={onCancel} size="xs">
      {!!isOpen && (
        <>
          <ModalContent>
            <Header withSeparator>{title}</Header>
            {suggestedSmarttagsLimited &&
              suggestedSmarttagsLimited.length > 0 && (
                <>
                  <Header size="s">{__('Navrhované tagy')}</Header>
                  <SmarttagTags
                    showWhitespace
                    items={suggestedSmarttagsLimited}
                    onClickTag={(key: string, value: string) => {
                      setSmartTag([{ key, values: [{ key: value }] }]);
                    }}
                  />
                  <br />
                  <br />
                </>
              )}
            <Header size="s">{__('Nový tag')}</Header>
            <TagmanagerConnector
              appId={config.APP_ID}
              appspace={ppo ?? ''}
              accessToken={accessToken ?? ''}
            >
              {(getSmarttagsKeys: any, getSmarttagsValues: any) => (
                <SmarttagControl
                  getSmarttagsKeys={getSmarttagsKeys}
                  getSmarttagsValues={getSmarttagsValues}
                  value={[]}
                  onChange={(v: any) => setSmartTag(v)}
                />
              )}
            </TagmanagerConnector>
          </ModalContent>
          <ModalActions>
            <div />
            <Button onClick={onCancel}>{__('Zavrieť')}</Button>{' '}
          </ModalActions>
          <Modal
            isOpen={smarttag !== null}
            handleClose={handleCloseConfirmation}
            size="xs"
          >
            <ModalContent>
              <Header>{__('Potvrďte vybraný smarttag')}</Header>
              {smarttag && <SmarttagTags showWhitespace items={smarttag} />}
            </ModalContent>
            <ModalActions>
              <Button onClick={handleCloseConfirmation}>{__('Späť')}</Button>
              <Button
                primary
                onClick={() => {
                  if (smarttag !== null) {
                    handleCloseConfirmation();
                    onSubmit({
                      key: smarttag[0].key,
                      value: smarttag[0].values[0].key,
                    });
                  }
                }}
              >
                {__('Potvrdiť')}
              </Button>
            </ModalActions>
          </Modal>
        </>
      )}
    </Modal>
  );
};

export default SmarttagPrompt;
