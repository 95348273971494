export const countSmarttagsInSelection = (
  smarttags: Readonly<{
    [key: string]: Readonly<{
      [key: string]: boolean;
    }>;
  }>
) =>
  Object.keys(smarttags).reduce((acc, key) => {
    return acc + Object.keys(smarttags[key]).length;
  }, 0);

export default countSmarttagsInSelection;
